import React from 'react'
import Box from 'components/Box'
import { Trans } from '@lingui/macro'
import { Flex, Text, Image } from '@chakra-ui/react'

import { CartProduct } from 'models/cartproduct'
import { formatMoney } from 'utils/currency'
import usePagination from 'utils/usePagination'

import { Pagination } from 'components/Pagination'
import { getLatestProductImage } from 'utils/product'

export interface ProductProps {
  products: CartProduct[]
}

function Products(props: ProductProps) {
  const { products } = props

  const {
    slice,
    currentPage,
    shouldShowPagination,
    handlePageChange,
    total
  } = usePagination(products, 3)

  return (
    <Box bg='white' pb={3}>
      <Box
        borderTopWidth={1}
        borderBottomWidth={1}
        borderColor='gray'
        px={2}
        py={4}
      >
        {slice.map((p, index) => {
          const productImage = getLatestProductImage(p)
          return (
            <Box key={p.id} d='flex' py={2}>
              {productImage && (
                <Box mr={{ base: 5, md: 0 }}>
                  {index === 0 && <Header hideText>Image</Header>}
                  <Image
                    mr={{ base: 5, md: 3 }}
                    boxSize='60px'
                    alt={p.productName}
                    src={productImage?.url}
                    fallbackSrc='/images/shop-logo.svg'
                  />
                </Box>
              )}
              <Box w='100%'>
                <Box d={{ md: 'flex' }}>
                  <Box flex={1}>
                    {index === 0 && (
                      <Header>
                        <Trans>Product</Trans>
                      </Header>
                    )}
                    <Box>
                      <Text fontWeight='600'>{p.productName}</Text>
                      <Flex
                        mt={2}
                        opacity='0.8'
                        flexDirection={{ base: 'column', md: 'row' }}
                      >
                        <Flex alignItems='center'>
                          <Text fontSize='xs'>
                            {p.productCode} x {p.productQuantity}
                          </Text>
                        </Flex>
                      </Flex>
                    </Box>
                  </Box>
                  <Box>
                    {index === 0 && (
                      <Header>
                        <Trans>Price</Trans>
                      </Header>
                    )}
                    <Text mt={{ base: 1, md: 0 }} mr={4} fontWeight='bold'>
                      {formatMoney(p.price)}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        })}
      </Box>
      {shouldShowPagination && (
        <Box mt='14px' display='flex' justifyContent='center'>
          <Pagination
            total={total}
            current={currentPage}
            generateLink={handlePageChange}
          />
        </Box>
      )}
    </Box>
  )
}

function Header({
  children,
  hideText
}: {
  children: React.ReactNode
  hideText?: boolean
}) {
  return (
    <Text
      d={{ md: 'block', base: 'none' }}
      bg='secondary'
      p={2}
      fontSize='xs'
      fontWeight='600'
      color={hideText ? 'secondary' : 'GrayText'}
      mb={2}
    >
      {children}
    </Text>
  )
}

export default Products
