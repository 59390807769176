import { useQuery } from 'react-query'

import request, { RequestError } from 'utils/request'
import config from 'config'
import { Organization } from 'models/organization'

async function fetchOrganization(tracker: string): Promise<Organization> {
  const result = await request<Organization>({
    method: 'GET',
    url: config.apis.getOrganization(tracker)
  })

  return result.data
}

async function fetchOrganizationByHost(host: string): Promise<string> {
  const result = await request<string>({
    method: 'GET',
    url: config.apis.getOrganizationByHost(host)
  })

  return result.data
}

export function useOrganizationFromHost(host: string) {
  const organizationQueryKey = `organization__${host}`

  const query = useQuery<string, RequestError, string>(
    organizationQueryKey,
    () => fetchOrganizationByHost(host),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!host
    }
  )

  return {
    query
  }
}

export function useOrganization(tracker: string) {
  const organizationQueryKey = `organization__${tracker}`

  const query = useQuery<Organization, RequestError, Organization>(
    organizationQueryKey,
    () => fetchOrganization(tracker),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!tracker
    }
  )

  return {
    query
  }
}
