import { Image, ImageProps } from '@chakra-ui/react'
import { ProductImage } from 'models/productImage'

type ProductThumbnailProps = {
  productImages: ProductImage[]
} & ImageProps

export default function ProductThumbnail({
  productImages,
  ...imageProps
}: ProductThumbnailProps) {
  const src =
    productImages && productImages[0]
      ? productImages[0].url
      : '/images/shop-logo.svg'
  // : '/images/default.png'

  // src='/images/shopping-cart.svg'

  return <Image src={src} {...imageProps} />
}
