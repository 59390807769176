export default class LocalStorageManager {
  static organizationIdKey = 'organizationId'
  static cartIdKey = 'previous-cart'
  static currencyKey = 'currency'
  static langKey = 'lang'
  static tempCartKey = 'temp-cart'

  static setOrganizationId(organizationId: string) {
    localStorage.setItem(this.organizationIdKey, organizationId)
  }

  static getOrganizationId() {
    return localStorage.getItem(this.organizationIdKey)
  }

  static setTempCart(cart: string) {
    localStorage.setItem(`${this.tempCartKey}-${this.organizationIdKey}`, cart)
  }

  static getTempCart() {
    return localStorage.getItem(`${this.tempCartKey}-${this.organizationIdKey}`)
  }

  static clearTempCart() {
    localStorage.removeItem(`${this.tempCartKey}-${this.organizationIdKey}`)
  }

  static setCartId(cartId: string) {
    localStorage.setItem(this.cartIdKey, cartId)
  }

  static getCartId() {
    return localStorage.getItem(this.cartIdKey)
  }

  static removeCartId() {
    localStorage.removeItem(this.cartIdKey)
  }

  static setCurrency(currency: number) {
    localStorage.setItem(this.currencyKey, currency.toString())
  }

  static getCurrency() {
    return localStorage.getItem(this.currencyKey)
  }

  static removeCurrency() {
    localStorage.removeItem(this.currencyKey)
  }

  static getLang() {
    return localStorage.getItem(this.langKey)
  }

  static setLang(lang: string) {
    localStorage.setItem(this.langKey, lang)
  }
}
