import request from 'utils/request'
import { useQuery } from 'react-query'

interface AsyncOptionsProps {
  url?: string | null
  mapDataFunc: (data: any) => Option
}

interface Option {
  value: string | number
  name: string | number
}

export function AsyncOptions(props: AsyncOptionsProps) {
  const { url, mapDataFunc } = props

  async function fetchOptions() {
    if (url) {
      const result = await request({
        method: 'GET',
        url
      })

      return result.data
    }
    return null
  }

  const query = useQuery(url || '', () => fetchOptions(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false
  })

  const options = query.data && url ? query.data.map(mapDataFunc) : []

  return (
    <>
      {query.isFetching && <option value=''>loading</option>}
      {options.map((option: Option) => (
        <option value={option.value} key={option.value}>
          {option.name}
        </option>
      ))}
    </>
  )
}
