import React, { useState } from 'react'
import { t, Trans } from '@lingui/macro'
import {
  Text,
  Tag,
  Divider,
  Radio,
  RadioGroup,
  Stack,
  Input,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react'

import { formatMoney } from 'utils/currency'
import { Button } from 'components/Button'
import Box from 'components/Box'
import { ShippingSetting } from 'models/cart'
import { useLingui } from '@lingui/react'
import { InfoIcon, PhoneIcon } from '@chakra-ui/icons'

export interface SummaryProps {
  total: number
  subTotal: number
  deliveryFee?: number
  discount?: number
  isDisabled?: boolean
  shippingSettings: ShippingSetting[]
  deliveryOptionId: string | null
  extraNote: string | null
  onProceed: (address: string, phoneNumber: string) => void
  pickShippingOption: (deliveryOptionId: string) => void
}

function Summary(props: SummaryProps) {
  const { i18n } = useLingui()
  const {
    total,
    isDisabled,
    subTotal,
    discount,
    deliveryFee,
    shippingSettings,
    deliveryOptionId,
    pickShippingOption,
    onProceed
  } = props

  // const texts = {
  //   emailError: i18n._(t`Please enter a valid email address.`),
  //   emptyError: i18n._(t`This field must not be empty.`)
  // }

  const [address, setAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [option, setOption] = useState(
    deliveryOptionId || shippingSettings[0].id
  )

  return (
    <Box>
      <Box bg='white' p={3}>
        <Box display='flex' flex='1' height='32px' alignItems='center'>
          <Text mr={3} fontWeight='600' whiteSpace='pre'>
            <Trans>Shipping option</Trans>
          </Text>
        </Box>
        <RadioGroup
          onChange={value => {
            setOption(value as string)
            pickShippingOption(value as string)
          }}
          value={option}
          position='relative'
        >
          <Stack>
            {shippingSettings.map(setting => (
              <Radio
                value={setting.id}
                size='md'
                key={setting.id}
                isFullWidth
                py={1}
              >
                <Text fontSize='sm' whiteSpace='nowrap' fontWeight='bold'>
                  {setting.option} - {formatMoney(setting.surcharge)}
                </Text>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
        <Divider />
        <InputGroup mt={2}>
          <InputLeftElement pointerEvents='none'>
            <PhoneIcon color='gray.300' />
          </InputLeftElement>
          <Input
            errorBorderColor='red.300'
            type='tel'
            placeholder={i18n._(t`Phone number`)}
            isInvalid={!phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          />
        </InputGroup>
        <InputGroup mt={2}>
          <InputLeftElement pointerEvents='none'>
            <InfoIcon color='gray.300' />
          </InputLeftElement>
          <Input
            errorBorderColor='red.300'
            placeholder={i18n._(t`Address`)}
            isInvalid={!address}
            onChange={e => setAddress(e.target.value)}
          />
        </InputGroup>
      </Box>
      <Divider />
      <Box
        as='h2'
        textStyle='sectionTitle'
        display='flex'
        bg='white'
        p={3}
        borderRadius='md'
        flexDirection='column'
      >
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Text color='GrayText' fontSize='sm'>
            <Trans>Sub total</Trans>
          </Text>
          <Text color='GrayText' fontSize='sm'>
            {formatMoney(subTotal)}
          </Text>
        </Box>
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Text color='GrayText' fontSize='sm'>
            <Trans>Delivery Fee</Trans>
          </Text>
          <Tag fontSize='sm'>
            {deliveryFee ? formatMoney(deliveryFee) : formatMoney(0)}
          </Tag>
        </Box>
        {!!discount && (
          <Box display='flex' justifyContent='space-between' mb={2}>
            <Text color='GrayText' fontSize='sm'>
              <Trans>Discount</Trans>
            </Text>
            <Tag fontSize='sm' colorScheme='orange'>
              -{formatMoney(Math.min(discount, subTotal))}
            </Tag>
          </Box>
        )}{' '}
        <Box display='flex' justifyContent='space-between'>
          <Text fontSize='1.5rem'>
            <Trans>Total</Trans>
          </Text>
          <Text fontSize='1.5rem'>{formatMoney(total)}</Text>
        </Box>
        <Box mt={3}>
          <Button
            onClick={() => {
              onProceed(address, phoneNumber)
            }}
            width='100%'
            variant='primary'
            disabled={isDisabled || !phoneNumber || !address}
          >
            <Trans>Proceed to checkout</Trans>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Summary
