import Box from 'components/Box'
import { Spinner } from '@chakra-ui/react'
import ProductList from './components/ProductList'
import useOrganizationId from 'utils/useOrganizationId'
import { useCategoryProducts } from 'fetchers/catalog'
import useCategoryId from 'utils/useCategoryId'

export interface CategoryViewProps {
  tracker: string
}

function CategoryView() {
  const tracker = useOrganizationId()
  const categoryId = useCategoryId()
  const { query } = useCategoryProducts(tracker, categoryId)
  const products = query.data?.data

  return (
    <Box mt={4}>
      {query.isFetching ? (
        <Spinner />
      ) : (
        <>{products && <ProductList products={products} />}</>
      )}
    </Box>
  )
}

export default CategoryView
