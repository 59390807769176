import { Trans } from '@lingui/macro'
import {
  Text,
  Tag,
  Divider,
  Alert,
  AlertIcon,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { formatMoney } from 'utils/currency'
import { Button } from 'components/Button'
import Box from 'components/Box'
import Ads from 'components/Ads'

export interface SummaryProps {
  isLoading: boolean
  total: number
  subTotal: number
  deliveryFee?: number
  discount?: number
  extraNote: null | string
  isDisabled?: boolean
  onProceed: () => void
}

function Summary(props: SummaryProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isLoading,
    total,
    isDisabled,
    subTotal,
    discount,
    extraNote,
    deliveryFee,
    onProceed
  } = props

  return (
    <Box>
      <Box
        as='h2'
        textStyle='sectionTitle'
        display='flex'
        bg='white'
        p={3}
        borderRadius='md'
        flexDirection='column'
      >
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Ads
            url='https://shorten.asia/3vvcnNQa'
            description='Ưu đãi vé máy bay với ATADI!'
          />
        </Box>
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Text color='GrayText' fontSize='sm'>
            <Trans>Sub total</Trans>
          </Text>
          <Text color='GrayText' fontSize='sm'>
            {formatMoney(subTotal)}
          </Text>
        </Box>
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Text color='GrayText' fontSize='sm'>
            <Trans>Delivery Fee</Trans>
          </Text>
          <Tag fontSize='sm'>
            {deliveryFee ? formatMoney(deliveryFee) : formatMoney(0)}
          </Tag>
        </Box>
        {!!discount && (
          <Box display='flex' justifyContent='space-between' mb={2}>
            <Text color='GrayText' fontSize='sm'>
              <Trans>Discount</Trans>
            </Text>
            <Tag fontSize='sm' colorScheme='orange'>
              -{formatMoney(Math.min(discount, subTotal))}
            </Tag>
          </Box>
        )}{' '}
        <Box display='flex' justifyContent='space-between'>
          <Text fontSize='1.5rem'>
            <Trans>Total</Trans>
          </Text>
          <Text fontSize='1.5rem'>{formatMoney(total)}</Text>
        </Box>
        <Box mt={3}>
          {/* <Button
            onClick={onProceed}
            width='100%'
            variant='primary'
            disabled={isDisabled}
          >
            <Trans>Proceed to checkout</Trans>
          </Button> */}
          <Button
            width='100%'
            variant='primary'
            onClick={onProceed}
            disabled={isDisabled}
            isLoading={isLoading}
            mt={2}
            // isLoading={deliveryInfoMutation.isLoading || payMutation.isLoading}
          >
            <Trans>Confirm</Trans>
          </Button>
        </Box>
        {extraNote && (
          <Box mt={3}>
            <Divider />
            <Box
              display='flex'
              fontSize='sm'
              fontWeight={400}
              justifyContent='space-between'
            >
              <Alert status='warning'>
                <AlertIcon onClick={onOpen} />
                <Text noOfLines={2} onClick={onOpen}>
                  {extraNote}
                </Text>
              </Alert>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    <Trans>Note</Trans>
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text>{extraNote}</Text>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
            <Divider />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Summary
