import React from "react";
import { Container, ContainerProps } from "@chakra-ui/layout";

export type AppContainerProps = ContainerProps;

export default function AppContainer(props: AppContainerProps) {
  return (
    <Container
      maxW="1600px"
      pl={{ base: "14px", sm: "50px", md: "50px", lg: "85px" }}
      pr={{ base: "14px", sm: "50px", md: "50px", lg: "85px" }}
      {...props}
    />
  );
}
