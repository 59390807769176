import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import {
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  ModalContent
} from '@chakra-ui/react'

interface ConfirmOptions {
  onConfirm: (args?: any) => void
  message: React.ReactNode
  onDismiss?: () => void
  title?: React.ReactNode
  confirmText?: React.ReactNode
  dismissText?: React.ReactNode
}

export default function useConfirm(options: ConfirmOptions): any {
  const {
    onConfirm,
    onDismiss,
    title,
    message,
    confirmText,
    dismissText
  } = options
  const [confirmArgs, setConfirmArgs] = useState()
  const [isOpen, setOpen] = useState(false)

  const toggle = () => setOpen(!isOpen)

  const handleProceed = () => {
    onConfirm(confirmArgs)
    toggle()
  }

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss()
    }
    toggle()
  }

  const handleConfirm = (args: any) => {
    setConfirmArgs(args)
    toggle()
  }

  const ConfirmModal = () => {
    return (
      <Modal isOpen={isOpen} onClose={toggle} size='sm'>
        <ModalOverlay />
        <ModalContent>
          {title ? <ModalHeader>{title}</ModalHeader> : null}

          <ModalCloseButton />
          <ModalBody>{message}</ModalBody>

          <ModalFooter>
            <Button variant='danger' mr={2} onClick={handleProceed}>
              {confirmText ? confirmText : <Trans>OK</Trans>}
            </Button>
            <Button onClick={handleDismiss}>
              {dismissText ? dismissText : <Trans>Cancel</Trans>}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return [ConfirmModal, handleConfirm]
}
