import { useQuery } from 'react-query'
import request, { RequestError } from 'utils/request'
import { KaioDistrict, KaioProvince, KaioWard } from 'models/cart'
import config from 'config'

async function fetchKaioProvinces() {
  const result = await request<KaioProvince[]>({
    method: 'GET',
    url: config.apis.getKaioProvinces()
  })
  return result.data
}

async function fetchKaioDistricts(
  kaioDistrictId: number
): Promise<KaioDistrict[]> {
  const result = await request<KaioDistrict[]>({
    method: 'GET',
    url: config.apis.getKaioDistricts(kaioDistrictId)
  })
  return result.data
}

async function fetchKaioWards(kaioWardId: number): Promise<KaioWard[]> {
  const result = await request<KaioWard[]>({
    method: 'GET',
    url: config.apis.getKaioWards(kaioWardId)
  })

  return result.data
}

export function useKaioDeliveryServices() {
  const kaioProvincesQueryKey = `kaio_services_provinces`

  const kaioProvincesQuery = useQuery<
    KaioProvince[],
    RequestError,
    KaioProvince[]
  >(kaioProvincesQueryKey, async () => {
    return await fetchKaioProvinces()
  })

  return {
    kaioProvincesQuery,
    fetchKaioDistricts,
    fetchKaioWards
  }
}

export function useKaioDistricts(provinceId: number) {
  const kaioDistrictsQueryKey = `kaio_services_districts__${provinceId}`

  const kaioDistrictsQuery = useQuery(kaioDistrictsQueryKey, async () => {
    return await fetchKaioDistricts(provinceId)
  })

  return kaioDistrictsQuery
}

export function useVTPAreas(districtId: number) {
  const kaioWardsQueryKey = `kaio_services_wards__${districtId}`

  const kaioWardsQuery = useQuery(kaioWardsQueryKey, async () => {
    return await fetchKaioWards(districtId)
  })

  return kaioWardsQuery
}
