import React, { useEffect } from 'react'
import { Button, Box, Checkbox, SimpleGrid } from '@chakra-ui/react'
import { useForm, useWatch } from 'react-hook-form'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { BuyerData } from 'models/cart'

import { TextField } from 'components/TextField'
import { AsyncOptions } from 'components/AsyncOptions'
import { useQuery } from 'react-query'

import config from 'config'
import useCartId from 'utils/useCartId'
import { useCart } from 'fetchers/cart'
import { PHONE_NUMBER_REGEX, EMAIL_REGEX } from 'utils/regex'

export default function ShippingForm(props: {
  onSubmit(values: BuyerData): void
  isLoading?: boolean
  isBuyer?: boolean
  formData?: BuyerData
}) {
  const { i18n } = useLingui()
  const { register, handleSubmit, errors, reset, control } = useForm<BuyerData>(
    {
      reValidateMode: 'onChange',
      mode: 'all',
      defaultValues: props.formData
    }
  )

  const provinceId = useWatch<string>({
    control,
    name: 'provinceId'
  })

  const districtId = useWatch<string>({
    control,
    name: 'districtId'
  })

  const id = useCartId()
  const { query } = useCart(id)
  const provinces = useQuery(config.apis.getProvinces())

  useEffect(() => {
    if (props.formData && provinces.isFetched) {
      setTimeout(() => {
        // temporary workaround to make fill with selected values
        reset(props.formData)
      }, 500)
    }
  }, [props.formData, reset, provinces])

  const texts = {
    emailError: i18n._(t`Please enter a valid email address.`),
    emptyError: i18n._(t`This field must not be empty.`),
    phoneError: i18n._(t`Please enter a valid phone number.`)
  }

  const isGiaoHangNhanhEnabled = query.data?.isGiaoHangNhanhEnabled

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <SimpleGrid columns={isGiaoHangNhanhEnabled ? 2 : 1} spacing={3}>
        <Box>
          <TextField
            isRequired={true}
            error={Boolean(errors.recipient?.message)}
            helperText={errors.recipient?.message}
            inputProps={{
              name: 'recipient',
              ref: register({
                required: texts.emptyError
              })
            }}
            label={<Trans>Full name</Trans>}
          />
        </Box>
        <Box>
          <TextField
            isRequired={true}
            error={Boolean(errors.phoneNumber?.message)}
            helperText={errors.phoneNumber?.message}
            inputProps={{
              name: 'phoneNumber',
              ref: register({
                // required: texts.emptyError
                pattern: {
                  value: PHONE_NUMBER_REGEX,
                  message: texts.phoneError
                }
              })
            }}
            label={<Trans>Phone number</Trans>}
          />
        </Box>
        <Box>
          <TextField
            isRequired={true}
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
            inputProps={{
              name: 'email',
              ref: register({
                // required: texts.emailError,
                pattern: {
                  value: EMAIL_REGEX,
                  message: texts.emailError
                }
              })
            }}
            label={<Trans>Email</Trans>}
          />
        </Box>
        <Box>
          <TextField
            error={Boolean(errors.deliveryInstruction?.message)}
            helperText={errors.deliveryInstruction?.message}
            inputProps={{
              name: 'deliveryInstruction',
              ref: register
            }}
            label={<Trans>Note to delivery drivers</Trans>}
          />
        </Box>
        {!!isGiaoHangNhanhEnabled && (
          <Box>
            <TextField
              isRequired={true}
              type='select'
              selectProps={{
                name: 'provinceId',
                ref: register({
                  required: texts.emptyError
                })
              }}
              label={<Trans>Province</Trans>}
            >
              <AsyncOptions
                url={config.apis.getProvinces()}
                mapDataFunc={option => ({
                  value: option.provinceId,
                  name: option.provinceName
                })}
              />
            </TextField>
          </Box>
        )}
        {!!isGiaoHangNhanhEnabled && (
          <Box>
            <TextField
              isRequired={true}
              type='select'
              selectProps={{
                name: 'districtId',
                ref: register({
                  required: texts.emptyError
                })
              }}
              label={<Trans>District</Trans>}
            >
              <AsyncOptions
                url={provinceId ? config.apis.getDistricts(provinceId) : null}
                mapDataFunc={option => ({
                  value: option.DistrictID,
                  name: option.districtName
                })}
              />
            </TextField>
          </Box>
        )}
        {!!isGiaoHangNhanhEnabled && (
          <Box>
            <TextField
              isRequired={true}
              type='select'
              selectProps={{
                name: 'wardCode',
                ref: register({
                  required: texts.emptyError
                })
              }}
              label={<Trans>Ward</Trans>}
            >
              <AsyncOptions
                url={districtId ? config.apis.getWards(districtId) : null}
                mapDataFunc={option => ({
                  value: option.wardCode,
                  name: option.wardName
                })}
              />
            </TextField>
          </Box>
        )}
        <Box>
          <TextField
            isRequired={true}
            error={Boolean(errors.deliveryAddress?.message)}
            helperText={errors.deliveryAddress?.message}
            inputProps={{
              name: 'deliveryAddress',
              ref: register({
                required: texts.emptyError
              })
            }}
            label={<Trans>Address</Trans>}
          />
        </Box>
      </SimpleGrid>
      {props.isBuyer && (
        <Box mt={3}>
          <Checkbox name='isDefault' ref={register}>
            <Trans>Make this your default shipping address</Trans>
          </Checkbox>
        </Box>
      )}
      <Box mt={10}>
        <Button
          width='100%'
          variant='primary'
          type='submit'
          isLoading={props.isLoading}
        >
          <Trans>Submit</Trans>
        </Button>
      </Box>
    </form>
  )
}
