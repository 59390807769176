import { useMutation, useQueryClient, useQuery } from 'react-query'

import request, { RequestError, handleErrors } from 'utils/request'
import config from 'config'
import { PaymentMode, PaymentInstruction } from 'models/cart'

export async function fetchPayV1(data: {
  cartId: string
  promotionId: string | null
  paymentProof: string | null
  paymentMode: PaymentMode
}): Promise<undefined> {
  const result = await request<undefined>({
    method: 'POST',
    url:
      data.paymentMode === PaymentMode.Offline
        ? config.apis.payOffline()
        : config.apis.cashOnDelivery(),
    data: {
      cartId: data.cartId,
      promotionId: data.promotionId,
      paymentProof: data.paymentProof
    }
  })
  return result.data
}

export async function fetchPayV2(data: {
  cartId: string
  promotionId: string | null
  paymentProof: string | null
  phoneNumber: string | null
  deliveryAddress: string | null
  postalCode: string | null
  recipient: string | null
  deliveryInstruction: string | null
  kaioProvinceId: number | null
  kaioDistrictId: number | null
  kaioWardId: number | null
  kaioProvince: string | null
  kaioDistrict: string | null
  kaioWard: string | null
  isPickUp: boolean | null
}): Promise<undefined> {
  const result = await request<undefined>({
    method: 'POST',
    url: config.apis.pay(),
    data: {
      cartId: data.cartId,
      promotionId: data.promotionId,
      paymentProof: data.paymentProof,
      phoneNumber: data.phoneNumber,
      deliveryAddress: data.deliveryAddress,
      recipient: data.recipient,
      postalCode: data.postalCode,
      deliveryInstruction: data.deliveryInstruction,
      kaioProvinceId: data.kaioProvinceId,
      kaioDistrictId: data.kaioDistrictId,
      kaioWardId: data.kaioWardId,
      kaioProvince: data.kaioProvince,
      kaioDistrict: data.kaioDistrict,
      kaioWard: data.kaioWard,
      isPickUp: data.isPickUp
    }
  })

  return result.data
}

export async function fetchStripeCheckout(data: {
  cartId: string
  promotionId: string | null
}): Promise<string> {
  const result = await request<string>({
    method: 'POST',
    url: config.apis.stripCheckout(),
    data
  })
  return result.data
}

export async function fetchPaymentInstruction(
  id: string
): Promise<PaymentInstruction> {
  const result = await request<PaymentInstruction>({
    method: 'GET',
    url: config.apis.getPaymentInstruction(id)
  })
  return result.data
}

function fetchPaymentInstructionWrap(id: string) {
  return async () => {
    return await fetchPaymentInstruction(id)
  }
}

export async function fetchCancelPayment(cartId: string) {
  await request<string>({
    method: 'POST',
    url: config.apis.cancelPayment(),
    data: {
      cartId
    }
  })
}

export async function fetchUrlPayme(payload: {
  customerId: string
  merchantId: string
  orderId: string
  amount: number
  paymentMethod: string
  checkoutUrl?: string
  customerName: string
  customerPhone: string
}): Promise<any> {
  const result = await request<any>({
    method: 'POST',
    url: 'https://services-dev.kaio.ai/payment/customer/checkout',
    data: payload
  })
  return result
}

export function usePayment(cartId: string) {
  const queryClient = useQueryClient()

  const cartQueryKey = `cart__${cartId}`
  const cartPaymentInstructionQuery = `payment__instruction__${cartId}`

  const query = useQuery<PaymentInstruction, RequestError, PaymentInstruction>(
    cartPaymentInstructionQuery,
    fetchPaymentInstructionWrap(cartId)
  )

  const payMutation = useMutation(fetchPayV1, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  const payV2Mutation = useMutation(fetchPayV2, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  const useGetPayme = useMutation(
    async (payload: any) => await fetchUrlPayme(payload),
    {
      onError: (error: any) => {
        handleErrors(error)
      }
    }
  )

  return {
    query,
    payMutation,
    payV2Mutation,
    useGetPayme
  }
}
