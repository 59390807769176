import localStorageManager from 'utils/localStorageManager'
import create, { SetState, GetState } from 'zustand'
import { Product } from 'models/product'

type Cart = {
  products: { product: Product; quantity: number }[]
  name: string
}
type Store = {
  cart: Cart
  setName: (name: string) => void
  addProduct: (product: Product) => void
  handleQuantityChange: (productId: string, quantity: number) => void
  removeProduct: (productId: string) => void
}

const tempCart = localStorageManager.getTempCart()

const saveCartToLocalStorage = (cart: Cart) => {
  localStorageManager.setTempCart(JSON.stringify(cart))
}

const useStore = create<Store>(
  (set: SetState<Store>, get: GetState<Store>) => ({
    cart: tempCart ? JSON.parse(tempCart) : { products: [], name: null },
    setName: name => {
      const { cart } = get()
      const newCart = {
        products: cart.products,
        name: name
      }
      set({ cart: newCart })
      saveCartToLocalStorage(newCart)
    },
    addProduct: product => {
      const { cart } = get()
      const cartById = cart.products.find(
        ({ product: { id } }) => id === product.id
      )

      if (cartById) {
        cartById.quantity++
      } else {
        cart.products.push({
          product,
          quantity: 1
        })
      }

      set({ cart })
      saveCartToLocalStorage(cart)
    },
    handleQuantityChange: (productId, quantity) => {
      const { cart } = get()
      const cartById = cart.products.find(
        ({ product: { id } }) => id === productId
      )
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      cartById!.quantity = quantity
      set({ cart })
      saveCartToLocalStorage(cart)
    },
    removeProduct: productId => {
      const { cart } = get()
      const newCart = {
        products: cart.products.filter(
          ({ product: { id } }) => id !== productId
        ),
        name: cart.name
      }
      set({ cart: newCart })
      saveCartToLocalStorage(newCart)
    }
  })
)

export default useStore
