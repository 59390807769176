import React from 'react'
import { useCart } from 'fetchers/cart'

import { CartStatus } from 'models/cart'

import Main from 'layouts/Main'
import PendingForVerification from 'scenes/PendingForVerification'
import CartFailed from 'scenes/CartFailed'
import CartExpired from 'scenes/CartExpired'
import PaymentCompleted from 'scenes/PaymentCompleted'
import NoProduct from 'scenes/NoProduct'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Spinner
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import localStorageManager from 'utils/localStorageManager'
import useCartId from 'utils/useCartId'

export interface CheckoutContainerProps {
  children: React.ReactElement
}

function CheckoutContainer(props: CheckoutContainerProps) {
  const id = useCartId()
  const { query } = useCart(id)

  React.useEffect(() => {
    if (query.data && query.data.currency !== null) {
      localStorageManager.setCurrency(query.data.currency)
    }
  }, [query.data])

  if (query.isFetched && query.data && query.data.products.length === 0) {
    return (
      <Main>
        <NoProduct />
      </Main>
    )
  }

  if (!query.isFetched) {
    return (
      <Box d='flex' alignItems='center' justifyContent='center' height='30vh'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </Box>
    )
  }

  if (query.data) {
    switch (query.data.cartStatus) {
      case CartStatus.Pending:
      case CartStatus.WaitingForPayment: {
        return props.children
      }
      case CartStatus.OnlinePayment:
      case CartStatus.CashOnDelivery:
      case CartStatus.PickUp:
      case CartStatus.PendingForVerification: {
        return (
          <PendingForVerification
            id={id}
            showEditButton={query.data?.editCartEnabled ?? true}
          />
        )
      }
      case CartStatus.Completed: {
        return <PaymentCompleted id={id} />
      }
      case CartStatus.Failed: {
        return (
          <Main>
            <CartFailed />
          </Main>
        )
      }
      case CartStatus.CartExpired: {
        return (
          <Main>
            <CartExpired />
          </Main>
        )
      }
    }
  }

  return (
    <Main>
      <Box maxWidth='60ch' margin='auto' mt='36px'>
        <Alert status='error'>
          <AlertIcon />
          <Box flex='1'>
            <AlertTitle>
              <Trans>Cart not found!</Trans>
            </AlertTitle>
            <AlertDescription display='block'>
              <Trans>Sorry, the ID you entered does not match any cart.</Trans>
              <br />
              <Trans>Please contact us if you believe this is an error.</Trans>
            </AlertDescription>
          </Box>
        </Alert>
      </Box>
    </Main>
  )
}

export default CheckoutContainer
