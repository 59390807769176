import React from 'react'
import { Box, Image, Flex } from '@chakra-ui/react'

import { ProductImage } from 'models/productImage'
import { useState } from 'react'

interface ProductGalleryProps {
  images: ProductImage[]
}

export default function ProductGallery({ images }: ProductGalleryProps) {
  const [selectedImage, setSelectedImage] = useState<ProductImage>(images[0])

  return (
    <Box>
      <Box rounded='sm' maxWidth={{ base: '300px', md: '400px' }}>
        <Image src={selectedImage.url} />
      </Box>
      <Flex mt={4}>
        {images.map((image, index) => (
          <Box
            key={index}
            rounded='sm'
            borderColor={image.url === selectedImage.url ? 'gray.600' : 'white'}
            borderWidth='1px'
            mr={2}
            onClick={() => setSelectedImage(image)}
            cursor='pointer'
          >
            <Image src={image.url} width={{ base: '80px', md: '100px' }} />
          </Box>
        ))}
      </Flex>
    </Box>
  )
}
