import React from 'react'

import { BuyerData } from 'models/cart'
import useCartId from 'utils/useCartId'
import { useCart } from 'fetchers/cart'

import ShippingForm from '../components/ShippingForm'

interface GuestAddressFormProps {
  onSubmit(): void
  formData?: BuyerData
}

export default function GuestAddressForm(props: GuestAddressFormProps) {
  const id = useCartId()
  const { deliveryInfoMutation } = useCart(id)

  const handleSubmitForm = (values: any) => {
    deliveryInfoMutation.mutate(
      {
        cartId: id,
        customerEmail: values.email,
        customerPhoneNumber: values.phoneNumber,
        deliveryAddress: values.deliveryAddress,
        recipient: values.recipient,
        deliveryInstruction: values.deliveryInstruction,
        wardCode: values.wardCode,
        districtId: values.districtId,
        provinceId: values.provinceId
      },
      {
        onSuccess: () => {
          props.onSubmit()
        }
      }
    )
  }

  return (
    <ShippingForm
      onSubmit={handleSubmitForm}
      isLoading={deliveryInfoMutation.isLoading}
      formData={props.formData}
    />
  )
}
