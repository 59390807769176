import React from "react";
import {
  InputProps as InputCoreProps,
  Input as InputCore,
} from "@chakra-ui/input";

import {
  TextareaProps as TextareaPropsCore,
  Textarea as TextareaCore,
} from "@chakra-ui/textarea";

export type InputProps = InputCoreProps & {
  ref?: any;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { ...otherProps } = props;
    return <InputCore ref={ref} {...otherProps} />;
  }
);

Input.displayName = "Input";

export type TextareaProps = TextareaPropsCore & {
  ref?: any;
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const { ...otherProps } = props;
    return <TextareaCore ref={ref} {...otherProps} />;
  }
);

Textarea.displayName = "Textarea";
