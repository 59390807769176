import { useBreakpointValue } from '@chakra-ui/react'

export default function useModalSize() {
  const modalSize = useBreakpointValue({
    base: 'full',
    md: 'md',
    lg: 'lg',
    xl: 'xl'
  })
  return modalSize
}
