import React, { useEffect } from 'react'
import { Container, Box, Text, Image, Spinner, Button } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { formatMoney } from 'utils/currency'
import useCartId from 'utils/useCartId'
import { useCart } from 'fetchers/cart'
import { usePayment, fetchCancelPayment } from 'fetchers/payment'
import { CartStatus } from 'models/cart'

import Main from 'layouts/Main'
import Card from 'components/Card'
import { TextField } from 'components/TextField'
import Dropzone from 'components/DropZone'

import Title from './components/Title'
import PreviewPaymentProof from './components/PreviewPaymentProof'
import { PaymentMode } from 'models/cart'
import './Payment.css'

export default function Payment() {
  const id = useCartId()
  const { query: cart } = useCart(id)
  const { query: paymentInstruction, payMutation } = usePayment(id)

  const { i18n } = useLingui()
  const {
    errors,
    getValues,
    setValue,
    register,
    trigger,
    handleSubmit
  } = useForm<{ paymentProof: File[] }>({
    reValidateMode: 'onChange',
    mode: 'all'
  })

  useEffect(() => {
    register(
      { name: 'paymentProof' },
      { required: i18n._(t`Transfer receipt is required.`) }
    )
  }, [register, i18n])

  const paymentProof = getValues('paymentProof')

  const handleChangePaymentProof = (files: File[]) => {
    setValue('paymentProof', files)
    trigger('paymentProof')
  }

  const handleClearPaymentProof = () => {
    setValue('paymentProof', '')
    trigger('paymentProof')
  }

  const handleSubmitForm = async (values: { paymentProof: File[] }) => {
    if (cart.data?.cartStatus === CartStatus.WaitingForPayment) {
      await fetchCancelPayment(id)
    }
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      function () {
        payMutation.mutate({
          paymentMode: PaymentMode.Offline,
          cartId: id,
          paymentProof: reader.result as string,
          promotionId: null
        })
      },
      false
    )

    reader.readAsDataURL(values.paymentProof[0])
  }

  return (
    <Main title={<Title />}>
      <Container mt={5}>
        {cart.data && (
          <Card>
            <Box display='flex' justifyContent='space-between' p={3}>
              <Text fontWeight='bold'>
                <Trans>Total</Trans>
              </Text>
              <Text fontWeight='bold'>{formatMoney(cart.data.total)}</Text>
            </Box>
          </Card>
        )}
        <Card title={<Trans>Transfer information</Trans>} mt={3}>
          <Box p={3} pt={0}>
            {paymentInstruction.isLoading && <Spinner />}
            {paymentInstruction.data && (
              <>
                {paymentInstruction.data.map(instruction => (
                  <Box
                    key={instruction.id}
                    d='flex'
                    mb={3}
                    borderTopWidth='1px'
                    borderColor='gray'
                    pt={3}
                    alignItems='flex-start'
                  >
                    {instruction.image && (
                      <Image
                        borderRadius={10}
                        borderColor='gray'
                        borderWidth='3px'
                        borderStyle='solid'
                        mr={3}
                        width='100px'
                        src={instruction.image}
                      />
                    )}
                    <Box>
                      <Text fontWeight='bold' fontSize='md'>
                        {instruction.title}
                      </Text>
                      <div
                        className='description'
                        dangerouslySetInnerHTML={{
                          __html: instruction.description
                        }}
                      />
                    </Box>
                  </Box>
                ))}

                <TextField
                  mt={3}
                  isRequired={true}
                  error={Boolean((errors.paymentProof as any)?.message)}
                  helperText={(errors.paymentProof as any)?.message}
                  inputProps={{ name: 'paymentProof' }}
                  renderInput={
                    <>
                      {paymentProof && paymentProof.length > 0 && (
                        <Box d='flex'>
                          <PreviewPaymentProof
                            onClear={handleClearPaymentProof}
                            file={paymentProof[0]}
                          />
                        </Box>
                      )}
                      <Box display={paymentProof ? 'none' : ''}>
                        <Dropzone
                          onChange={handleChangePaymentProof}
                          options={{
                            maxFiles: 1,
                            maxSize: 5 * 1024 * 1024,
                            accept: 'image/*'
                          }}
                        />
                      </Box>
                    </>
                  }
                  label={<Trans>Upload your bank transfer receipt</Trans>}
                />
              </>
            )}

            <Button
              variant='primary'
              w='full'
              mt={3}
              onClick={handleSubmit(handleSubmitForm)}
              isLoading={payMutation.isLoading}
            >
              <Trans>Send</Trans>
            </Button>
          </Box>
        </Card>
      </Container>
    </Main>
  )
}
