import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'
import { I18nProvider } from '@lingui/react'
import { useLingui } from '@lingui/react'

export default function useToast(options?: UseToastOptions) {
  const { i18n } = useLingui()
  const description = options?.description

  const descriptionWithI18n = (
    <I18nProvider i18n={i18n}>{description}</I18nProvider>
  )

  const toast = useChakraToast({
    ...options,
    description: descriptionWithI18n
  })

  const toastImpl = (options?: UseToastOptions | undefined) => {
    const description = options?.description

    const descriptionWithI18n = (
      <I18nProvider i18n={i18n}>{description}</I18nProvider>
    )

    toast({
      position: 'top',
      isClosable: true,
      status: 'error',
      duration: 7000,
      ...options,
      description: descriptionWithI18n
    })
  }

  toastImpl.close = toast.close
  toastImpl.closeAll = toast.closeAll
  toastImpl.toast = toast.isActive
  toastImpl.update = toast.update

  return toastImpl
}
