import { createContext } from 'react'

import { BuyerData } from 'models/cart'
import { PaymentChannel } from 'models/order'

interface CheckoutContextInterface {
  formData: BuyerData | null
  setFormData: React.Dispatch<React.SetStateAction<BuyerData | null>>
  paymentMode: PaymentChannel
  setPaymentMode: React.Dispatch<React.SetStateAction<PaymentChannel>>
}

const CheckoutContext = createContext<CheckoutContextInterface>(
  {} as CheckoutContextInterface
)

export default CheckoutContext
