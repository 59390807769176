import { useState } from 'react'
import {
  Text,
  Box,
  Flex,
  useColorModeValue,
  HStack,
  Image,
  AspectRatio
} from '@chakra-ui/react'
// import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

interface CarouselProps {
  images: string[]
}

export default function Carousel(props: CarouselProps) {
  const arrowStyles = {
    cursor: 'pointer',
    top: '50%',
    w: 'auto',
    mt: '-22px',
    p: '16px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    transition: '0.6s ease',
    borderRadius: '0 3px 3px 0',
    _hover: {
      opacity: 0.8,
      bg: 'black'
    }
  }

  const { images } = props

  const [currentSlide, setCurrentSlide] = useState(0)

  const slidesCount = images.length

  const prevSlide = () => {
    setCurrentSlide(s => (s === 0 ? slidesCount - 1 : s - 1))
  }
  const nextSlide = () => {
    setCurrentSlide(s => (s === slidesCount - 1 ? 0 : s + 1))
  }
  const setSlide = (slide: number) => {
    setCurrentSlide(slide)
  }
  const carouselStyle = {
    transition: 'all .5s',
    ml: `-${currentSlide * 100}%`
  }

  return (
    <Flex
      w='full'
      bg={useColorModeValue('gray.200', 'gray.600')}
      alignItems='center'
      justifyContent='center'
    >
      <Flex w='full' overflow='hidden' pos='relative'>
        <Flex w='full' {...carouselStyle}>
          {images.map((image, sid) => (
            <Box key={`slide-${sid}`} boxSize='full' flex='none'>
              <Text
                color='white'
                fontSize='xs'
                p='8px 12px'
                pos='absolute'
                top='0'
              >
                {sid + 1} / {slidesCount}
              </Text>
              <AspectRatio ratio={1}>
                <Image
                  src={image}
                  width='100%'
                  height='100%'
                  objectFit='cover'
                  borderRadius='12px'
                />
              </AspectRatio>
              {/* <LazyLoadImage
                effect='blur'
                src={image}
                style={{
                  display: 'block',
                  marginBottom: '-1rem',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '12px'
                }}
              /> */}
            </Box>
          ))}
        </Flex>
        {images.length > 1 && (
          <>
            <Text
              {...arrowStyles}
              pos='absolute'
              userSelect='none'
              left='0'
              onClick={e => {
                e.stopPropagation()
                prevSlide()
              }}
            >
              &#10094;
            </Text>
            <Text
              {...arrowStyles}
              pos='absolute'
              userSelect='none'
              right='0'
              onClick={e => {
                e.stopPropagation()
                nextSlide()
              }}
            >
              &#10095;
            </Text>

            <HStack justify='center' pos='absolute' bottom='8px' w='full'>
              {Array.from({ length: slidesCount }).map((_, slide) => (
                <Box
                  key={`dots-${slide}`}
                  cursor='pointer'
                  boxSize={['7px', '', '15px']}
                  m='0 2px'
                  bg={
                    currentSlide === slide ? 'blackAlpha.800' : 'blackAlpha.500'
                  }
                  rounded='50%'
                  display='inline-block'
                  transition='background-color 0.6s ease'
                  _hover={{ bg: 'blackAlpha.800' }}
                  onClick={e => {
                    e.stopPropagation()
                    setSlide(slide)
                  }}
                />
              ))}
            </HStack>
          </>
        )}
      </Flex>
    </Flex>
  )
}
