import { useQuery, useMutation, useQueryClient } from 'react-query'

import request, { handleErrors, RequestError } from '../utils/request'
import config from '../config'
import { BuyerData } from '../models/cart'

export async function fetchLogin(): Promise<undefined> {
  await request({
    method: 'POST',
    url: config.apis.login()
  })

  return
}

export async function fetchSaveBuyerData(data: BuyerData): Promise<undefined> {
  await request({
    method: 'POST',
    url: config.apis.save(),
    data
  })

  return
}

export async function fetchUpdateBuyerData(
  data: BuyerData
): Promise<undefined> {
  await request({
    method: 'PUT',
    url: config.apis.updateBuyerData(),
    data
  })

  return
}

export async function fetchDeleteBuyerData(id: string) {
  await request({
    method: 'DELETE',
    url: config.apis.deleteBuyerData(id)
  })

  return
}

export async function fetchGetBuyerData(): Promise<BuyerData[]> {
  const result = await request<BuyerData[]>({
    method: 'GET',
    url: config.apis.getBuyerData()
  })

  return result.data
}

function fetchBuyerDataWrap() {
  return async () => {
    return await fetchGetBuyerData()
  }
}

export function useBuyer() {
  const queryClient = useQueryClient()
  const buyerKey = 'buyer'

  const query = useQuery<BuyerData[], RequestError, BuyerData[]>(
    buyerKey,
    fetchBuyerDataWrap(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )

  const saveBuyerDataMutation = useMutation(fetchSaveBuyerData, {
    onSuccess: () => {
      queryClient.invalidateQueries(buyerKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  const updateBuyerDataMutation = useMutation(fetchUpdateBuyerData, {
    onSuccess: () => {
      queryClient.invalidateQueries(buyerKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  const deleteBuyerDataMutation = useMutation(fetchDeleteBuyerData, {
    onSuccess: () => {
      queryClient.invalidateQueries(buyerKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  return {
    query,
    saveBuyerDataMutation,
    updateBuyerDataMutation,
    deleteBuyerDataMutation
  }
}
