import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import UserSettingsContext from 'contexts/UserSettingsContext'

Sentry.init({
  dsn:
    'https://b75d1f0bfd304285b0202cb696d1005f@o166067.ingest.sentry.io/5999552',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1
})

ReactDOM.render(
  <React.StrictMode>
    <UserSettingsContext>
      <Auth0Provider
        domain='kaio-buyer.au.auth0.com'
        clientId='1XbuZSllJ7r1mNQjvg8tyT9nzjoSXbnU'
        audience='https://kaio-buyer.au.auth0.com/api/v2/'
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        cacheLocation={'localstorage'}
      >
        <App />
      </Auth0Provider>{' '}
    </UserSettingsContext>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
