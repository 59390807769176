import React from 'react'
import logo from '../../assets/images/kaio.png'

interface LogoProps {
  backgroundFill?: string
  iconFill?: string
  textFill?: string
  size?: number
}

export default function Logo({ size = 100 }: LogoProps): React.ReactElement {
  return (
    <img
      src={logo}
      alt=''
      style={{
        width: size,
        height: size
      }}
    />
  )
}
