import Box from 'components/Box'
import { Spinner } from '@chakra-ui/react'
import ProductList from './components/ProductList'
import useOrganizationId from 'utils/useOrganizationId'
import { useCatalogProducts } from 'fetchers/catalog'
import useCatalogId from 'utils/useCatalogId'

export interface CatalogProps {
  tracker: string
}

function Catalog() {
  const tracker = useOrganizationId()
  const catalogId = useCatalogId()
  const { query } = useCatalogProducts(tracker, catalogId)
  const products = query.data?.data

  return (
    <Box mt={4}>
      {query.isFetching ? (
        <Spinner />
      ) : (
        products && <ProductList products={products} />
      )}
    </Box>
  )
}

export default Catalog
