import React from 'react'

import localStorageManager from 'utils/localStorageManager'

import Main from 'layouts/Main'
import ShopContainer from 'containers/ShopContainer'
import NoProduct from 'scenes/NoProduct'
import ShopContext from 'contexts/ShopContext'
import { useOrganizationFromHost } from 'fetchers/organization'

export interface ShopSwitchProps {
  id: string
  catalogId: string
  categoryId: string
  children: React.ReactElement
}

function ShopSwitch(props: ShopSwitchProps) {
  const currentHost = `${window.location.hostname}`

  const { query: organization } = useOrganizationFromHost(currentHost)

  if (!organization.data) {
    return (
      <Main>
        <NoProduct />
      </Main>
    )
  }

  if (
    organization.data &&
    localStorageManager.getOrganizationId() !== organization.data
  ) {
    localStorageManager.setOrganizationId(organization.data)
  }

  return (
    <ShopContext.Provider
      value={{
        organizationId: organization.data,
        catalogId: props.catalogId,
        categoryId: props.categoryId
      }}
    >
      <ShopContainer>{props.children}</ShopContainer>
    </ShopContext.Provider>
  )
}

export default ShopSwitch
