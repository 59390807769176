import React from 'react'
import { Button } from '@chakra-ui/react'

export interface StickyButtonProps {
  label: React.ReactElement
  onOpen: () => void
  leftIcon: React.ReactElement
}

function StickyButton(props: StickyButtonProps) {
  return (
    <Button
      position={'fixed'}
      bottom={'125px'}
      right='20px'
      zIndex='999'
      variant='primary'
      leftIcon={props.leftIcon}
      size='md'
      onClick={props.onOpen}
      ml={3}
    >
      {props.label}
    </Button>
  )
}

export default StickyButton
