import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { get } from 'lodash'
// import { Auth0Client } from '@auth0/auth0-spa-js'
import { createStandaloneToast } from '@chakra-ui/toast'
import localStorageManager from 'utils/localStorageManager'

const TIMEOUT = 1 * 60 * 1000

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BUYER_ENDPOINT,
  timeout: TIMEOUT
})

export const setupAxiosInterceptors = (
  token: string | null,
  onUnauthenticated: () => void
): void => {
  const onRequestSuccess = async (config: AxiosRequestConfig) => {
    config.headers['Accept-Language'] =
      localStorageManager.getLang() === 'en' ? 'en-US' : 'vi-VN'

    try {
      // const a0 = new Auth0Client({
      //   domain: 'kaio-buyer.au.auth0.com',
      //   client_id: '1XbuZSllJ7r1mNQjvg8tyT9nzjoSXbnU',
      //   audience: 'https://kaio-buyer.au.auth0.com/api/v2/'
      // })

      // const accessToken = await a0.getTokenSilently()

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    } catch (error) {
      // Unauthenticated user, return default config
    }

    return config
  }

  const onResponseSuccess = (response: any) => response
  const onResponseError = (err: any) => {
    const status = err.status || get(err, 'response.status')
    if (status === 403 || status === 401) {
      onUnauthenticated()
    }
    return Promise.reject(err)
  }
  axiosInstance.interceptors.request.use(onRequestSuccess)
  axiosInstance.interceptors.response.use(onResponseSuccess, onResponseError)
}

export const getValidationErrors = (axiosError: AxiosError<any>): string[] => {
  return Object.values(axiosError.response?.data.errors).reduce<string[]>(
    (final, current) => {
      final.push(...(current as any))
      return final
    },
    []
  )
}

export type RequestResponse<T = any> = AxiosResponse<T>

export type RequestError<T = any> = AxiosError<T>

export interface RequestConfig extends AxiosRequestConfig {}

export default function request<T = any>(
  config: RequestConfig
): Promise<RequestResponse<T>> {
  return axiosInstance.request(config)
}

export const handleErrors = (error: unknown) => {
  const errors = getValidationErrors(error as AxiosError)
  const toast = createStandaloneToast()

  if (errors && errors.length > 0) {
    errors.map(error =>
      toast({
        // title: 'An error occurred.',
        position: 'top',
        description: error,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    )
  }
}
