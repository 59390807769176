import { useNumberInput, HStack, Button, Input } from '@chakra-ui/react'

interface QuantityInputProps {
  defaultValue: number
  onChange: (value: number) => void
  onIncrease?: () => void
  onDecrease?: () => void
  disabled?: boolean
}

export default function QuantityInput({
  defaultValue,
  onChange,
  disabled,
  onIncrease,
  onDecrease
}: QuantityInputProps) {
  const {
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
    value
  } = useNumberInput({
    step: 1,
    min: 1,
    defaultValue: defaultValue,
    value: defaultValue
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  const handleChange = () => {
    onChange(value as number)
  }

  return (
    <HStack w='200px'>
      <Button {...dec} size='sm' disabled={disabled} onClick={onDecrease}>
        -
      </Button>
      <Input {...input} size='sm' disabled onBlur={handleChange} />
      <Button {...inc} size='sm' disabled={disabled} onClick={onIncrease}>
        +
      </Button>
    </HStack>
  )
}
