import { useState } from 'react'
import Box from 'components/Box'
import { Text, Button, Flex } from '@chakra-ui/react'
import { FiShoppingCart } from 'react-icons/fi'
import { Trans } from '@lingui/macro'
import { formatMoney } from 'utils/currency'
import ProductDetail from './ProductDetail'
import useAddToCart from './useAddToCart'
import Carousel from 'components/Carousel'
import { Product } from 'models/product'

export interface ProductListProps {
  products: Product[]
}

export default function ProductList({ products }: ProductListProps) {
  const addToCart = useAddToCart()
  const [product, setProduct] = useState<Product | null>(null)

  const getProductImage = (product: Product) => {
    if (!product.productImages || !product.productImages.length) {
      return ['/images/shop-logo.svg']
    }

    return product.productImages
      .sort((a, b) => a.ordinal - b.ordinal)
      .map(image => image.url)
      .splice(0, 5)
  }

  return (
    <>
      <Flex flexWrap='wrap'>
        {products.map(product => (
          <Box
            bg='white'
            cursor='pointer'
            mt={4}
            key={product.id}
            flexBasis={{
              xl: '20%',
              lg: '25%',
              sm: '50%',
              base: '50%'
            }}
            borderColor='gray.200'
            borderWidth='1px'
            p={2}
          >
            <Flex justifyContent='center' onClick={() => setProduct(product)}>
              <Carousel images={getProductImage(product)}></Carousel>
            </Flex>

            <Box>
              <Box p='4' pb='2'>
                <Text lineHeight='tight'>{product.productCode}</Text>
                <Text noOfLines={1}>{product.productName}</Text>
                <Text mt={1} fontWeight='bold'>
                  {formatMoney(product.price)}
                </Text>
              </Box>
              <Box p='2' bg='white'>
                {product.productStock > 0 ? (
                  <>
                    <Button
                      leftIcon={<FiShoppingCart />}
                      isFullWidth
                      variant='primary'
                      onClick={e => {
                        e.stopPropagation()
                        if (product && product.isAds && product.url && window) {
                          window.open(product.url, '_blank')!.focus()
                        } else {
                          addToCart(product)
                        }
                      }}
                    >
                      <Trans>Buy it now</Trans>
                    </Button>
                    <Button
                      size='sm'
                      mt={2}
                      isFullWidth
                      variant='outline'
                      onClick={() => setProduct(product)}
                    >
                      <Trans>View Detail</Trans>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button isFullWidth colorScheme={'gray'} disabled={true}>
                      <Trans>Out of stock</Trans>
                    </Button>
                    <Button
                      size='sm'
                      mt={2}
                      isFullWidth
                      colorScheme={'gray'}
                      variant='outline'
                      onClick={() => setProduct(product)}
                    >
                      <Trans>View Detail</Trans>
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Flex>
      <ProductDetail
        isOpen={!!product}
        product={product}
        onClose={() => setProduct(null)}
      />
    </>
  )
}
