import React, { FunctionComponent } from 'react'
import { UserSettingsContext } from 'contexts/UserSettingsContext'
import {
  Menu,
  MenuButton,
  MenuList,
  Button,
  MenuOptionGroup,
  MenuItemOption,
  Image,
  Box
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'

const LanguageDropDown: FunctionComponent = () => {
  const { locale, setLocale } = React.useContext(UserSettingsContext)

  const onChangeLocale = (value: string | string[]) => {
    setLocale(value as string)
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        variant='solid'
        px={2}
      >
        {locale === 'en' ? (
          <Image src='/images/en.svg' w={7} />
        ) : (
          <Image src='/images/vi.svg' w={7} />
        )}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={locale}
          type='radio'
          onChange={onChangeLocale}
        >
          <MenuItemOption value='en'>
            <Box d='flex' alignItems='center' justifyContent='space-between'>
              English <Image src='/images/en.svg' w={5} />
            </Box>
          </MenuItemOption>
          <MenuItemOption value='vi'>
            <Box d='flex' alignItems='center' justifyContent='space-between'>
              Tiếng Việt <Image src='/images/vi.svg' w={5} />
            </Box>
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

export default LanguageDropDown
