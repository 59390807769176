import { Box, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Card from 'components/Card'
import { Cart as CartInterface } from 'models/cart'
import { formatMoney } from 'utils/currency'
import Cart from './Cart'

interface CartInfoProps {
  data: CartInterface
}

export default function CartInfo(props: CartInfoProps) {
  const { data } = props
  const discount = data?.discount
  return (
    <>
      <Cart
        products={data.products}
        pageName={data.pageName}
        permalinkUrl={data.permalinkUrl}
      />
      {!!discount && (
        <Card title={<Trans>Discount</Trans>}>
          {formatMoney(Math.min(discount, data.subTotal))}
        </Card>
      )}{' '}
      <Card>
        <Text textAlign={'right'} p={3}>
          <Box as='span' fontWeight={'bold'}>
            <Trans>Delivery Fee</Trans>
          </Box>
          : {formatMoney(data.deliveryFee)}
        </Text>
      </Card>
      <Card>
        <Text textAlign={'right'} textStyle={'sectionTitle'} p={3}>
          <Trans>Total</Trans>: {formatMoney(data.total)}
        </Text>
      </Card>
    </>
  )
}
