import { Link, Text, Flex } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Trans } from '@lingui/macro'

export default function Title() {
  return (
    <Flex>
      <Link href='/' color='white' fontWeight='bold' fontSize='xl'>
        <ArrowBackIcon mr={1} />
      </Link>
      <Text color='white' fontWeight='bold' fontSize='xl'>
        <Trans>Checkout</Trans>
      </Text>
    </Flex>
  )
}
