import { createContext } from 'react'

const ShopContext = createContext<{
  organizationId: string
  catalogId: string
  categoryId: string
}>({
  organizationId: '',
  catalogId: '',
  categoryId: ''
})

export default ShopContext
