import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Main from 'layouts/Main'

export default function StoreMerchant() {
  const toHomeLink = (
    <Link as={RouterLink} to='/' color='white' fontWeight='bold' fontSize='xl'>
      <Trans>Your cart</Trans>
    </Link>
  )
  return <Main title={toHomeLink}>StoreMerchant</Main>
}
