import { useEffect, useState } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerProps,
  Button,
  Box,
  Text,
  Divider,
  Input
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { CloseIcon } from '@chakra-ui/icons'
import { useForm } from 'react-hook-form'

import QuantityInput from 'components/QuantityInput'
import { formatMoney } from 'utils/currency'
import useStore from 'store'
import request from 'utils/request'
import config from 'config'
import useOrganizationId from 'utils/useOrganizationId'
import useToast from 'utils/useToast'

import ProductThumbnail from './ProductThumbnail'
import { Product } from 'models/product'

type CustomerForm = { customerName: string; phoneNumber: string }

export default function CartDrawer(props: Omit<DrawerProps, 'children'>) {
  const { isOpen, onClose, ...drawerProps } = props
  const [tempName, setTempName] = useState<string | null>(null)
  const { cart, handleQuantityChange, removeProduct, setName } = useStore()
  const organizationId = useOrganizationId()
  const { handleSubmit } = useForm<CustomerForm>({
    reValidateMode: 'onChange'
  })
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const total = cart.products.reduce(
    (accumulator: number, value: { product: Product; quantity: number }) => {
      return value.product.price * value.quantity + accumulator
    },
    0
  )

  useEffect(() => {
    if (cart.name) {
      setTempName(cart.name)
    }
  }, [cart])

  const handleSubmitForm = async () => {
    try {
      setLoading(true)
      if (tempName) {
        setName(tempName)
      }
      const { data } = await request({
        method: 'POST',
        url: config.apis.createCart(),
        data: {
          organizationId,
          name: tempName,
          products: cart.products.map(({ product, quantity }) => ({
            productId: product.id,
            quantity
          }))
        }
      })

      window.location.replace(`/${data}`)
    } catch (err) {
      toast({
        status: 'error',
        description: <Trans>Something went wrong. Please try again.</Trans>
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement='right'
      size='md'
      {...drawerProps}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Your cart</DrawerHeader>

        <DrawerBody>
          {cart.products.length === 0 ? (
            <Text>
              <Trans>Your cart is currently empty.</Trans>
            </Text>
          ) : (
            <>
              {cart.products.map(({ product, quantity }) => (
                <Box key={product.id}>
                  <Divider />
                  <Box d='flex' py={3}>
                    <ProductThumbnail
                      productImages={product.productImages}
                      width='100px'
                      mr={10}
                    />
                    <Box flex='1'>
                      <Text mb={2}>{product.productName}</Text>
                      <Text fontWeight='bold' mb={4}>
                        {formatMoney(product.price)}
                      </Text>
                      <QuantityInput
                        defaultValue={quantity}
                        onDecrease={() =>
                          handleQuantityChange(product.id, quantity - 1)
                        }
                        onIncrease={() =>
                          handleQuantityChange(product.id, quantity + 1)
                        }
                        onChange={quantity =>
                          handleQuantityChange(product.id, quantity)
                        }
                      />
                    </Box>
                    <CloseIcon
                      cursor='pointer'
                      h={3}
                      alignSelf='center'
                      color='gray.400'
                      onClick={() => removeProduct(product.id)}
                    />
                  </Box>
                </Box>
              ))}
              <Divider />
            </>
          )}
        </DrawerBody>

        <DrawerFooter>
          <Box w='100%' d='flex' flexDirection='column'>
            {!!cart.products.length && (
              <>
                <Divider />
                <Box textAlign='center' fontSize='lg' py={4}>
                  <Text mb={1} fontWeight='semibold'>
                    <Trans>Total</Trans>
                  </Text>
                  <Text fontWeight='bold'>{formatMoney(total)}</Text>
                </Box>
                <Divider />
              </>
            )}

            <Box w='100%' d='flex' flexDirection='row' mt={4}>
              <Text mb={1} fontWeight='semibold'>
                <Trans>Name</Trans>
              </Text>
              <Input
                value={tempName || ''}
                onChange={e => setTempName(e.target.value)}
              />
            </Box>

            <Box alignSelf='flex-end' mt={4}>
              <Button variant='outline' mr={3} onClick={onClose}>
                <Trans>Continue shopping</Trans>
              </Button>
              {!!cart.products.length && (
                <Button
                  variant='primary'
                  isLoading={loading}
                  onClick={handleSubmit(handleSubmitForm)}
                  disabled={tempName === null || tempName === ''}
                >
                  <Trans>Checkout</Trans>
                </Button>
              )}
            </Box>
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
