import { useAuth0 } from '@auth0/auth0-react'

import Card from 'components/Card'

import GuestAddress from './guest/GuestAddress'
import BuyerAddress from './buyer/BuyerAddress'
import { Trans } from '@lingui/macro'

export default function Shipping() {
  const { isAuthenticated } = useAuth0()

  return (
    <Card title={<Trans>Shipping Address</Trans>}>
      {isAuthenticated ? <BuyerAddress /> : <GuestAddress />}
    </Card>
  )
}
