import { Trans } from '@lingui/macro'
import {
  Progress,
  Link,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Image,
  Text
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useCart } from 'fetchers/cart'
import useCartId from 'utils/useCartId'
import { getValidationErrors, handleErrors } from 'utils/request'

import Box from 'components/Box'
import AppContainer from 'components/AppContainer'
import Main from 'layouts/Main'
import Summary from './components/Summary'
import { AxiosError } from 'axios'
import useToast from 'utils/useToast'
import { useEffect, useState } from 'react'
import Products from './components/Products'
import PageHeader from 'components/PageHeader'
import { useForm } from 'react-hook-form'
import PromotionForm from './components/PromotionForm'
import { PaymentChannel } from 'models/order'
import Card from 'components/Card'
import { CartStatus, Currency } from 'models/cart'
import {
  fetchCancelPayment,
  fetchStripeCheckout,
  usePayment
} from 'fetchers/payment'
import { loadStripe } from '@stripe/stripe-js'

interface PromotionForm {
  promotionCode: string
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string)

function Confirmation() {
  const id = useCartId()
  const history = useHistory()

  const [paymentMode, setPaymentMode] = useState<PaymentChannel>(
    PaymentChannel.CashOnDelivery
  )

  const {
    query,
    shippingOptionsQuery,
    increaseMutation,
    decreaseMutation,
    deleteMutation,
    promotionMutation,
    pickShippingMutation,
    deliveryInfoMutation
  } = useCart(id)

  const { payMutation } = usePayment(id)

  const shouldShowStripe =
    query.data &&
    query.data.currency === Currency.AUD &&
    query.data.onlinePaymentEnabled

  const toast = useToast()

  const promotionForm = useForm<PromotionForm>({
    reValidateMode: 'onChange',
    mode: 'all'
  })

  useEffect(() => {
    if (query.data && !query.data.deliveryOptionId) {
      if (
        shippingOptionsQuery.data &&
        shippingOptionsQuery.data.length &&
        !query.data?.deliveryOptionId &&
        !query.data?.isGiaoHangNhanhEnabled
      ) {
        try {
          pickShippingMutation.mutate({
            cartId: id,
            deliveryOptionId: shippingOptionsQuery.data[0].id
          })
        } catch (err) {
          return
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    setError: setPromotionError,
    getValues: getPromotionValues,
    setValue: setPromotionValue
  } = promotionForm

  function resetPromotionCode() {
    setPromotionValue('promotionCode', null)
    promotionMutation.mutate(
      {
        cartId: id,
        promotionCode: null
      },
      {
        onError(err) {
          handleErrors(err)
        }
      }
    )
  }

  function handleApplyCode(values: PromotionForm) {
    promotionMutation.mutate(
      {
        cartId: id,
        promotionCode: values.promotionCode || null
      },
      {
        onError(err) {
          const errors = getValidationErrors(err as AxiosError)
          setPromotionError('promotionCode', {
            message: errors[0],
            shouldFocus: true
          })
        }
      }
    )
  }

  useEffect(() => {
    if (query.data?.promotionCode && !getPromotionValues('promotionCode')) {
      setPromotionValue('promotionCode', query.data?.promotionCode)
    }
  }, [query.data?.promotionCode, getPromotionValues, setPromotionValue])

  const isLoading =
    (query.isFetched && query.isFetching) ||
    increaseMutation.isLoading ||
    deleteMutation.isLoading ||
    decreaseMutation.isLoading ||
    promotionMutation.isLoading ||
    pickShippingMutation.isLoading

  function pickShippingOption(deliveryOptionId: string) {
    pickShippingMutation.mutate(
      {
        cartId: id,
        deliveryOptionId
      },
      {
        onError(err) {
          const errors = getValidationErrors(err as AxiosError)
          toast({
            description: errors[0]
          })
        }
      }
    )
  }

  const handlePayment = async () => {
    if (!query.data) {
      return
    }

    if (paymentMode === PaymentChannel.CashOnDelivery) {
      if (query.data.cartStatus === CartStatus.WaitingForPayment) {
        await fetchCancelPayment(id)
      }

      const paymentMode: number = PaymentChannel.CashOnDelivery
      payMutation.mutate({
        paymentMode,
        cartId: id,
        paymentProof: null,
        promotionId: query.data.promotionId
      })

      return
    }

    if (paymentMode === PaymentChannel.Online) {
      const stripe = await stripePromise

      const sessionId: string = await fetchStripeCheckout({
        cartId: id,
        promotionId: query.data.promotionId
      })

      await stripe?.redirectToCheckout({
        sessionId
      })

      return
    }

    history.push('/payment')
  }

  const onProceed = async (address: string, phoneNumber: string) => {
    deliveryInfoMutation.mutate(
      {
        cartId: id,
        customerPhoneNumber: phoneNumber,
        deliveryAddress: address
      },
      {
        onSuccess: () => {
          handlePayment()
        }
      }
    )
  }

  const toConfirmationLink = (
    <Link href='/' color='white' fontWeight='bold' fontSize='xl'>
      <Trans>Your cart</Trans>
    </Link>
  )

  return (
    <Main title={toConfirmationLink}>
      {query.isFetched && query.data && (
        <AppContainer
          paddingX={{ base: 0, sm: '50px' }}
          pt={{ base: '0', sm: '24px' }}
        >
          <Box bg='white'>
            {query.data.pageName && query.data.permalinkUrl && (
              <PageHeader
                pageName={query.data.pageName}
                permalinkUrl={query.data.permalinkUrl}
                py={3}
              />
            )}
          </Box>
          <Products products={query.data.products} />
          <Divider />
          <PromotionForm
            form={promotionForm}
            promotionId={query.data.promotionId}
            handleApplyCode={handleApplyCode}
            handleRemoveCode={resetPromotionCode}
            isLoading={promotionMutation.isLoading}
          />
          <Divider />
          <Card title={<Trans>Payment Method</Trans>}>
            <RadioGroup
              p={3}
              defaultValue={paymentMode.toString()}
              onChange={(paymentMode: string) => {
                setPaymentMode(+paymentMode)
              }}
            >
              <Stack>
                {query.data.cashOnDeliveryPaymentEnabled ? (
                  <Radio value={PaymentChannel.CashOnDelivery.toString()}>
                    <Flex alignItems='center'>
                      <Image width={6} src='/images/cod.svg' />
                      <Text fontSize='sm' ml={2}>
                        <Trans>Cash on delivery</Trans>
                      </Text>
                    </Flex>
                  </Radio>
                ) : null}
                {query.data.offlinePaymentEnabled ? (
                  <Radio value={PaymentChannel.Offline.toString()} pt={1}>
                    <Flex alignItems='center'>
                      <Image width={6} src='/images/bank-transfer.svg' />
                      <Box ml={2}>
                        <Text fontSize='sm'>
                          <Trans>Bank transfer</Trans>
                        </Text>
                        <Text fontSize='sm' color='GrayText'>
                          <Trans>
                            You will be required to attach a transaction receipt
                          </Trans>
                        </Text>
                      </Box>
                    </Flex>
                  </Radio>
                ) : null}
                {shouldShowStripe ? (
                  <Radio value={PaymentChannel.Online.toString()} pt={1}>
                    <Flex alignItems='center'>
                      <Image width={6} src='/images/card.svg' />
                      <Text fontSize='sm' ml={2}>
                        <Trans>Pay with credit or debit card</Trans>
                      </Text>
                    </Flex>
                  </Radio>
                ) : null}
              </Stack>
            </RadioGroup>
          </Card>
          <Box>
            {isLoading && (
              <Progress
                size='xs'
                isIndeterminate
                position='absolute'
                width='100%'
              />
            )}
            <Summary
              pickShippingOption={pickShippingOption}
              shippingSettings={shippingOptionsQuery.data ?? []}
              deliveryOptionId={query.data.deliveryOptionId}
              total={query.data.total}
              isDisabled={isLoading}
              subTotal={query.data.subTotal}
              discount={query.data.discount}
              deliveryFee={query.data.deliveryFee}
              extraNote={query.data.extraNote}
              onProceed={onProceed}
            />
          </Box>
        </AppContainer>
      )}
    </Main>
  )
}

export default Confirmation
