import React, { useEffect } from 'react'
import { Box, Portal, BoxProps } from '@chakra-ui/react'

import useDimensions from 'react-cool-dimensions'

export interface FixedFooterProps {
  children?: React.ReactNode
}

export default function FixedFooter(props: FixedFooterProps & BoxProps) {
  const { children, ...rest } = props
  const { observe, height } = useDimensions()

  useEffect(() => {
    const rootElement = window.document.getElementById('root')
    if (rootElement) {
      rootElement.style.paddingBottom = `${height}px`
    }

    return () => {
      const rootElement = window.document.getElementById('root')
      if (rootElement) {
        rootElement.style.paddingBottom = ''
      }
    }
  }, [height])

  return (
    <Portal>
      <Box
        position='fixed'
        left={0}
        bottom={0}
        w='full'
        borderTopWidth={1}
        borderColor='gray'
        ref={observe}
        {...rest}
      >
        {children}
      </Box>
    </Portal>
  )
}
