import React from 'react'
import { IoIosCloseCircle } from 'react-icons/io'
import {
  Image,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  CloseButton,
  useDisclosure,
  useBreakpointValue
} from '@chakra-ui/react'

import Box, { BoxProps } from 'components/Box'

export interface PreviewPaymentProofProps extends BoxProps {
  file?: File
  onClear?: () => void
}

function PreviewPaymentProof(props: PreviewPaymentProofProps) {
  const { file, onClear, ...otherProps } = props
  const imgRef = React.useRef<HTMLImageElement | null>(null)
  const [src, setSrc] = React.useState<string | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const modalSize = useBreakpointValue({
    base: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl'
  })

  React.useEffect(() => {
    if (file && imgRef.current) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = function (e) {
        if (imgRef.current && e.target) {
          const src = e.target.result as string
          imgRef.current.src = src
          setSrc(src)
        }
      }
    }
  }, [file])

  return (
    <Box position='relative' boxShadow='lg' {...otherProps}>
      <IconButton
        variant='unstyled'
        onClick={onClear}
        position='absolute'
        top='0'
        right='0'
        aria-label='Delete payment proof'
        size='sm'
      >
        <IoIosCloseCircle fill='#B91C1C' size='32px' />
      </IconButton>
      <Image
        height='200px'
        objectFit='cover'
        ref={imgRef}
        alt='Preview payment proof'
        onClick={onOpen}
        cursor='pointer'
      />
      {src && (
        <Modal isOpen={isOpen} onClose={onClose} size={modalSize} isCentered>
          <ModalOverlay bg='rgba(0, 0, 0, 0.9)' />
          <ModalContent background='transparent'>
            <CloseButton
              size='lg'
              color='white'
              alignSelf='flex-end'
              mb={2}
              onClick={onClose}
            />
            <Image
              maxH='80vh'
              objectFit='contain'
              src={src}
              alt='Preview payment proof'
            />
          </ModalContent>
        </Modal>
      )}
    </Box>
  )
}

export default PreviewPaymentProof
