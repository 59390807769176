import React from 'react'
import Box from '../Box'
import { Button } from '../Button'

export type PaginationProps = {
  total?: number
  current: number
  generateLink: (number: number) => () => void
}

export type PaginationItemProps = {
  children?: React.ReactNode
  active?: boolean
  href: () => void
}

export function PaginationItem(props: PaginationItemProps) {
  const { children, active, href } = props
  const activeStyles = {
    color: active ? 'white' : 'inherit',
    bg: active ? 'primary' : 'transparent'
  }

  return (
    <Button
      {...activeStyles}
      border='1px solid #ccc'
      mr='8px'
      _hover={active ? activeStyles : undefined}
      _focus={active ? activeStyles : undefined}
      _active={active ? activeStyles : undefined}
      onClick={href}
    >
      {children}
    </Button>
  )
}

export function PaginationDot() {
  return (
    <Box display='inline-flex' mr='8px' cursor='not-allowed'>
      ...
    </Box>
  )
}

export function Pagination(props: PaginationProps) {
  const { current, total, generateLink } = props
  return (
    <nav>
      {current !== 1 && (
        <PaginationItem href={generateLink(current - 1)}>&lt;</PaginationItem>
      )}
      <PaginationItem active={1 === current} href={generateLink(1)}>
        {1}
      </PaginationItem>
      {current > 3 && <PaginationDot />}
      {/* {current === total && total > 3 && (
        <PaginationItem href={generateLink(current - 2)}>
          {current - 2}
        </PaginationItem>
      )} */}
      {current > 2 && (
        <PaginationItem href={generateLink(current - 1)}>
          {current - 1}
        </PaginationItem>
      )}
      {current !== 1 && current !== total && (
        <PaginationItem active={true} href={generateLink(current)}>
          {current}
        </PaginationItem>
      )}
      {total && (
        <React.Fragment>
          {current < total - 1 && (
            <PaginationItem href={generateLink(current + 1)}>
              {current + 1}
            </PaginationItem>
          )}
          {current === 1 && total > 3 && (
            <PaginationItem href={generateLink(current + 2)}>
              {current + 2}
            </PaginationItem>
          )}
          {/* {current < total - 2 && <PaginationDot />} */}
          {total < 100 && (
            <PaginationItem
              active={total === current}
              href={generateLink(total)}
            >
              {total}
            </PaginationItem>
          )}
        </React.Fragment>
      )}
      {current !== total && (
        <PaginationItem href={generateLink(current + 1)}>&gt;</PaginationItem>
      )}
    </nav>
  )
}
