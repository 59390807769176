import { Button, useDisclosure } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Box from 'components/Box'
import CheckoutContext from 'contexts/CheckoutContext'
import { useCart } from 'fetchers/cart'
import React, { useContext, useEffect } from 'react'
import useCartId from 'utils/useCartId'
import ShippingOverview from '../components/ShippingOverview'
import GuestEditAddressModal from './GuestEditAddressModal'
import GuestNewAddressModal from './GuestNewAddressModal'

export default function GuestAddress() {
  const id = useCartId()
  const { query } = useCart(id)
  const {
    isOpen: isOpenNew,
    onClose: onCloseNew,
    onOpen: onOpenNew
  } = useDisclosure()
  const {
    isOpen: isOpenChange,
    onClose: onCloseChange,
    onOpen: onOpenChange
  } = useDisclosure()
  const { formData, setFormData } = useContext(CheckoutContext)

  useEffect(() => {
    if (query.data && query.data.recipient) {
      setFormData({
        recipient: query.data.recipient,
        email: query.data.emailAddress,
        phoneNumber: query.data.phoneNumber,
        deliveryAddress: query.data.deliveryAddress,
        deliveryInstruction: query.data.deliveryInstruction,
        postalCode: query.data.postalCode,
        wardCode: query.data.wardCode,
        districtId: query.data.districtId,
        provinceId: query.data.provinceId
      })
    }
  }, [query, setFormData])

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        p={3}
      >
        {formData ? (
          <Button variant='link' color='primary' onClick={onOpenChange}>
            <Trans>Change</Trans>
          </Button>
        ) : (
          <Button variant='link' color='primary' onClick={onOpenNew}>
            <Trans>Add</Trans>
          </Button>
        )}
      </Box>

      <ShippingOverview formData={formData} />

      {formData && (
        <GuestEditAddressModal
          isOpen={isOpenChange}
          onClose={onCloseChange}
          formData={formData}
        />
      )}
      <GuestNewAddressModal isOpen={isOpenNew} onClose={onCloseNew} />
    </>
  )
}
