import React from 'react'

import { RiCheckboxCircleFill } from 'react-icons/ri'

import Main from 'layouts/Main'
import Box from 'components/Box'

export interface SuccessLayoutProps {
  children: React.ReactNode
}

function SuccessLayout(props: SuccessLayoutProps) {
  return (
    <Main d='flex' flexDirection='column'>
      <Box
        minHeight='100vh'
        bgImage="url('/images/confetti.png')"
        bgPosition='center'
        bgColor='primary'
        h='full'
        d='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        px={5}
      >
        <Box>
          <RiCheckboxCircleFill size={100} fill='white' />
        </Box>
        {props.children}
      </Box>
    </Main>
  )
}

export default SuccessLayout
