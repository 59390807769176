import { successColor } from './../utils/colors'
import { extendTheme } from '@chakra-ui/react'
import { breakpoints } from 'utils/useBreakpoint'

const theme = extendTheme({
  breakpoints,
  sizes: {
    container: {
      xl: 1400
    }
  },
  fonts: {
    body:
      'Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Open Sans, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;',
    heading:
      'Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Open Sans, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;',
    mono:
      'Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Open Sans, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;'
  },
  styles: {
    global: {
      body: {
        fontSize: '14px',
        background: 'secondary',
        color: '#141414'
      }
    }
  },
  colors: {
    primary: '#1d4ed8',
    secondary: '#f3f4f6',
    success: successColor,
    negative: '#B91C1C',
    warn: '#b45309'
  },
  zIndices: {
    modal: 1700
  },
  textStyles: {
    pageTitle: {
      fontWeight: 'bold',
      fontSize: {
        base: '28px',
        lg: '32px'
      },
      lineHeight: {
        base: '120%'
      }
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: {
        base: '20px',
        lg: '28px'
      },
      lineHeight: {
        base: '110%'
      }
    }
  },
  components: {
    Button: {
      variants: {
        primary: {
          bg: 'primary',
          color: '#fff'
        },
        danger: {
          bg: 'negative',
          color: '#fff'
        }
      }
    },

    Radio: {
      parts: ['label'],
      baseStyle: {
        label: {
          fontSize: '14px'
        }
      }
    },
    FormLabel: {
      baseStyle: {
        fontSize: '14px'
      }
    },
    Input: {
      baseStyle: {},
      sizes: {
        md: {
          field: {}
        }
      }
    },
    Table: {
      baseStyle: {
        th: {
          paddingLeft: '8px',
          paddingRight: '8px'
        }
      },
      sizes: {
        md: {
          th: {
            px: '8px'
          },
          td: {
            px: '8px'
          }
        }
      }
    }
  }
})

export { theme }
