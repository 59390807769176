export interface Order {
  id?: string
  cartId?: string
  orderStatus?: OrderStatus
  orderItems?: OrderItem[]
  orderTags?: OrderTag[]
  generalInformation?: OrderGeneralInformation
  deliveryDetails?: OrderDeliveryDetails
  promotionDetails?: OrderPromotionDetails
  paymentDetails?: OrderPaymentDetails
  created?: string
  lastUpdated?: string
  cartCreated?: string
}

export interface OrderItem {
  id?: string
  itemId?: string
  itemName?: string
  itemCode?: string
  itemDescription?: string
  itemPrice?: number
  itemQuantity?: number
  itemSubTotal?: number
}

export interface OrderDeliveryDetails {
  deliveryAddress?: string
  deliverySurcharge?: number
  postalCode?: number
  recipient?: string
  deliveryInstruction?: string
}

export interface OrderGeneralInformation {
  pageId?: string
  pageName?: string
  customerName?: string
  customerPhoneNumber?: string
}

export interface OrderPaymentDetails {
  partiallyPaidAmount?: number
  discount?: number
  deliveryFee?: number
  subTotal?: number
  total?: number
  paymentStatus?: PaymentStatus
  paymentChannel?: PaymentChannel
  paymentResponse?: string
}

export interface OrderPromotionDetails {
  promotionCode?: string
}

export enum OrderStatus {
  Unpaid = 0,
  Paid = 1
}

export interface OrderTag {
  tag?: OrderTagValue
  id?: string
}

export enum OrderTagValue {
  Archived = 'Archived',
  Shipped = 'Shipped'
}

export enum PaymentChannel {
  Online,
  Offline,
  CashOnDelivery,
  PickUp,
  PayMe
}

export enum PaymentStatus {
  PaymentPending,
  PaymentRejected,
  PaymentCancelled,
  PaymentFailed,
  PaymentPaid
}
