import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import {
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Tag
} from '@chakra-ui/react'
import { loadStripe } from '@stripe/stripe-js'

import { formatMoney } from 'utils/currency'
import { Cart, CartStatus } from 'models/cart'
import { PaymentChannel } from 'models/order'
import CheckoutContext from 'contexts/CheckoutContext'
import { useCart } from 'fetchers/cart'
import {
  usePayment,
  fetchStripeCheckout,
  fetchCancelPayment
} from 'fetchers/payment'
import { useAuth0 } from '@auth0/auth0-react'
import useToast from 'utils/useToast'
import { Button } from 'components/Button'
import Box from 'components/Box'
import Card from 'components/Card'
import GuestNewAddressModal from 'scenes/ShippingAddress/guest/GuestNewAddressModal'
import BuyerNewAddressModal from 'scenes/ShippingAddress/buyer/BuyerNewAddressModal'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string)

export interface SummaryProps {
  cart: Cart
}

function Summary(props: SummaryProps) {
  const { cart } = props
  const { deliveryInfoMutation } = useCart(cart.id)
  const { formData, paymentMode } = useContext(CheckoutContext)
  const { payMutation } = usePayment(cart.id)
  const history = useHistory()
  const { isAuthenticated } = useAuth0()
  const {
    isOpen: isOpenGuest,
    onClose: onCloseGuest,
    onOpen: onOpenGuest
  } = useDisclosure()
  const {
    isOpen: isOpenBuyer,
    onClose: onCloseBuyer,
    onOpen: onOpenBuyer
  } = useDisclosure()
  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: onOpenAlert
  } = useDisclosure()
  const toast = useToast()

  const handlePayment = async () => {
    if (paymentMode === PaymentChannel.CashOnDelivery) {
      if (cart.cartStatus === CartStatus.WaitingForPayment) {
        await fetchCancelPayment(cart.id)
      }
      const paymentMode: number = PaymentChannel.CashOnDelivery
      payMutation.mutate({
        paymentMode,
        cartId: cart.id,
        paymentProof: null,
        promotionId: cart.promotionId
      })

      return
    }

    if (paymentMode === PaymentChannel.Online) {
      const stripe = await stripePromise

      const sessionId: string = await fetchStripeCheckout({
        cartId: cart.id,
        promotionId: cart.promotionId
      })

      await stripe?.redirectToCheckout({
        sessionId
      })

      return
    }

    history.push('/payment')
  }

  const handleClick = () => {
    if (!cart.recipient) {
      if (formData) {
        deliveryInfoMutation.mutate(
          {
            cartId: cart.id,
            customerEmail: formData.email,
            customerPhoneNumber: formData.phoneNumber,
            deliveryAddress: formData.deliveryAddress,
            recipient: formData.recipient,
            deliveryInstruction: formData.deliveryInstruction,
            postalCode: formData.postalCode,
            provinceId: formData.provinceId,
            wardCode: formData.wardCode,
            districtId: formData.districtId
          },
          {
            onSuccess: () => {
              handlePayment()
            }
          }
        )
      } else {
        onOpenAlert()
      }

      return
    }

    if (cart.isGiaoHangNhanhEnabled && !cart.giaoHangNhanhServiceId) {
      toast({
        description: <Trans>Please select shipping option to continue</Trans>
      })
      return
    }

    handlePayment()
  }

  const handleOpenModal = () => {
    onCloseAlert()
    if (isAuthenticated) {
      onOpenBuyer()
    } else {
      onOpenGuest()
    }
  }
  return (
    <Card>
      <Box
        as='h2'
        textStyle='sectionTitle'
        display='flex'
        bg='white'
        p={3}
        borderRadius='md'
        flexDirection='column'
      >
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Text color='GrayText' fontSize='sm'>
            <Trans>Sub total</Trans>
          </Text>
          <Text color='GrayText' fontSize='sm'>
            {formatMoney(cart.subTotal)}
          </Text>
        </Box>
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Text color='GrayText' fontSize='sm'>
            <Trans>Delivery Fee</Trans>
          </Text>
          <Tag fontSize='sm'>{formatMoney(cart.deliveryFee || 0)}</Tag>
        </Box>
        {!!cart.discount && (
          <Box display='flex' justifyContent='space-between' mb={2}>
            <Text color='GrayText' fontSize='sm'>
              <Trans>Discount</Trans>
            </Text>
            <Tag fontSize='sm' colorScheme='orange'>
              -{formatMoney(Math.min(cart.discount, cart.subTotal))}
            </Tag>
          </Box>
        )}{' '}
        <Box display='flex' justifyContent='space-between'>
          <Text color='GrayText' fontSize='sm'>
            <Trans>Total</Trans>
          </Text>
          <Text>{formatMoney(cart.total)}</Text>
        </Box>
        <Box mt={3}>
          <Button
            width='100%'
            variant='primary'
            onClick={handleClick}
            isLoading={deliveryInfoMutation.isLoading || payMutation.isLoading}
          >
            <Trans>Checkout</Trans>
          </Button>
        </Box>
      </Box>

      <Modal isOpen={isOpenAlert} onClose={onCloseAlert} size='sm'>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Text fontSize='md' py={3}>
              <Trans>
                We do not have your shipping address. Please add your shipping
                address.
              </Trans>
            </Text>
            <Box display='flex' justifyContent='flex-end'>
              <Button variant='primary' onClick={handleOpenModal}>
                <Trans>Add</Trans>
              </Button>
              <Button onClick={onCloseAlert} ml={3}>
                <Trans>Cancel</Trans>
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {isAuthenticated ? (
        <BuyerNewAddressModal isOpen={isOpenBuyer} onClose={onCloseBuyer} />
      ) : (
        <GuestNewAddressModal isOpen={isOpenGuest} onClose={onCloseGuest} />
      )}
    </Card>
  )
}

export default Summary
