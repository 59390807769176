import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Heading, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Card from 'components/Card'
import CheckoutContext from 'contexts/CheckoutContext'
import { useCart } from 'fetchers/cart'
import SuccessLayout from 'layouts/Success'
import { BuyerData } from 'models/cart'
import { PaymentChannel } from 'models/order'
import React, { useState } from 'react'
import CartInfo from 'scenes/Checkout/components/CartInfo'
import ShippingAddress from 'scenes/ShippingAddress'

export interface PendingForVerificationProps {
  id: string
  showEditButton: boolean
}

function PendingForVerification(props: PendingForVerificationProps) {
  const { isAuthenticated } = useAuth0()
  const { id, showEditButton } = props
  const { query, cancelPaymentMutation } = useCart(id)
  const [formData, setFormData] = useState<BuyerData | null>(null)
  const [paymentMode, setPaymentMode] = useState<PaymentChannel>(
    PaymentChannel.CashOnDelivery
  )

  return (
    <SuccessLayout>
      <CheckoutContext.Provider
        value={{ formData, setFormData, paymentMode, setPaymentMode }}
      >
        <Heading size='lg' color='white' textAlign='center'>
          <Trans>Thanks!</Trans>
        </Heading>
        <Text color='white' textAlign='center' mt={2}>
          <Trans>Your order is being processed.</Trans>
        </Text>

        <Card p={5} mt={5}>
          <Text fontWeight='500' color='success'>
            <Trans>Order No. {id}</Trans>
          </Text>
          {query.data && query.data.permalinkUrl && (
            <Box>
              {isAuthenticated && <ShippingAddress />}
              <CartInfo data={query.data} />

              {showEditButton && (
                <Button
                  onClick={() => cancelPaymentMutation.mutate(id)}
                  mt={3}
                  w='full'
                >
                  <Trans>Edit Cart</Trans>
                </Button>
              )}
              <Button
                variant='primary'
                as='a'
                href={query.data.permalinkUrl}
                mt={3}
                w='full'
              >
                <Trans>Continue shopping</Trans>
              </Button>
            </Box>
          )}
        </Card>
      </CheckoutContext.Provider>
    </SuccessLayout>
  )
}

export default PendingForVerification
