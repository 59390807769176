import { ProductImage } from 'models/productImage'
import { CartProduct } from 'models/cartproduct'
import maxBy from 'lodash/maxBy'

export const getLatestProductImage = (
  product: CartProduct
): ProductImage | undefined => {
  const imageLength = product.productImages?.length

  if (imageLength) {
    return maxBy(product.productImages, 'ordinal')
  }
}
