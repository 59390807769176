import { useState, useMemo, useCallback } from 'react'

export default function usePagination(values: Array<any>, perPage = 10) {
  const [currentPage, setPage] = useState(1)

  const slice = useMemo(() => {
    return values.slice((currentPage - 1) * perPage, currentPage * perPage)
  }, [currentPage, values, perPage])

  const total = useMemo(() => {
    return Math.ceil(values.length / perPage)
  }, [values, perPage])

  const shouldShowPagination = useMemo(() => {
    return values.length > perPage
  }, [values, perPage])

  const handlePageChange = useCallback(
    (num: number) => {
      return () => {
        setPage(num)
      }
    },
    [setPage]
  )

  return {
    currentPage,
    slice,
    total,
    shouldShowPagination,
    handlePageChange
  }
}
