import React from 'react'
import Box, { BoxProps } from '../components/Box'
import AppContainer from '../components/AppContainer'
import { useHistory } from 'react-router-dom'
import LanguageDropDown from './LanguageDropDown'
import MobileMenu from './MobileMenu'
import useBreakpoint from 'utils/useBreakpoint'
import Logo from 'components/Logo'
import useOrganizationId from 'utils/useOrganizationId'
import { useOrganization } from 'fetchers/organization'
import { PhoneIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/button'
import { brandColor } from 'utils/colors'
import { Link } from '@chakra-ui/react'
import { FaFacebookMessenger } from 'react-icons/fa'

export interface MainProps extends Omit<Omit<BoxProps, 'children'>, 'title'> {
  title?: React.ReactElement
  children?: React.ReactNode
}

function Main(props: MainProps) {
  const { children, title, ...boxProps } = props
  const host = useOrganizationId()
  const { query: organization } = useOrganization(host)
  const { isMd, isSm } = useBreakpoint()

  const history = useHistory()

  const toHome = React.useCallback(() => {
    history.push('/')
  }, [history])

  return (
    <Box as='main' {...boxProps}>
      <Box
        as='header'
        height='60px'
        bg='#1d4ed8'
        display='flex'
        justifyContent='end'
        alignItems='center'
      >
        <AppContainer
          d='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          {title ? (
            title
          ) : (
            <Box cursor='pointer' onClick={toHome}>
              <Logo size={100} />
            </Box>
          )}
          {isMd || isSm ? (
            <Box display='flex' justifyContent='end' alignItems='center'>
              <Box>
                {organization.data?.pageId && (
                  <Link
                    href={`https://m.me/${organization.data?.pageId}`}
                    isExternal
                  >
                    <Button type='button' variant='solid' w='auto'>
                      <FaFacebookMessenger color={brandColor} />
                    </Button>
                  </Link>
                )}
                {organization.data?.mobileNumber && (
                  <Link href={`tel:${organization.data?.mobileNumber}`} ml={2}>
                    <Button type='button' variant='solid' w='auto'>
                      <PhoneIcon color={brandColor} />
                    </Button>
                  </Link>
                )}
              </Box>

              <Box ml={2}>
                <LanguageDropDown />
              </Box>
            </Box>
          ) : (
            <MobileMenu
              mobileNumber={organization.data?.mobileNumber}
              pageId={organization.data?.pageId}
            />
          )}
        </AppContainer>
      </Box>
      {children}
      {/* TODO: make this sticky footer */}
      {/* <Box
        as="footer"
        height="60px"
        bg="#1d4ed8"
        display="flex"
        alignItems="center"
      >
        <AppContainer>
          <Center>
            <Link href="https://kaio.ai" isExternal textColor="#ffffff">
              Powered by KAIO <ExternalLinkIcon mx="2px" />
            </Link>
          </Center>
        </AppContainer>
      </Box> */}
    </Box>
  )
}

export default Main
