import localStorageManager from 'utils/localStorageManager'

const CURRENCY_MAP = new Map([
  ['0', 'VND'],
  ['1', 'AUD'],
  ['2', 'USD'],
  ['3', 'JPY']
])

export const formatMoney = (num: number): string => {
  const locale = localStorageManager.getLang()
  const currencyKey = localStorageManager.getCurrency()
  if (locale === null) {
    return num.toString()
  }

  if (currencyKey === null) {
    return num.toString()
  }

  const currency = CURRENCY_MAP.get(currencyKey)

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(num)
}
