import Box from 'components/Box'
import { Center, Heading, Spinner } from '@chakra-ui/react'
import { useProducts } from 'fetchers/products'
import ProductList from './components/ProductList'
import useOrganizationId from 'utils/useOrganizationId'
import { Trans } from '@lingui/macro'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useHistory } from 'react-router-dom'
import AboutFooter from 'components/AboutFooter'
import { Pagination } from 'components/Pagination'
import { useCallback, useEffect, useState } from 'react'
import localStorageManager from 'utils/localStorageManager'

export interface ProductsProps {
  tracker: string
}

function Shop() {
  const history = useHistory()
  const organizationId = useOrganizationId()
  const pageSize = 20

  if (!organizationId) {
    history.push('/not-found')
  }

  const [currentPage, setCurrentPage] = useState(1)

  const { query } = useProducts(organizationId, currentPage, pageSize)

  const handlePageChange = useCallback(
    (num: number) => {
      return () => {
        setCurrentPage(num)
      }
    },
    [setCurrentPage]
  )

  // @TODO: currency
  useEffect(() => {
    // VND, AUD, USD, JPY
    localStorageManager.setLang('vi')
    localStorageManager.setCurrency(0)
  }, [])

  useEffect(() => {
    query.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const products = query.data?.data
  const total = query.data?.total

  return (
    <Box pb={6}>
      {/* <Box my='40px'>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDir='column'
          mb={10}
        >
          <Heading as='h3' mb={2} fontSize={24} size='lg' textAlign='center'>
            <Trans>Flash sale</Trans>
          </Heading>
          <Countdown date={new Date('2023-05-09T12:00:00')} />
        </Box>
        <Slider {...settings}>
          {products?.map(i => (
            <ProductItem product={i} key={i.id} type='sale' />
          ))}
        </Slider>
      </Box>

      <Box my='40px'>
        <Heading mb={10} as='h3' fontSize={24} size='lg' textAlign='center'>
          <Trans>Highlighted products</Trans>
        </Heading>
        <Slider {...settings}>
          {products?.map(i => (
            <ProductItem product={i} key={i.id} type='hot' />
          ))}
        </Slider>
      </Box> */}

      {/* <Box my={2}>
        <Center w='full'>
          <Heading mb={4} as='h3' size='lg'>
            <Trans>All Categories</Trans>
          </Heading>
        </Center>
        <Category tracker={organizationId} />
      </Box> */}
      {query.isFetching ? (
        <Spinner />
      ) : (
        <Box mt={4}>
          <Center w='full'>
            <Heading mt={2} mb={2} as='h3' size='lg'>
              <Trans>All Products</Trans>
            </Heading>
          </Center>
          <Center w='full'>
            <Heading mt={2} mb={2} as='h3' size='lg'>
              <Pagination
                total={Math.ceil((total ?? 0) / pageSize)}
                current={currentPage}
                generateLink={handlePageChange}
              />
            </Heading>
          </Center>
          {products && <ProductList products={products} />}
        </Box>
      )}
      <Box>
        <Center w='full'>
          <Heading mt={2} mb={2} as='h3' size='lg'>
            <Pagination
              total={Math.ceil((total ?? 0) / pageSize)}
              current={currentPage}
              generateLink={handlePageChange}
            />
          </Heading>
        </Center>
      </Box>
      <AboutFooter />
    </Box>
  )
}

export default Shop
