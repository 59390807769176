import React, { useEffect } from 'react'
import { Trans } from '@lingui/macro'
import {
  ModalProps,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  RadioGroup,
  Radio,
  Box,
  Text,
  Button,
  useDisclosure,
  Modal,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Progress
} from '@chakra-ui/react'
import { BsThreeDots } from 'react-icons/bs'

import { BuyerData } from 'models/cart'
import useModalSize from 'utils/useModalSize'
import useBreakpoint from 'utils/useBreakpoint'
import { useBuyer } from 'fetchers/buyer'
import useToast from 'utils/useToast'
import useCartId from 'utils/useCartId'
import { useCart } from 'fetchers/cart'
import useConfirm from 'utils/useConfirm'

import BuyerNewAddressModal from './BuyerNewAddressModal'
import BuyerEditAddressModal from './BuyerEditAddressModal'

interface SelectAddressModalProps extends Omit<ModalProps, 'children'> {
  addresses: BuyerData[]
}
export default function SelectAddressModal(props: SelectAddressModalProps) {
  const { deleteBuyerDataMutation } = useBuyer()
  const toast = useToast()
  const id = useCartId()
  const { deliveryInfoMutation, query } = useCart(id)
  const { addresses, ...modalProps } = props
  const defaultAddress = addresses.find(address => address.isDefault)
  const [value, setValue] = React.useState(
    defaultAddress ? defaultAddress.id : addresses[0].id
  )
  const [selectedAddress, setSelectedAddress] = React.useState<BuyerData>()
  const modalSize = useModalSize()
  const { isMd } = useBreakpoint()
  const [ConfirmModal, handleConfirm] = useConfirm({
    onConfirm: (id: string) => {
      deleteBuyerDataMutation.mutate(id)
    },
    confirmText: <Trans>Delete</Trans>,
    title: <Trans>Remove address</Trans>,
    message: <Trans>Are you sure you want to remove this address?</Trans>
  })

  useEffect(() => {
    if (!selectedAddress && !query.data?.districtId && defaultAddress) {
      setSelectedAddress(defaultAddress)
      deliveryInfoMutation.mutate({
        cartId: id,
        customerEmail: defaultAddress.email,
        customerPhoneNumber: defaultAddress.phoneNumber,
        deliveryAddress: defaultAddress.deliveryAddress,
        recipient: defaultAddress.recipient,
        deliveryInstruction: defaultAddress.deliveryInstruction,
        districtId: defaultAddress.districtId,
        provinceId: defaultAddress.provinceId,
        wardCode: defaultAddress.wardCode
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    isOpen: isOpenNew,
    onClose: onCloseNew,
    onOpen: onOpenNew
  } = useDisclosure()
  const {
    isOpen: isOpenEdit,
    onClose: onCloseEdit,
    onOpen: onOpenEdit
  } = useDisclosure()

  const handleOnChange = (value: string) => {
    setValue(value)
    const selectedAddress = addresses.find(address => address.id === value)
    if (selectedAddress) {
      setSelectedAddress(selectedAddress)
    }
  }

  const handleEditAddress = (address: BuyerData) => {
    setSelectedAddress(address)
    onOpenEdit()
  }

  const handleDeleteAddress = (address: BuyerData) => {
    if (address.isDefault) {
      toast({
        description: (
          <Trans>
            You cannot remove the default address. Please set another address as
            your default address first.
          </Trans>
        )
      })
      return
    }
    if (address.id) {
      handleConfirm(address.id)
    }
  }

  const handleSelectAddress = () => {
    if (!selectedAddress) {
      return
    }

    deliveryInfoMutation.mutate(
      {
        cartId: id,
        customerEmail: selectedAddress.email,
        customerPhoneNumber: selectedAddress.phoneNumber,
        deliveryAddress: selectedAddress.deliveryAddress,
        recipient: selectedAddress.recipient,
        deliveryInstruction: selectedAddress.deliveryInstruction,
        districtId: selectedAddress.districtId,
        provinceId: selectedAddress.provinceId,
        wardCode: selectedAddress.wardCode,
        giaoHangNhanhServiceId: null
      },
      {
        onSuccess: () => {
          props.onClose()
        }
      }
    )
  }

  return (
    <Modal {...modalProps} isCentered size={modalSize}>
      <ModalOverlay />
      <ModalContent marginY={0}>
        <ModalHeader>
          <Trans>Select your shipping address</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup
            onChange={handleOnChange}
            value={value}
            position='relative'
          >
            {deleteBuyerDataMutation.isLoading && (
              <Progress
                size='xs'
                isIndeterminate
                position='absolute'
                width='100%'
              />
            )}
            {addresses.map(address => (
              <Box
                key={address.id}
                display='flex'
                width='full'
                paddingY={3}
                borderTopWidth='1px'
                borderBottomWidth='1px'
                mb='-1px'
                borderColor='gray'
              >
                <Radio value={address.id} size='md' flex='1'>
                  <Text fontWeight='600' fontSize='sm'>
                    {address.recipient}
                  </Text>
                  <Text fontSize='sm' color='GrayText'>
                    {address.phoneNumber}
                  </Text>
                  <Text fontSize='sm' color='GrayText'>
                    {address.deliveryAddress}
                  </Text>
                  {address.isDefault && (
                    <Text color='success' fontSize='sm'>
                      <Trans>Default address</Trans>
                    </Text>
                  )}
                </Radio>
                {isMd ? (
                  <Box>
                    {addresses.length > 1 && (
                      <Button
                        variant='link'
                        mr={2}
                        size='sm'
                        onClick={() => handleDeleteAddress(address)}
                      >
                        <Trans>Delete</Trans>
                      </Button>
                    )}
                    <Button
                      variant='link'
                      size='sm'
                      color='primary'
                      onClick={() => handleEditAddress(address)}
                    >
                      <Trans>Update</Trans>
                    </Button>
                  </Box>
                ) : (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<BsThreeDots size='24px' />}
                      size='md'
                      variant='ghost'
                    />
                    <MenuList>
                      {addresses.length > 1 && (
                        <MenuItem onClick={() => handleDeleteAddress(address)}>
                          <Trans>Delete</Trans>
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => handleEditAddress(address)}>
                        <Trans>Update</Trans>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Box>
            ))}
          </RadioGroup>
          <Button
            color='primary'
            variant='link'
            mt={3}
            onClick={onOpenNew}
            size='sm'
          >
            <Trans>Add new address</Trans>
          </Button>
          <Button
            width='100%'
            variant='primary'
            mt={10}
            onClick={handleSelectAddress}
            isLoading={deliveryInfoMutation.isLoading}
          >
            <Trans>Ship to this address</Trans>
          </Button>
        </ModalBody>
      </ModalContent>

      {selectedAddress && (
        <BuyerEditAddressModal
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          formData={selectedAddress}
        />
      )}
      <BuyerNewAddressModal isOpen={isOpenNew} onClose={onCloseNew} />

      <ConfirmModal />
    </Modal>
  )
}
