import React from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { Trans } from '@lingui/macro'

import { Text } from '@chakra-ui/react'
import { BiImageAdd } from 'react-icons/bi'
import Box from '../Box'
import useToast from 'utils/useToast'

export interface DropZoneProps {
  inputProps?: React.HTMLAttributes<HTMLInputElement>
  options?: DropzoneOptions
  onChange?: (files: File[]) => void
}

export default function Dropzone(props: DropZoneProps) {
  const toast = useToast()
  const { options, onChange, inputProps } = props

  const onDrop = React.useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length) {
        fileRejections.forEach(
          ({ errors }: { errors: Array<{ message: string }> }) => {
            errors.forEach(error => {
              toast({
                description: <Trans id={error.message} />
              })
            })
          }
        )
        return
      }
      if (onChange) {
        onChange(acceptedFiles)
      }
    },
    [onChange, toast]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...options,
    onDrop
  })

  return (
    <Box
      display='flex'
      alignItems='center'
      {...getRootProps()}
      bg='#eee'
      w='200px'
      height='200px'
      border='1px dashed #ccc'
      color='#aeaeae'
      cursor='pointer'
      rounded='md'
    >
      <input {...inputProps} {...getInputProps()} />
      <Box textAlign='center' flex={1}>
        {isDragActive ? (
          <Box textAlign='center' flex={1}>
            <Trans>Drop the files here ...</Trans>
          </Box>
        ) : (
          <Box
            d='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <BiImageAdd size='50px' />
            <Text>
              <Trans>Select an image or drop it here</Trans>
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
