import { Text, Box } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { BuyerData } from 'models/cart'

interface ShippingOverviewProps {
  formData: BuyerData | null
}

export default function ShippingOverview(props: ShippingOverviewProps) {
  return (
    <Box>
      {props.formData ? (
        <>
          <Text>
            {props.formData.recipient}
            <br />
            {props.formData.phoneNumber}
          </Text>
          <Text>{props.formData.deliveryAddress}</Text>
        </>
      ) : (
        <Text opacity='0.6'>
          <Trans>Please add your shipping address</Trans>
        </Text>
      )}
    </Box>
  )
}
