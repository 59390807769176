import React, { ReactNode, useEffect } from 'react'
import {
  Box,
  Portal,
  chakra,
  useColorModeValue,
  VisuallyHidden,
  Container,
  Stack,
  Text
} from '@chakra-ui/react'
import { FaFacebook } from 'react-icons/fa'
import useDimensions from 'react-cool-dimensions'
import useBreakpoint from 'utils/useBreakpoint'

export interface AboutFooterProps {
  children?: React.ReactNode
}

const SocialButton = ({
  children,
  label,
  href
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200')
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

export default function AboutFooter() {
  const { isXl } = useBreakpoint()
  const { observe, height } = useDimensions()

  useEffect(() => {
    const rootElement = window.document.getElementById('root')
    if (rootElement) {
      rootElement.style.paddingBottom = `${height}px`
    }

    return () => {
      const rootElement = window.document.getElementById('root')
      if (rootElement) {
        rootElement.style.paddingBottom = ''
      }
    }
  }, [height])

  return (
    <Portal>
      <Box
        position='fixed'
        left={0}
        bottom={0}
        w='full'
        borderTopWidth={1}
        borderColor='gray'
        ref={observe}
      >
        <Box
          bg={useColorModeValue('gray.50', 'gray.900')}
          color={useColorModeValue('gray.700', 'gray.200')}
        >
          {isXl ? (
            <Container
              as={Stack}
              maxW={'6xl'}
              py={2}
              direction={{ base: 'column', md: 'row' }}
              justify={{ base: 'center', md: 'space-between' }}
              align={{ base: 'center', md: 'center' }}
            >
              <Text>© 2023 KAIO. All rights reserved</Text>
              <Stack direction={'row'} spacing={6}>
                <SocialButton
                  label={'Twitter'}
                  href={'https://www.facebook.com/KAIO.AIVIETNAM'}
                >
                  <FaFacebook />
                </SocialButton>
              </Stack>
            </Container>
          ) : (
            <Container
              as={Stack}
              maxW={'6xl'}
              py={2}
              direction={{ base: 'column', md: 'row' }}
              justify={{ base: 'center', md: 'space-between' }}
              align={{ base: 'center', md: 'center' }}
            >
              <Text>
                © 2023 KAIO. All rights reserved.{' '}
                <SocialButton
                  label={'Facebook'}
                  href={'https://www.facebook.com/KAIO.AIVIETNAM'}
                >
                  <FaFacebook />
                </SocialButton>
              </Text>
            </Container>
          )}
        </Box>
      </Box>
    </Portal>
  )
}
