import React from 'react'
import { Trans } from '@lingui/macro'
import {
  Modal,
  ModalProps,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton
} from '@chakra-ui/react'

import useModalSize from 'utils/useModalSize'
import { BuyerData } from 'models/cart'

import GuestAddressForm from './GuestAddressForm'

interface GuestEditAddressModalProps extends Omit<ModalProps, 'children'> {
  formData?: BuyerData
}
export default function GuestEditAddressModal(
  props: GuestEditAddressModalProps
) {
  const modalSize = useModalSize()

  const { formData, ...modalProps } = props
  return (
    <Modal {...modalProps} isCentered size={modalSize}>
      <ModalOverlay />
      <ModalContent marginY={0}>
        <ModalHeader>
          <Trans>Edit your shipping address</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <GuestAddressForm onSubmit={props.onClose} formData={formData} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
