import { useRef, useState } from 'react'
import Box from 'components/Box'
import {
  Button,
  Spinner,
  useDisclosure,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Divider,
  Text,
  Stack,
  Alert,
  AlertTitle
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useProducts } from 'fetchers/products'
import { Input } from 'components/Input'
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import useOrganizationId from 'utils/useOrganizationId'
import ProductThumbnail from './ProductThumbnail'
import { formatMoney } from 'utils/currency'
import useAddToCart from './useAddToCart'
export interface ProductsProps {
  tracker: string
}

export default function SearchBox() {
  const addToCart = useAddToCart()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const tracker = useOrganizationId()
  const currentPage = 1
  const pageSize = 20
  const { query } = useProducts(tracker, currentPage, pageSize, searchQuery)
  const products = query.data?.data
  const timeoutRef = useRef<any>(null)
  const maxLength = 2

  const onSearch = (value: string) => {
    setSearchQuery(value)
    setShowAlert(false)
    if (value && value.length < maxLength) {
      setShowAlert(true)
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      if (value.length >= maxLength || value === '') {
        query.refetch()
      }
    }, 300)
  }

  return (
    <>
      <Button
        leftIcon={<SearchIcon color='primary' />}
        rightIcon={
          <CloseIcon
            color='gray.500'
            onClick={() => {
              setSearchQuery('')
              setTimeout(() => query.refetch(), 500)
            }}
          />
        }
        isFullWidth
        variant='outline'
        onClick={onOpen}
        backgroundColor='#fff'
        borderRadius='25px'
        border='2px solid #E2E8F0'
      >
        <Stack w='100%' flexDirection='row'>
          <Text color='gray.400' fontWeight='400'>
            {searchQuery ? searchQuery : <Trans>Search for products</Trans>}
          </Text>
        </Stack>
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          query.refetch()
          onClose()
        }}
        size='lg'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={6}>
            <InputGroup size='lg' mr={3}>
              <Input
                value={searchQuery}
                placeholder='A1, A2, A3...'
                onChange={e => {
                  onSearch(e.target.value)
                }}
              />
              <InputRightElement>
                {searchQuery ? (
                  <CloseIcon
                    onClick={() => {
                      setSearchQuery('')
                      setTimeout(() => query.refetch(), 500)
                    }}
                    color='gray.500'
                  />
                ) : (
                  <SearchIcon color='primary' />
                )}
              </InputRightElement>
            </InputGroup>
            {showAlert && (
              <Alert status='warning' mt='2'>
                <AlertTitle>
                  <Trans>Please enter 2 or more characters</Trans>
                </AlertTitle>
              </Alert>
            )}

            <Box mt={4} maxHeight='70vh' overflow='auto'>
              {query.isFetching ? (
                <Spinner />
              ) : (
                <>
                  {
                    // TODO: use size from API
                    products &&
                      products.slice(0, 5).map(product => (
                        <Box key={product.id}>
                          <Box
                            d='flex'
                            p={2}
                            py={3}
                            _hover={{ bg: 'gray.200' }}
                          >
                            <ProductThumbnail
                              productImages={product.productImages}
                              width='100px'
                              height='100px'
                            />
                            <Box ml={4}>
                              <Text mb={2}>{product.productName}</Text>
                              <Text fontWeight='bold' mb={4}>
                                {formatMoney(product.price)}
                              </Text>
                              <Button
                                onClick={() => addToCart(product)}
                                size='sm'
                                variant='primary'
                              >
                                <Trans>Add to cart</Trans>
                              </Button>
                            </Box>
                          </Box>
                          <Divider />
                        </Box>
                      ))
                  }
                </>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
