const apis = {
  login: () => {
    return `buyers/login`
  },
  save: () => {
    return `buyers/save`
  },
  updateBuyerData: () => {
    return `buyers/details`
  },
  deleteBuyerData: (id: string) => {
    return `buyers/details/${id}`
  },
  getBuyerData: () => {
    return `buyers`
  },
  getPhoneNumberStatus: (id: string, phoneNumber: string) => {
    return `v2/payments/phone-number/${id}?number=${phoneNumber}`
  },
  verifyPhoneNumber: () => {
    return `v2/payments/verify`
  },
  refreshStock: () => {
    return `v2/payments/sync-stock`
  },
  verifyOtp: () => {
    return `v2/payments/verify/otp`
  },
  getPaymentInstruction: (id: string) => {
    return `v2/payments/instruction/${id}`
  },
  getCart: (id: string) => {
    return `v2/payments/${id}`
  },
  getOrder: (id: string) => {
    return `v2/payments/order/${id}`
  },
  getShippingOptions: (id: string) => {
    return `v2/payments/delivery-options/${id}`
  },
  cartIncrease: () => {
    return `v2/payments/increase-quantity`
  },
  cartDecrease: () => {
    return `v2/payments/decrease-quantity`
  },
  cartDeleteProduct: () => {
    return `v2/payments/delete-product`
  },
  payOffline: () => {
    return `v2/payments/pay-offline`
  },
  applyPromotion: () => {
    return `v2/payments/apply-promotion`
  },
  pickShippingOption: () => {
    return `v2/payments/pick-delivery-option`
  },
  cashOnDelivery: () => {
    return `v2/payments/cash-on-delivery`
  },
  pickUp: () => {
    return `v2/payments/pick-up`
  },
  pay: () => {
    return `v2/payments/pay`
  },
  saveGuestDeliveryInfo: () => {
    return `v2/payments/delivery-info`
  },
  stripCheckout: () => {
    return `v2/payments/stripe/checkout`
  },
  cancelPayment: () => {
    return `v2/payments/cancel`
  },
  getProducts: (
    organizationId: string,
    pageNumber: number,
    pageSize: number,
    search?: string
  ) => {
    return search
      ? `v2/products/${organizationId}?searchTerm=${search}&webActive=true&pageSize=${pageSize}&pageNumber=${pageNumber}`
      : `v2/products/${organizationId}?webActive=true&pageSize=${pageSize}&pageNumber=${pageNumber}`
  },
  getRecommendations: (id: string) => {
    return `v2/products/recommendations/${id}`
  },
  addProduct: () => {
    return `v2/products/add`
  },
  getProvinces: () => {
    return `delivery/ghn/provinces`
  },
  getDistricts: (provinceId: string) => {
    return `delivery/ghn/districts/${provinceId}`
  },
  getWards: (districtId: string) => {
    return `delivery/ghn/wards/${districtId}`
  },
  getJtCities: () => {
    return `delivery/jt-express/cities`
  },
  getJtDistricts: (cityId: string) => {
    return `delivery/jt-express/districts/${cityId}`
  },
  getJtAreas: (districtId: string) => {
    return `delivery/jt-express/areas/${districtId}`
  },
  ghnCalculateFee: (
    cartId: string,
    query: {
      toDistrict: number
      wardCode: string
      serviceId: string
    }
  ) => {
    const { toDistrict, wardCode, serviceId } = query
    return `delivery/ghn/calculate-fee/${cartId}?toDistrict=${toDistrict}&wardCode=${wardCode}&serviceId=${serviceId}`
  },
  createCart: () => 'carts/create',
  getCategories: (organizationId: string) => `category/${organizationId}`,
  getCategoryProducts: (organizationId: string, categoryId: string) =>
    `category/${organizationId}/${categoryId}`,
  getCatalogs: (organizationId: string) => `product-catalog/${organizationId}`,
  getCatalogProducts: (organizationId: string, catalogId: string) =>
    `product-catalog/${organizationId}/${catalogId}`,
  getOrganization: (organizationId: string) => `organization/${organizationId}`,
  getOrganizationByHost: (host: string) => `organization/domain?domain=${host}`,
  getKaioProvinces: () => {
    return `delivery/kaio-delivery/provinces`
  },
  getKaioDistricts: (kaioDistrictId: number) => {
    return `delivery/kaio-delivery/districts/${kaioDistrictId}`
  },
  getKaioWards: (kaioWardId: number) => {
    return `delivery/kaio-delivery/wards/${kaioWardId}`
  },
  getUrlPayme: () => {
    return `/payment/customer/checkout`
  }
}

const config = {
  apis
}

export default config
