import { Trans } from '@lingui/macro'
import { Product } from 'models/product'

import useStore from 'store'
import useToast from 'utils/useToast'

export default function useAddToCart() {
  const { addProduct } = useStore()
  const toast = useToast()

  return function addToCart(product: Product) {
    addProduct(product)
    toast({
      status: 'success',
      description: (
        <Trans>
          {product.productName ?? product.productCode} was added to your cart
        </Trans>
      )
    })
  }
}
