import { Trans } from '@lingui/macro'
import { Image, Text, Center, Stack, Heading } from '@chakra-ui/react'

function NoProduct() {
  return (
    <Center p='50px' pt='100px'>
      <Stack>
        <Image height='100px' src='/images/shopping-cart.svg' />
        <Heading size='lg' textAlign='center'>
          <Trans>Your cart is currently empty</Trans>
        </Heading>
        <Text size='md' textAlign='center'>
          <Trans>
            Before proceeding to checkout you must add some products to your
            shopping cart.
          </Trans>
        </Text>
      </Stack>
    </Center>
  )
}

export default NoProduct
