import { Image, Button, Text, Box } from '@chakra-ui/react'
import { TextField } from 'components/TextField'
import { UseFormMethods } from 'react-hook-form'
import { Trans } from '@lingui/macro'

interface PromotionForm {
  promotionCode: string
}

interface PromotionFormProps {
  form: UseFormMethods<PromotionForm>
  handleApplyCode: (values: PromotionForm) => void
  handleRemoveCode: () => void
  promotionId: string | null
  isLoading: boolean
}

export default function PromotionForm(props: PromotionFormProps) {
  const {
    form,
    handleApplyCode,
    promotionId,
    handleRemoveCode,
    isLoading
  } = props
  const { handleSubmit, register, errors } = form

  return (
    <form onSubmit={handleSubmit(handleApplyCode)}>
      <Box display='flex' p={3} bg='white'>
        <Box display='flex' flex='1' height='32px' alignItems='center'>
          <Image width={6} src='/images/promotion.svg' mr={3} />
          <Text mr={3} fontWeight='600' whiteSpace='pre'>
            <Trans>Promotion code</Trans>
          </Text>
        </Box>
        <TextField
          mr={3}
          w='auto'
          flex='1'
          error={Boolean(errors.promotionCode?.message)}
          helperText={errors.promotionCode?.message}
          inputProps={{
            isDisabled: !!promotionId,
            size: 'sm',
            name: 'promotionCode',
            ref: register
          }}
        />
        {promotionId ? (
          <Button
            variant='outline'
            onClick={handleRemoveCode}
            size='sm'
            isLoading={isLoading}
          >
            <Trans>Remove</Trans>
          </Button>
        ) : (
          <Button
            variant='outline'
            type='submit'
            size='sm'
            isLoading={isLoading}
          >
            <Trans>Apply</Trans>
          </Button>
        )}
      </Box>
    </form>
  )
}
