import { ChatIcon, PhoneIcon } from '@chakra-ui/icons'
import { Trans } from '@lingui/macro'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  ModalFooter,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  HStack,
  PinInput,
  PinInputField,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Icon
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button } from 'components/Button'
import { TextField } from 'components/TextField'
import { verifyOtp, verifyPhoneNumber } from 'fetchers/cart'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DEFAULT_PHONE_NUMBER_REGEX, PHONE_NUMBER_REGEX } from 'utils/regex'

export interface PhoneNumberVerificationProps {
  id: string
  defaultPhoneNumber: string
  currency: number
  onVerified: () => void
}

function PhoneNumberVerification(props: PhoneNumberVerificationProps) {
  const { i18n } = useLingui()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id, defaultPhoneNumber, currency, onVerified } = props

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
  const [isVerified, setIsVerified] = useState<boolean | null>(null)
  const [isVerifying, setIsVerifying] = useState<boolean>(false)
  const [timeLeft, setTimeLeft] = useState<number | null>(null)

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null)
    }

    if (!timeLeft) return

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  useEffect(() => {
    onOpen()
    setPhoneNumber(defaultPhoneNumber)
  }, [defaultPhoneNumber, onOpen])

  const triggerOtp = async (isZalo: boolean) => {
    if (!phoneNumber) {
      return
    }

    verifyPhoneNumber({ cartId: id, phoneNumber: phoneNumber, isZalo: isZalo })
    setIsVerifying(true)
    setTimeLeft(60)
  }

  // const validatePhoneNumber = async (number: string | null) => {
  //   if (!number) {
  //     setIsVerified(false)
  //     return
  //   }

  //   var isVerified = await fetchPhoneNumberStatus(id, number)

  //   setIsVerified(isVerified)
  // }

  const validateOtp = async (otp: string) => {
    const status = await verifyOtp({ cartId: id, otp: otp })

    if (status) {
      onVerified()
      onClose()
      return
    }

    setIsVerified(status)
  }

  const { register, errors } = useForm<{ phoneNumber: string | null }>({
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: { phoneNumber: defaultPhoneNumber }
  })

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Please verify your phone number</Trans>
        </ModalHeader>
        <ModalBody pb={6}>
          {!isVerifying ? (
            <FormControl>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <PhoneIcon color='gray.300' />
                </InputLeftElement>
                <TextField
                  isRequired={true}
                  error={Boolean(errors.phoneNumber?.message)}
                  helperText={errors.phoneNumber?.message}
                  inputProps={{
                    name: 'phoneNumber',
                    ref: register({
                      pattern: {
                        value:
                          currency === 0
                            ? PHONE_NUMBER_REGEX
                            : DEFAULT_PHONE_NUMBER_REGEX,
                        message: i18n._(t`Please enter a valid phone number.`)
                      }
                    }),
                    errorBorderColor: 'crimson',
                    placeholder: i18n._(t`Phone number`),
                    onChange: (e: any) => {
                      setPhoneNumber(e.target.value)
                    },
                    pl: 10
                  }}
                />
              </InputGroup>
            </FormControl>
          ) : (
            <FormControl>
              <HStack>
                <PinInput
                  otp
                  onComplete={(otp: string) => validateOtp(otp)}
                  onChange={() => {
                    if (isVerified !== null) {
                      setIsVerified(null)
                    }
                  }}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </FormControl>
          )}
          {isVerified !== null && !isVerified && (
            <Alert status='error' mt={4}>
              <AlertIcon />
              <AlertTitle>
                <Trans>Verification failed!</Trans>
              </AlertTitle>
              <AlertDescription>
                <Trans>Please try again.</Trans>
              </AlertDescription>
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          {!isVerifying ? (
            <>
              <Button mr={2} onClick={() => triggerOtp(true)}>
                Zalo
              </Button>
              <Button
                variant='primary'
                leftIcon={<Icon as={ChatIcon} />}
                onClick={() => triggerOtp(false)}
              >
                SMS
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => setIsVerifying(false)}
                mr={2}
                disabled={timeLeft && timeLeft > 0 ? true : false}
              >
                <Trans>Edit</Trans>
              </Button>
              <Button
                onClick={() => triggerOtp(false)}
                variant='primary'
                disabled={timeLeft && timeLeft > 0 ? true : false}
              >
                <Trans>Resend</Trans>{' '}
                {timeLeft && timeLeft > 0 && <span>({timeLeft})</span>}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PhoneNumberVerification
