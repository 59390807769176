import { useMediaQuery } from '@chakra-ui/react'

export const breakpoints: any = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em'
}

export default function useBreakpoint() {
  const smQuery = `(min-width: ${breakpoints.sm})`
  const mdQuery = `(min-width: ${breakpoints.md})`
  const lgQuery = `(min-width: ${breakpoints.lg})`
  const xlQuery = `(min-width: ${breakpoints.xl})`

  const [isSm, isMd, isLg, isXl] = useMediaQuery([
    smQuery,
    mdQuery,
    lgQuery,
    xlQuery
  ])

  return {
    isSm,
    isMd,
    isLg,
    isXl
  }
}
