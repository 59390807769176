import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  MenuOptionGroup,
  MenuItemOption,
  Box,
  Image,
  Link
} from '@chakra-ui/react'
import { ChatIcon, HamburgerIcon, PhoneIcon } from '@chakra-ui/icons'
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { UserSettingsContext } from 'contexts/UserSettingsContext'
import { brandColor } from 'utils/colors'

interface MobileMenuProps {
  pageId?: string
  mobileNumber?: string
}

export default function MobileMenu({ pageId, mobileNumber }: MobileMenuProps) {
  const { locale, setLocale } = React.useContext(UserSettingsContext)
  const { i18n } = useLingui()

  const onChangeLocale = (value: string | string[]) => {
    setLocale(value as string)
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='Options'
        icon={<HamburgerIcon />}
      />
      <MenuList>
        <MenuOptionGroup
          defaultValue={locale}
          title={i18n._(t`Language`)}
          type='radio'
          onChange={onChangeLocale}
        >
          <MenuItemOption value='en'>
            <Box d='flex' alignItems='center' justifyContent='space-between'>
              English <Image src='/images/en.svg' w={5} />
            </Box>
          </MenuItemOption>
          <MenuItemOption value='vi'>
            <Box d='flex' alignItems='center' justifyContent='space-between'>
              Tiếng Việt <Image src='/images/vi.svg' w={5} />
            </Box>
          </MenuItemOption>
        </MenuOptionGroup>

        {(pageId || mobileNumber) && (
          <MenuOptionGroup title={i18n._(t`Contact`)}>
            {pageId && (
              <MenuItem>
                <Link href={`https://m.me/${pageId}`} isExternal>
                  <ChatIcon color={brandColor} /> <Trans>Facebook</Trans>
                </Link>
              </MenuItem>
            )}

            {mobileNumber && (
              <MenuItem>
                <Link href={`tel:${mobileNumber}`}>
                  <PhoneIcon color={brandColor} /> <Trans>Phone Number</Trans>
                </Link>
              </MenuItem>
            )}
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  )
}
