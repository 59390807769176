import React from 'react'
import { Trans } from '@lingui/macro'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Heading,
  Stack,
  StackDivider,
  Text,
  Link,
  Icon
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom'
import { FaFacebook } from 'react-icons/fa'

import { Button } from 'components/Button'
import Main from 'layouts/Main'
import Box from 'components/Box'

function Auth() {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const history = useHistory()

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [history, isAuthenticated])

  const loginWithEmail = () => {
    loginWithRedirect({ redirectUri: `${window.location.origin}/checkout` })
  }

  const loginWithFacebook = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}/checkout`,
      connection: 'facebook'
    })
  }

  const toHomeLink = (
    <Link
      href='/'
      color='white'
      fontWeight='bold'
      fontSize='xl'
      display='flex'
      alignItems='center'
    >
      <ArrowBackIcon mr={1} />
    </Link>
  )

  return (
    <Main title={toHomeLink}>
      <Stack
        direction={['column', 'row']}
        divider={<StackDivider borderColor='gray.200' />}
        spacing={10}
        align='stretch'
        justify='center'
        mt={10}
      >
        <Box paddingX={5}>
          <Heading size='md'>
            <Trans>Guest checkout</Trans>
          </Heading>
          <Text mt={1}>
            <Trans>Proceed to checkout, you can create an account later</Trans>
          </Text>
          <Button variant='primary' w='full' mt={3} as='a' href='/checkout'>
            <Trans>Continue as guest</Trans>
          </Button>
        </Box>
        <Box paddingX={5}>
          <Heading size='md'>
            <Trans>Shop often?</Trans>
          </Heading>
          <Text mt={1}>
            <Trans>Login or sign up for a better checkout experience</Trans>
          </Text>
          <Button variant='outline' w='full' mt={3} onClick={loginWithEmail}>
            <Trans>Login or sign up via email</Trans>
          </Button>
          <Button
            variant='outline'
            w='full'
            leftIcon={<Icon as={FaFacebook} color='blue.500' boxSize={5} />}
            mt={3}
            onClick={loginWithFacebook}
          >
            <Trans>Continue with Facebook</Trans>
          </Button>
        </Box>
      </Stack>
    </Main>
  )
}

export default Auth
