import { useState } from 'react'
import { Box } from '@chakra-ui/react'

import { useCart } from 'fetchers/cart'
import useCartId from 'utils/useCartId'
import { BuyerData } from 'models/cart'
import { PaymentChannel } from 'models/order'
import CheckoutContext from 'contexts/CheckoutContext'
import useBreakpoint from 'utils/useBreakpoint'

import Main from 'layouts/Main'
import AppContainer from 'components/AppContainer'
import ShippingAddress from 'scenes/ShippingAddress'
import FixedFooter from 'components/FixedFooter'
import Title from './components/Title'
import Cart from './components/Cart'
import Summary from './components/Summary'
import PaymentMethods from './components/PaymentMethod'

function Checkout() {
  const id = useCartId()
  const { query } = useCart(id)
  const [formData, setFormData] = useState<BuyerData | null>(null)
  const [paymentMode, setPaymentMode] = useState<PaymentChannel>(
    PaymentChannel.CashOnDelivery
  )
  const { isXl } = useBreakpoint()
  return (
    <CheckoutContext.Provider
      value={{ formData, setFormData, paymentMode, setPaymentMode }}
    >
      <Main title={<Title />}>
        {query.isFetched && query.data && (
          <AppContainer
            paddingX={{ base: 0, sm: '50px' }}
            pt={{ base: '0', sm: '24px' }}
          >
            <Box display={{ xl: 'flex' }}>
              <Box flex='1'>
                <Cart
                  products={query.data.products}
                  pageName={query.data.pageName}
                  permalinkUrl={query.data.permalinkUrl}
                />
                <Box mt={5}>
                  <PaymentMethods cart={query.data} />
                </Box>
              </Box>
              <Box
                width={{ xl: '400px' }}
                pl={{ xl: '50px' }}
                mt={{
                  base: '14px',
                  xl: 0
                }}
              >
                <ShippingAddress />
                {isXl ? (
                  <Box mt={5}>
                    <Summary cart={query.data} />
                  </Box>
                ) : (
                  <FixedFooter>
                    <Summary cart={query.data} />
                  </FixedFooter>
                )}
              </Box>
            </Box>
          </AppContainer>
        )}
      </Main>
    </CheckoutContext.Provider>
  )
}

export default Checkout
