import { MdShoppingCart } from 'react-icons/md'
import { Trans } from '@lingui/macro'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Alert,
  AlertIcon,
  Stack
} from '@chakra-ui/react'
import { Button } from 'components/Button'
import { refreshStock } from 'fetchers/cart'
import { useEffect, useRef, useState } from 'react'

import { CartProduct } from 'models/cartproduct'

export interface StockCheckModalProps {
  id: string
  products: CartProduct[]
  onResync: () => void
}

function StockCheckModal(props: StockCheckModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id, products, onResync } = props

  const [outOfStockProducts, setOutOfStockProducts] = useState<CartProduct[]>(
    []
  )

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  useEffect(() => {
    const filtered = products.filter(p => p.productQuantity > p.productStock)

    setOutOfStockProducts(filtered)

    const hasOutOfStockProducts = filtered.length > 0

    if (hasOutOfStockProducts) {
      onOpen()
    }
  }, [products, onOpen])

  const resyncStock = () => {
    refreshStock({ cartId: id }).then(() => {
      onResync()
      onClose()
    })
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      scrollBehavior='inside'
      isCentered
    >
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />

      <ModalContent>
        <ModalHeader>
          <Trans>Oops, some products are out of stock</Trans>
        </ModalHeader>
        <ModalBody pb={6}>
          <Stack spacing={1}>
            {outOfStockProducts.map(p => {
              const status = p.productStock <= 0 ? 'error' : 'info'

              const message =
                p.productStock <= 0 ? (
                  <Trans>Product {p.productCode} is out of stock</Trans>
                ) : (
                  <Trans>
                    Product {p.productCode} only have {p.productStock}/
                    {p.productQuantity} left
                  </Trans>
                )

              return (
                <Alert status={status} variant='left-accent' key={p.id}>
                  <AlertIcon />
                  {message}
                </Alert>
              )
            })}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            leftIcon={<MdShoppingCart />}
            variant='solid'
            onClick={resyncStock}
          >
            <Trans>Sync Cart</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default StockCheckModal
