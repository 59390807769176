import React from 'react'
import { Box, Heading, BoxProps } from '@chakra-ui/layout'

export interface CardProps extends Omit<BoxProps, 'title'> {
  title?: React.ReactNode
  children: React.ReactNode
}

export default function Card(props: CardProps) {
  const { title, children, ...otherProps } = props
  return (
    <Box bg='white' borderRadius='md' {...otherProps}>
      {title && (
        <Heading p={3} size='sm'>
          {title}
        </Heading>
      )}
      {children}
    </Box>
  )
}
