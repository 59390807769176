import React from "react";
import Box from "../../components/Box";
import Warn from "../../icons/Warn";
import { Trans } from "@lingui/macro";

function CartExpired() {
  return (
    <Box py="50px">
      <Box
        textAlign="center"
        color="warn"
        fontSize="60px"
        display="flex"
        justifyContent="center"
      >
        <Warn />
      </Box>
      <Box mt="14px" as="h1" textAlign="center" textStyle="pageTitle">
        <Trans>The requested cart is not found or expired.</Trans>
      </Box>
    </Box>
  );
}

export default CartExpired;
