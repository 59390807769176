import React from 'react'
import { Trans } from '@lingui/macro'
import { Link, useDisclosure, Flex, Box, Text } from '@chakra-ui/react'
import AppContainer from 'components/AppContainer'
import Main from 'layouts/Main'
import useOrganizationId from 'utils/useOrganizationId'
import ShopContext from 'contexts/ShopContext'
import useCatalogId from 'utils/useCatalogId'
import SearchBox from 'scenes/Shop/components/SearchBox'
import { FiShoppingBag } from 'react-icons/fi'
import useStore from 'store'
import CartDrawer from 'scenes/Shop//components/CartDrawer'
import useBreakpoint from 'utils/useBreakpoint'
import useCategoryId from 'utils/useCategoryId'
import StickyButton from 'components/StickyButton/StickyButton'

export interface ShopContainerProps {
  children: React.ReactElement
}

export default function ShopContainer(props: ShopContainerProps) {
  const organizationId = useOrganizationId()
  const catalogId = useCatalogId()
  const categoryId = useCategoryId()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { cart } = useStore()
  const { isMd } = useBreakpoint()

  const toHomeLink = (
    <Link href={`/shop`} color='white' fontWeight='bold' fontSize='xl'>
      <Trans>Home</Trans>
    </Link>
  )

  return (
    <ShopContext.Provider value={{ organizationId, catalogId, categoryId }}>
      <Box bgColor='#fff'>
        <Main title={toHomeLink}>
          <AppContainer
            paddingX={{ base: 0, sm: '50px' }}
            pt={{ base: '10px', sm: '24px' }}
          >
            <Flex>
              <SearchBox />
              {/* <Button
                variant='primary'
                leftIcon={<FiShoppingBag size='20px' />}
                size='md'
                onClick={onOpen}
                ml={3}
              >
                <Text fontSize='14px' fontWeight={500}>
                  {isMd && <Trans>View Cart</Trans>} ({cart.products.length})
                </Text>
              </Button> */}
            </Flex>
            {props.children}
            {cart.products.length > 0 && (
              <StickyButton
                leftIcon={<FiShoppingBag size='20px' />}
                onOpen={onOpen}
                label={
                  <Text fontSize='14px' fontWeight={500}>
                    {isMd && <Trans>View Cart</Trans>} ({cart.products.length})
                  </Text>
                }
              />
            )}
            <CartDrawer isOpen={isOpen} onClose={onClose} />
          </AppContainer>
        </Main>
      </Box>
    </ShopContext.Provider>
  )
}
