import {
  Modal,
  ModalProps,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Button
} from '@chakra-ui/react'
import { FiShoppingCart } from 'react-icons/fi'
import { Trans } from '@lingui/macro'
import ProductGallery from './ProductGallery'
import { formatMoney } from 'utils/currency'
import useAddToCart from './useAddToCart'
import { Product } from 'models/product'

interface ProductDetailProps extends Omit<ModalProps, 'children'> {
  product: Product | null
}
export default function ProductDetail(props: ProductDetailProps) {
  const { product, ...modalProps } = props
  const addToCart = useAddToCart()

  if (!product) {
    return null
  }

  return (
    <Modal {...modalProps} size='4xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box p={5} d={{ md: 'flex' }}>
            <Box mr={8} mb={4}>
              {product.productImages && product.productImages.length ? (
                <ProductGallery images={product.productImages} />
              ) : (
                <ProductGallery
                  images={[
                    {
                      ordinal: 0,
                      url: '/images/shop-logo.svg'
                    }
                  ]}
                />
              )}
            </Box>

            <Box>
              <Text maxW='400px' fontSize='2xl'>
                {product.productName ?? product.productCode}
              </Text>
              <Text fontWeight='bold' fontSize='xl' mt={2}>
                {formatMoney(product.price)}
              </Text>
              {/* TODO: add stock quantity */}
              {/* TODO: add Read more if the description is long */}
              <Text maxW='400px' mt={4} lineHeight='1.5' whiteSpace='pre-line'>
                {product.description}
              </Text>
              <Box mt={8}>
                <Button
                  leftIcon={<FiShoppingCart />}
                  colorScheme={product.productStock > 0 ? '' : 'gray'}
                  variant='primary'
                  onClick={() => {
                    if (product.productStock > 0) {
                      addToCart(product)
                    }
                  }}
                  mr={2}
                  disabled={product.productStock <= 0}
                >
                  <Trans>Buy it now</Trans>
                </Button>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
