import { Box, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Card from 'components/Card'
import { Order as OrderInterface } from 'models/order'
import { formatMoney } from 'utils/currency'
import Order from './Order'

interface OrderInfoProps {
  data: OrderInterface
}

export default function OrderInfo(props: OrderInfoProps) {
  const { data } = props
  const discount = data?.paymentDetails?.discount
  return (
    <>
      <Order
        products={data.orderItems || []}
        pageName={data.generalInformation?.pageName || ''}
        permalinkUrl={`https://www.facebook.com/${data.generalInformation?.pageId}`}
      />
      {!!discount && (
        <Card title={<Trans>Discount</Trans>}>
          {formatMoney(Math.min(discount, data.paymentDetails?.subTotal || 0))}
        </Card>
      )}{' '}
      <Card>
        <Text textAlign={'right'} p={3}>
          <Box as='span' fontWeight={'bold'}>
            <Trans>Delivery Fee</Trans>
          </Box>
          : {formatMoney(data.paymentDetails?.deliveryFee || 0)}
        </Text>
      </Card>
      <Card>
        <Text textAlign={'right'} textStyle={'sectionTitle'} p={3}>
          <Trans>Total</Trans>: {formatMoney(data.paymentDetails?.total || 0)}
        </Text>
      </Card>
    </>
  )
}
