import React from 'react'
import { Box, BoxProps, Image, Text, Link } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import axios from 'axios'

export interface PageHeaderProps extends BoxProps {
  pageName: string
  permalinkUrl: string
}

export default function PageHeader(props: PageHeaderProps) {
  const { pageName, permalinkUrl, ...boxProps } = props

  const [avatarUrl, setAvatarUrl] = React.useState('')
  React.useEffect(() => {
    async function fetchPageAvatar() {
      const id = permalinkUrl.replace('https://www.facebook.com/', '')

      try {
        const {
          data: {
            data: { url }
          }
        } = await axios.get(
          `https://graph.facebook.com/${id}/picture?redirect=0`
        )

        setAvatarUrl(url)
      } catch {
        // ignore
      }
    }

    fetchPageAvatar()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box display='flex' alignItems='center' {...boxProps}>
      <Image
        height={10}
        ml={2}
        fallbackSrc='/images/shop-logo.svg'
        src={avatarUrl}
      />
      <Box ml='3'>
        <Text fontWeight='bold' fontSize='md'>
          {pageName}
        </Text>
        <Link href={permalinkUrl} color='primary'>
          <Trans>Contact shop</Trans>
        </Link>
      </Box>
    </Box>
  )
}
