import React from 'react'

import localStorageManager from 'utils/localStorageManager'
import Main from 'layouts/Main'
import CheckoutContainer from 'containers/CheckoutContainer'
import NoProduct from 'scenes/NoProduct'
import CartIdContext from 'contexts/CartIdContext'

export interface CheckoutSwitchProps {
  id: string
  host: string
  children: React.ReactElement
}

function CheckoutSwitch(props: CheckoutSwitchProps) {
  const cartId = props.id || localStorageManager.getCartId()

  if (!cartId) {
    return (
      <Main>
        <NoProduct />
      </Main>
    )
  }

  if (localStorageManager.getCartId() !== cartId) {
    localStorageManager.setCartId(cartId)
  }

  return (
    <CartIdContext.Provider value={{ id: cartId }}>
      <CheckoutContainer>{props.children}</CheckoutContainer>
    </CartIdContext.Provider>
  )
}

export default CheckoutSwitch
