import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Box, ChakraProvider, Spinner } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { en, vi } from 'make-plural/plurals'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import withParams from './utils/withParams'
import { messages as messages_en } from './locales/en/messages.js'
import { messages as messages_vi } from './locales/vi/messages.js'
import { theme } from './theme'
import { UserSettingsContext } from 'contexts/UserSettingsContext'
import localStorageManager from 'utils/localStorageManager'

import Home from './scenes/Home'
import Confirmation from './scenes/Confirmation'
import NotFound from './scenes/NotFound'
import Auth from './scenes/Auth'
import CheckoutSwitch from './containers/CheckoutSwitch'
import Checkout from './scenes/Checkout'
import Payment from './scenes/Payment'
import ShopSwitch from 'containers/ShopSwitch'
import Shop from 'scenes/Shop'
import { Catalog } from 'scenes/Shop'
import CategoryView from 'scenes/Shop/Category'
import StoreMerchant from 'scenes/StoreMerchant/StoreMerchant'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
const clientId =
  process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '617295741c3314245763debf'

i18n.loadLocaleData('en', { plurals: en })
i18n.loadLocaleData('vi', { plurals: vi })
i18n.load('en', messages_en)
i18n.load('vi', messages_vi)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
})

const CheckoutSwitchScene = withParams(CheckoutSwitch)
const ShopSwitchScene = withParams(ShopSwitch)
const NotFoundScene = NotFound

function App() {
  const { isLoading } = useAuth0()

  const { locale } = React.useContext(UserSettingsContext)

  React.useEffect(() => {
    i18n.activate(locale)
    localStorageManager.setLang(locale)
  }, [locale])

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS={true} theme={theme}>
          <I18nProvider i18n={i18n}>
            {isLoading ? (
              <Box
                d='flex'
                alignItems='center'
                justifyContent='center'
                height='30vh'
              >
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />
              </Box>
            ) : (
              <Switch>
                <Route path='/' exact>
                  <ShopSwitchScene>
                    <Shop />
                  </ShopSwitchScene>
                </Route>
                <Route path='/store' exact>
                  <StoreMerchant />
                </Route>
                <Route path='/auth'>
                  <Auth />
                </Route>
                <Route path='/checkout'>
                  <CheckoutSwitchScene>
                    <Checkout />
                  </CheckoutSwitchScene>
                </Route>
                <Route path='/payment'>
                  <CheckoutSwitchScene>
                    <Payment />
                  </CheckoutSwitchScene>
                </Route>
                <Route path='/shop/:catalogId'>
                  <ShopSwitchScene>
                    <Catalog />
                  </ShopSwitchScene>
                </Route>
                <Route path='/shop'>
                  <ShopSwitchScene>
                    <Shop />
                  </ShopSwitchScene>
                </Route>
                <Route path='/category/:categoryId'>
                  <ShopSwitchScene>
                    <CategoryView />
                  </ShopSwitchScene>
                </Route>
                <Route path='/confirmation/:id'>
                  <CheckoutSwitchScene>
                    <Confirmation />
                  </CheckoutSwitchScene>
                </Route>
                <Route path='/:id'>
                  <CheckoutSwitchScene>
                    <Home />
                  </CheckoutSwitchScene>
                </Route>
                <Route path='/not-found'>
                  <NotFoundScene />
                </Route>
                <Route path='*'>
                  <NotFoundScene />
                </Route>
              </Switch>
            )}
          </I18nProvider>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  )
}

export default withLDProvider({
  clientSideID: clientId
})(App)
