import { Button, useDisclosure } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Box from 'components/Box'
import CheckoutContext from 'contexts/CheckoutContext'
import { useBuyer } from 'fetchers/buyer'
import { useCart } from 'fetchers/cart'
import React, { useContext, useEffect } from 'react'
import useCartId from 'utils/useCartId'
import ShippingOverview from '../components/ShippingOverview'
import GuestEditAddressModal from '../guest/GuestEditAddressModal'
import BuyerNewAddressModal from './BuyerNewAddressModal'
import SelectAddressModal from './BuyerSelectAddressModal'

export default function BuyerAddress() {
  const {
    isOpen: isOpenNew,
    onClose: onCloseNew,
    onOpen: onOpenNew
  } = useDisclosure()
  const {
    isOpen: isOpenSelect,
    onClose: onCloseSelect,
    onOpen: onOpenSelect
  } = useDisclosure()
  const { formData, setFormData } = useContext(CheckoutContext)
  const { query: buyerQuery } = useBuyer()
  const id = useCartId()
  const { query: cartQuery, deliveryInfoMutation } = useCart(id)

  // set default delivery info on load
  useEffect(() => {
    if (cartQuery.data?.deliveryAddress) {
      return
    }

    if (buyerQuery.data && buyerQuery.data.length > 0) {
      const defaultAddress =
        buyerQuery.data.find(address => address.isDefault) || buyerQuery.data[0]

      deliveryInfoMutation.mutate({
        cartId: id,
        deliveryAddress: defaultAddress.deliveryAddress,
        customerPhoneNumber: defaultAddress.phoneNumber,
        recipient: defaultAddress.recipient,
        deliveryInstruction: defaultAddress.deliveryInstruction,
        postalCode: defaultAddress.postalCode,
        provinceId: defaultAddress.provinceId,
        wardCode: defaultAddress.wardCode,
        districtId: defaultAddress.districtId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerQuery.data, cartQuery.data, id])

  useEffect(() => {
    if (cartQuery.data && cartQuery.data.recipient) {
      setFormData({
        recipient: cartQuery.data.recipient,
        email: cartQuery.data.emailAddress,
        phoneNumber: cartQuery.data.phoneNumber,
        deliveryAddress: cartQuery.data.deliveryAddress,
        deliveryInstruction: cartQuery.data.deliveryInstruction,
        postalCode: cartQuery.data.postalCode,
        provinceId: cartQuery.data.provinceId,
        wardCode: cartQuery.data.wardCode,
        districtId: cartQuery.data.districtId
      })

      return
    }
    if (buyerQuery.data && buyerQuery.data.length) {
      const defaultAddress =
        buyerQuery.data.find(address => address.isDefault) || buyerQuery.data[0]
      setFormData(defaultAddress)
    }
  }, [cartQuery, setFormData, buyerQuery])

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        p={3}
      >
        <ShippingOverview formData={formData} />
        {formData ? (
          <Button variant='link' color='primary' onClick={onOpenSelect}>
            <Trans>Change</Trans>
          </Button>
        ) : (
          <Button variant='link' color='primary' onClick={onOpenNew}>
            <Trans>Add</Trans>
          </Button>
        )}
      </Box>
      {formData && (
        <GuestEditAddressModal
          isOpen={isOpenSelect}
          onClose={onCloseSelect}
          formData={formData}
        />
      )}

      <BuyerNewAddressModal isOpen={isOpenNew} onClose={onCloseNew} />

      {buyerQuery.data && buyerQuery.data.length ? (
        <SelectAddressModal
          onClose={onCloseSelect}
          isOpen={isOpenSelect}
          addresses={buyerQuery.data}
        />
      ) : null}
    </>
  )
}
