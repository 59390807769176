import React from 'react'
import { Trans } from '@lingui/macro'
import {
  Modal,
  ModalProps,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton
} from '@chakra-ui/react'

import useModalSize from 'utils/useModalSize'
import { BuyerData } from 'models/cart'
import { useBuyer } from 'fetchers/buyer'

import ShippingForm from '../components/ShippingForm'

interface EditAddressModalProps extends Omit<ModalProps, 'children'> {
  formData: BuyerData
}
export default function BuyerEditAddressModal(props: EditAddressModalProps) {
  const modalSize = useModalSize()
  const { updateBuyerDataMutation } = useBuyer()

  const handleSubmitForm = (values: BuyerData) => {
    updateBuyerDataMutation.mutate(
      {
        ...formData,
        ...values
      },
      {
        onSuccess: () => {
          props.onClose()
        }
      }
    )
  }

  const { formData, ...modalProps } = props

  return (
    <Modal {...modalProps} isCentered size={modalSize}>
      <ModalOverlay />
      <ModalContent marginY={0}>
        <ModalHeader>
          <Trans>Edit your shipping address</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ShippingForm
            isBuyer
            onSubmit={handleSubmitForm}
            isLoading={updateBuyerDataMutation.isLoading}
            formData={formData}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
