import React from 'react'
import { Trans } from '@lingui/macro'
import {
  Modal,
  ModalProps,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton
} from '@chakra-ui/react'

import useModalSize from 'utils/useModalSize'

import GuestAddressForm from './GuestAddressForm'

export default function NewAddressModal(props: Omit<ModalProps, 'children'>) {
  const modalSize = useModalSize()

  return (
    <Modal {...props} isCentered size={modalSize}>
      <ModalOverlay />
      <ModalContent marginY={0}>
        <ModalHeader>
          <Trans>Add your shipping address</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <GuestAddressForm onSubmit={props.onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
