import React from 'react'
import { useParams } from 'react-router-dom'

export default function withParams<
  ComponentProps = any,
  ComponentPropsWithoutParamProps = any
>(
  Component: React.ComponentType<ComponentProps>,
  mapKeys?: { prop: string; param: string }[]
) {
  function WithParams(props: ComponentPropsWithoutParamProps) {
    const params: { [key: string]: any } = useParams()

    let paramsProps: any = {}

    if (mapKeys) {
      mapKeys.forEach(m => {
        paramsProps[m.prop] = params[m.param]
      })
    } else {
      paramsProps = params
    }

    return <Component {...props} {...paramsProps} />
  }

  WithParams.displayName = `WithParams(${Component.displayName || 'Component'})`

  return WithParams
}
