import React from 'react'
import {
  FormLabel,
  FormLabelProps,
  FormControl,
  FormControlProps,
  FormHelperText,
  HelpTextProps
} from '../FormControl'
import { Input, InputProps, Textarea, TextareaProps } from '../Input'
import { Select, SelectProps } from '../Select'

export interface TextFieldProps extends Omit<FormControlProps, 'label'> {
  renderInput?: React.ReactNode
  label?: React.ReactNode
  helperText?: string
  error?: boolean
  labelProps?: FormLabelProps
  inputProps?: InputProps
  textareaProps?: TextareaProps
  helperProps?: HelpTextProps
  selectProps?: SelectProps
  type?: 'input' | 'select' | 'textarea'
  children?: React.ReactNode
}

export function TextField(props: TextFieldProps) {
  const {
    type = 'input',
    children,
    labelProps,
    label,
    inputProps,
    helperProps,
    error,
    helperText,
    selectProps,
    textareaProps,
    renderInput,
    ...formControlProps
  } = props
  return (
    <FormControl {...formControlProps}>
      {label && <FormLabel {...labelProps}>{label}</FormLabel>}
      {!renderInput && (
        <React.Fragment>
          {type === 'input' && <Input isInvalid={error} {...inputProps} />}
          {type === 'select' && (
            <Select display='flex' isInvalid={error} {...selectProps}>
              {children}
            </Select>
          )}
          {type === 'textarea' && (
            <Textarea isInvalid={error} {...textareaProps} />
          )}
        </React.Fragment>
      )}

      {renderInput && <div>{renderInput}</div>}

      {helperText && (
        <FormHelperText color={error ? 'red.500' : undefined} {...helperProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
