import React from 'react'
import { Trans } from '@lingui/macro'
import { Text, Badge, Center, Flex, Image } from '@chakra-ui/react'

import { formatMoney } from 'utils/currency'
import usePagination from 'utils/usePagination'

import Box from 'components/Box'
import Card from 'components/Card'
import { Table, Tbody, Td, Tr, Thead, Th } from 'components/Table'
import { Pagination } from 'components/Pagination'
import PageHeader from 'components/PageHeader'
import { OrderItem } from 'models/order'

export interface ProductProps {
  pageName: string
  permalinkUrl: string
  products: OrderItem[]
}

function Order(props: ProductProps) {
  const { products, pageName, permalinkUrl } = props

  const {
    slice,
    total,
    currentPage,
    shouldShowPagination,
    handlePageChange
  } = usePagination(products)

  return (
    <Card title={<Trans>Your Order</Trans>}>
      {permalinkUrl && (
        <PageHeader pageName={pageName} permalinkUrl={permalinkUrl} pb={2} />
      )}
      <Table variant='simple'>
        <Thead bg='secondary'>
          <Tr>
            <Th textTransform='none'>
              <Trans>Product</Trans>
            </Th>
            <Th textTransform='none' isNumeric>
              <Trans>Quantity</Trans>
            </Th>
            <Th textTransform='none' isNumeric>
              <Trans>Price</Trans>
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {slice.map(p => {
            return (
              <Tr key={p.id} verticalAlign='top'>
                <Td>
                  <Text fontWeight='600'>{p.itemName}</Text>
                  <Text fontSize='xs' mt={2} opacity={0.8}>
                    {p.itemCode}
                  </Text>
                  <Flex mr={{ base: 2, md: 0 }} ml={2}>
                    <Center>
                      <Image
                        mr={{ base: 2, md: 3 }}
                        boxSize='85px'
                        objectFit='contain'
                        alt={p.itemName}
                        src={p.itemImage}
                        fallbackSrc='/images/shop-logo.svg'
                      />
                    </Center>
                  </Flex>
                </Td>
                <Td isNumeric>
                  <Badge>{p.itemQuantity}</Badge>
                </Td>
                <Td isNumeric>{formatMoney(p.itemPrice)}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      {shouldShowPagination && (
        <Box marginY='14px' display='flex' justifyContent='center'>
          <Pagination
            total={total}
            current={currentPage}
            generateLink={handlePageChange}
          />
        </Box>
      )}
    </Card>
  )
}

export default Order
