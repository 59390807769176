import { useQuery, useMutation, useQueryClient } from 'react-query'
import request, { handleErrors, RequestError } from 'utils/request'
import config from 'config'
import { ProductList } from 'models/productList'
import { Product } from 'models/product'

async function fetchRecommendations(id: string): Promise<Product[]> {
  const result = await request<Product[]>({
    method: 'GET',
    url: config.apis.getRecommendations(id)
  })

  return result.data
}

async function fetchProducts(
  organizationId: string,
  pageNumber: number,
  pageSize: number,
  search?: string
): Promise<{ total: number; data: Product[] }> {
  const result = await request<{ total: number; data: Product[] }>({
    method: 'GET',
    url: config.apis.getProducts(organizationId, pageNumber, pageSize, search)
  })

  return result.data
}

async function fetchAddProduct(data: {
  productId: string
  cartId: string
  productQuantity: number
}): Promise<undefined> {
  await request({
    method: 'POST',
    url: config.apis.addProduct(),
    data
  })
  return
}

export function useProducts(
  organizationId: string,
  pageNumber: number,
  pageSize: number,
  search?: string
) {
  const productsQueryKey = `products__${organizationId}`

  const query = useQuery<ProductList, RequestError, ProductList>(
    productsQueryKey,
    () => fetchProducts(organizationId, pageNumber, pageSize, search),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )

  return {
    query
  }
}

export function useProductsForCart(cartId: string) {
  const queryClient = useQueryClient()
  const cartQueryKey = `cart__${cartId}`
  const productRecommendationQueryKey = `recommendation-${cartId}`

  const query = useQuery<Product[], RequestError, Product[]>(
    productRecommendationQueryKey,
    () => fetchRecommendations(cartId)
  )

  const addProductMutation = useMutation(fetchAddProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
      queryClient.invalidateQueries(productRecommendationQueryKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  return {
    query,
    addProductMutation
  }
}
