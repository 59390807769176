import Box from 'components/Box'
import { Trans } from '@lingui/macro'
import { Flex, Text, Image, Badge, Center } from '@chakra-ui/react'

import { CartProduct } from 'models/cartproduct'
import { formatMoney } from 'utils/currency'
import usePagination from 'utils/usePagination'
import useToast from 'utils/useToast'

import { Button } from 'components/Button'
import { Pagination } from 'components/Pagination'
import QuantityInput from 'components/QuantityInput'
import { getLatestProductImage } from 'utils/product'
import useConfirm from 'utils/useConfirm'
import { BsDot } from 'react-icons/bs'
import { Divider } from 'components/Divider'

export interface ProductProps {
  products: CartProduct[]
  isDisabled?: boolean
  onChangeQuantity: (
    pid: string,
    change: 'increase' | 'decrease',
    quantity?: number
  ) => void
  onDelete: (pid: string) => void
}

function Products(props: ProductProps) {
  const { products, isDisabled, onChangeQuantity, onDelete } = props
  const toast = useToast()

  const {
    slice,
    currentPage,
    shouldShowPagination,
    handlePageChange,
    total
  } = usePagination(products)

  const [ConfirmModal, handleConfirm] = useConfirm({
    onConfirm: (pid: string) => {
      onDelete(pid)
    },
    title: <Trans>Remove item</Trans>,
    confirmText: <Trans>Delete</Trans>,
    message: (
      <Trans>
        You are about to remove this item from your cart, are you sure?
      </Trans>
    )
  })

  function handleIncreaseProduct(id: string, productStock: number) {
    if (productStock === 0) {
      toast({
        description: (
          <Trans>
            You've reached the maximum units allowed for purchase of this item
          </Trans>
        )
      })
      return
    }
    onChangeQuantity(id, 'increase')
  }

  function handleDecreaseProduct(id: string) {
    onChangeQuantity(id, 'decrease')
  }

  const handleQuantityChange = (quantity: number, product: CartProduct) => {
    if (quantity < product.productQuantity) {
      onChangeQuantity(
        product.productId,
        'decrease',
        product.productQuantity - quantity
      )
      return
    }

    if (quantity > product.productQuantity) {
      onChangeQuantity(
        product.productId,
        'increase',
        quantity - product.productQuantity
      )
    }
  }

  return (
    <>
      <Box>
        <Box
          borderTopWidth={1}
          borderBottomWidth={1}
          borderColor='gray'
          px={2}
          pb={2}
        >
          {slice.map((p: CartProduct) => {
            const canEdit = !isDisabled
            const productImage = getLatestProductImage(p)

            const notEnoughStock = p.productStock < p.productQuantity

            let lowStock = false

            if (p.productStock <= 3) {
              lowStock = true
            }

            return (
              <Box key={p.id}>
                <Box d='flex' py={2}>
                  <Flex mr={{ base: 2, md: 0 }} ml={2}>
                    <Center>
                      <Image
                        mr={{ base: 2, md: 3 }}
                        boxSize='100px'
                        objectFit='contain'
                        alt={p.productName}
                        src={productImage?.url}
                        fallbackSrc='/images/shop-logo.svg'
                      />
                    </Center>
                  </Flex>
                  <Box w='100%'>
                    <Box d={{ md: 'flex' }}>
                      <Box flex={1}>
                        <Box>
                          <Flex
                            opacity='0.8'
                            flexDirection={{ base: 'column', md: 'row' }}
                          >
                            <Flex alignItems='center'>
                              <Text fontSize='xs'>
                                {p.productCode} &bull;{' '}
                                <Badge colorScheme='messenger'>
                                  {formatMoney(p.price)}
                                </Badge>
                              </Text>
                              <BsDot />
                              <Text fontSize='xs'>
                                {notEnoughStock ? (
                                  p.productStock <= 0 ? (
                                    <Badge colorScheme='red'>
                                      <Trans>this item is out of stock</Trans>
                                    </Badge>
                                  ) : (
                                    <>
                                      {lowStock ? (
                                        <Badge colorScheme='red'>
                                          <Trans>low stock</Trans>
                                        </Badge>
                                      ) : (
                                        <Badge colorScheme='red'>
                                          <Trans>
                                            only {p.productStock} left
                                          </Trans>
                                        </Badge>
                                      )}
                                    </>
                                  )
                                ) : (
                                  <>
                                    <Badge colorScheme='green'>
                                      <Trans>available</Trans>
                                    </Badge>
                                  </>
                                )}
                              </Text>
                            </Flex>
                          </Flex>
                          <Box>
                            <Text fontWeight='600' mt={2}>
                              {p.productName}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                      <Box mt={{ base: 2, md: 0 }}>
                        <QuantityInput
                          defaultValue={p.productQuantity}
                          disabled={!canEdit || notEnoughStock}
                          onDecrease={() => handleDecreaseProduct(p.productId)}
                          onIncrease={() =>
                            handleIncreaseProduct(p.productId, p.productStock)
                          }
                          onChange={quantity =>
                            handleQuantityChange(quantity, p)
                          }
                        />
                        <Button
                          aria-label='Delete product'
                          disabled={!canEdit}
                          onClick={() => handleConfirm(p.productId)}
                          size='sm'
                          variant='outline'
                          colorScheme='red'
                          mt={2}
                          w='200px'
                        >
                          <Trans>Remove</Trans>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </Box>
            )
          })}
        </Box>
        {shouldShowPagination && (
          <Box mt='14px' display='flex' justifyContent='center'>
            <Pagination
              total={total}
              current={currentPage}
              generateLink={handlePageChange}
            />
          </Box>
        )}
      </Box>
      <ConfirmModal />
    </>
  )
}

export default Products
