/* eslint-disable react-hooks/exhaustive-deps */
import localStorageManager from 'utils/localStorageManager'
import { useEffect, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import {
  Progress,
  Link,
  Divider,
  Select,
  Image,
  Text,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Button,
  Alert,
  AlertIcon
  // useDisclosure
} from '@chakra-ui/react'
import { useCart } from 'fetchers/cart'
import useCartId from 'utils/useCartId'
import useBreakpoint from 'utils/useBreakpoint'
import { t } from '@lingui/macro'
// import { getValidationErrors } from 'utils/request'
import Box from 'components/Box'
import AppContainer from 'components/AppContainer'
import FixedFooter from 'components/FixedFooter'
import PageHeader from 'components/PageHeader'
import Main from 'layouts/Main'
import Summary from './components/Summary'
import Products from './components/Products'
// import PromotionForm from './components/PromotionForm'
// import { AxiosError } from 'axios'
// import ShippingForm from './components/ShippingForm'
// import useToast from 'utils/useToast'
import Dropzone from 'components/DropZone'
import { useLingui } from '@lingui/react'
import { TextField } from 'components/TextField'
import Card from 'components/Card'
import { PaymentChannel } from 'models/order'
import {
  Cart,
  KaioWard,
  KaioProvince,
  KaioDistrict,
  Currency
} from 'models/cart'
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import { usePayment, fetchStripeCheckout } from 'fetchers/payment'
import { loadStripe } from '@stripe/stripe-js'
import { useHistory } from 'react-router'
import PreviewPaymentProof from './components/PreviewPaymentProof'
import { useForm } from 'react-hook-form'
import {
  DEFAULT_PHONE_NUMBER_REGEX,
  PHONE_NUMBER_REGEX as VIETNAM_PHONE_NUMBER_REGEX
} from 'utils/regex'
import PhoneNumberVerification from './components/PhoneNumberVerification'
import StockCheckModal from './components/StockCheckModal'
import { useKaioDeliveryServices } from 'fetchers/kaiodelivery'
// import ModalPayme from 'components/Modal/ModalPayme'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string)

// interface PromotionForm {
//   promotionCode: string
// }

function Home() {
  const id = useCartId()

  const {
    query,
    // shippingOptionsQuery,
    increaseMutation,
    decreaseMutation,
    deleteMutation,
    promotionMutation,
    pickShippingMutation,
    deliveryInfoMutation
  } = useCart(id)

  const { query: paymentInstruction, payV2Mutation, useGetPayme } = usePayment(
    id
  )

  const {
    kaioProvincesQuery,
    fetchKaioDistricts,
    fetchKaioWards
  } = useKaioDeliveryServices()

  const [kaioProvinces, setKaioProvinces] = useState<KaioProvince[]>([])
  const [kaioDistricts, setKaioDistricts] = useState<KaioDistrict[]>([])
  const [kaioWards, setKaioWards] = useState<KaioWard[]>([])

  const [selectedKaioProvince, setSelectedKaioProvince] = useState<
    KaioProvince | undefined
  >(undefined)

  const [selectedKaioDistrict, setSelectedKaioDistrict] = useState<
    KaioDistrict | undefined
  >(undefined)

  const [selectedKaioWard, setSelectedKaioWard] = useState<
    KaioWard | undefined
  >(undefined)

  // general state
  const [note, setNote] = useState<string | null>(null)
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
  const [paymentMode, setPaymentMode] = useState<PaymentChannel>(
    PaymentChannel.CashOnDelivery
  )
  const [cart, setCart] = useState<Cart>()
  const [deliveryAddress, setDeliveryAddress] = useState<string | null>(null)
  const [paymentProof, setPaymentProof] = useState<File[] | null>(null)
  const { i18n } = useLingui()
  const { isXl } = useBreakpoint()
  const history = useHistory()

  // open modal payme
  const [urlPayme, setUrlPayme] = useState('')
  // const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (urlPayme) {
      // const convertUrl = urlPayme?.replace('http', 'https')
      window.open(urlPayme, '_self')
    }
  }, [urlPayme])

  // const toast = useToast()

  // const promotionForm = useForm<PromotionForm>({
  //   reValidateMode: 'onChange',
  //   mode: 'all'
  // })

  // const {
  //   setError: setPromotionError,
  //   getValues: getPromotionValues,
  //   setValue: setPromotionValue
  // } = promotionForm

  const {
    register,
    errors,
    trigger,
    formState: { isValid }
  } = useForm<{ phoneNumber: string | null }>({
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: { phoneNumber: query.data?.phoneNumber }
  })

  const isDisabled = useMemo(() => {
    // if the cart currency is in VND
    // must always have province / district and ward
    if (
      query.data?.currency == Currency.VND &&
      (!selectedKaioProvince || !selectedKaioDistrict || !selectedKaioWard)
    ) {
      return true
    }

    if (!phoneNumber || !deliveryAddress || !isValid) {
      return true
    }

    if (
      paymentMode === PaymentChannel.Offline &&
      (!paymentProof || paymentProof?.length === 0)
    ) {
      return true
    }

    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isValid,
    deliveryAddress,
    paymentMode,
    paymentProof,
    phoneNumber,
    query.data?.isGiaoHangNhanhEnabled,
    query.data?.isJtExpressEnabled,
    query.data?.currency,
    selectedKaioDistrict,
    selectedKaioProvince,
    selectedKaioWard
  ])

  const isLoading =
    (query.isFetched && query.isFetching) ||
    increaseMutation.isLoading ||
    deleteMutation.isLoading ||
    decreaseMutation.isLoading ||
    promotionMutation.isLoading ||
    pickShippingMutation.isLoading

  // useEffect(() => {
  //   if (query.data?.promotionCode && !getPromotionValues('promotionCode')) {
  //     setPromotionValue('promotionCode', query.data?.promotionCode)
  //   }
  // }, [query.data?.promotionCode, getPromotionValues, setPromotionValue])

  useEffect(() => {
    const { data } = query
    if (!data) return

    const {
      phoneNumber,
      deliveryAddress,
      cashOnDeliveryPaymentEnabled,
      selfPickUpEnabled,
      offlinePaymentEnabled,
      onlinePaymentEnabled
    } = data

    setCart(data)

    if (phoneNumber) {
      setPhoneNumber(phoneNumber)
      trigger('phoneNumber')
    }

    if (deliveryAddress) {
      setDeliveryAddress(deliveryAddress)
    }

    if (!cashOnDeliveryPaymentEnabled) {
      if (selfPickUpEnabled) {
        setPaymentMode(PaymentChannel.PickUp)
      } else if (offlinePaymentEnabled) {
        setPaymentMode(PaymentChannel.Offline)
      } else if (onlinePaymentEnabled) {
        setPaymentMode(PaymentChannel.Online)
      }
    }
  }, [query.data, trigger])

  useEffect(() => {
    const { data } = query
    if (!data) return

    const { kaioProvinceId } = data

    if (kaioProvinceId && !selectedKaioProvince && kaioProvinces.length > 0) {
      setSelectedKaioProvince(
        kaioProvinces.find(province => province.id === kaioProvinceId)
      )
    }
  }, [query.data, kaioProvinces])

  useEffect(() => {
    const { data } = query
    if (!data) return

    const { kaioDistrictId } = data

    if (kaioDistrictId && !selectedKaioDistrict && kaioDistricts.length > 0) {
      setSelectedKaioDistrict(
        kaioDistricts.find(district => district.id === kaioDistrictId)
      )
    }
  }, [query.data, kaioDistricts])

  useEffect(() => {
    const { data } = query
    if (!data) return

    const { kaioWardId } = data

    if (kaioWardId && !selectedKaioWard && kaioWards.length > 0) {
      setSelectedKaioWard(kaioWards.find(ward => ward.id === kaioWardId))
    }
  }, [query.data, kaioWards])

  useEffect(() => {
    if (kaioProvincesQuery.data) {
      setKaioProvinces(kaioProvincesQuery.data)
    }
  }, [kaioProvincesQuery])

  useEffect(() => {
    if (selectedKaioProvince) {
      fetchKaioDistricts(selectedKaioProvince.id).then(districts => {
        setKaioDistricts(districts)
      })
    }
  }, [selectedKaioProvince, fetchKaioDistricts])

  useEffect(() => {
    if (selectedKaioDistrict) {
      fetchKaioWards(selectedKaioDistrict.id).then(wards => {
        setKaioWards(wards)
      })
    }
  }, [selectedKaioDistrict, fetchKaioWards])

  const onKaioProvinceChanged = async (kaioProvince: KaioProvince) => {
    setSelectedKaioDistrict(undefined)
    setSelectedKaioWard(undefined)
    setSelectedKaioProvince(kaioProvince)
  }

  const onKaioDistrictChanged = async (kaioDistrict: KaioDistrict) => {
    setSelectedKaioWard(undefined)
    setSelectedKaioDistrict(kaioDistrict)
  }

  useEffect(() => {
    if (selectedKaioWard && selectedKaioDistrict && selectedKaioProvince) {
      deliveryInfoMutation.mutate({
        cartId: id,
        kaioWardId: selectedKaioWard.id,
        kaioDistrictId: selectedKaioDistrict.id,
        kaioProvinceId: selectedKaioProvince.id,
        kaioWard: selectedKaioWard.name,
        kaioDistrict: selectedKaioDistrict.name,
        kaioProvince: selectedKaioProvince.name
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedKaioWard])

  async function handleChangeQuantity(
    pid: string,
    change: 'increase' | 'decrease',
    quantity?: number
  ): Promise<void> {
    if (change === 'increase') {
      increaseMutation.mutate({
        productId: pid,
        cartId: id,
        quantity: quantity || 1
      })
    } else {
      await decreaseMutation.mutateAsync({
        productId: pid,
        cartId: id,
        quantity: quantity || 1
      })
      // if (getPromotionValues('promotionCode')) {
      //   promotionMutation.mutate(
      //     {
      //       cartId: id,
      //       promotionCode: getPromotionValues('promotionCode')
      //     },
      //     {
      //       onError(err) {
      //         handleErrors(err)
      //         // resetPromotionCode()
      //       }
      //     }
      //   )
      // }
    }
  }

  async function handleDeleteProduct(pid: string) {
    await deleteMutation.mutateAsync({
      productId: pid,
      cartId: id
    })
    // if (getPromotionValues('promotionCode')) {
    //   promotionMutation.mutate(
    //     {
    //       cartId: id,
    //       promotionCode: getPromotionValues('promotionCode')
    //     },
    //     {
    //       onError(err) {
    //         handleErrors(err)
    //         // resetPromotionCode()
    //       }
    //     }
    //   )
    // }
  }

  // function resetPromotionCode() {
  //   setPromotionValue('promotionCode', null)
  //   promotionMutation.mutate(
  //     {
  //       cartId: id,
  //       promotionCode: null
  //     },
  //     {
  //       onError(err) {
  //         handleErrors(err)
  //       }
  //     }
  //   )
  // }

  // function handleApplyCode(values: PromotionForm) {
  //   promotionMutation.mutate(
  //     {
  //       cartId: id,
  //       promotionCode: values.promotionCode || null
  //     },
  //     {
  //       onError(err) {
  //         const errors = getValidationErrors(err as AxiosError)
  //         setPromotionError('promotionCode', {
  //           message: errors[0],
  //           shouldFocus: true
  //         })
  //       }
  //     }
  //   )
  // }

  // function pickShippingOption(deliveryOptionId: string) {
  //   pickShippingMutation.mutate(
  //     {
  //       cartId: id,
  //       deliveryOptionId
  //     },
  //     {
  //       onError(err) {
  //         const errors = getValidationErrors(err as AxiosError)
  //         toast({
  //           description: errors[0]
  //         })
  //       }
  //     }
  //   )
  // }

  // const loginWithFacebook = () => {
  //   loginWithRedirect({
  //     redirectUri: `${window.location.origin}/checkout`,
  //     connection: 'facebook'
  //   })
  // }

  const onProceed = async () => {
    if (!cart) {
      return
    }

    if (paymentMode !== PaymentChannel.Online) {
      if (
        paymentMode === PaymentChannel.CashOnDelivery ||
        paymentMode === PaymentChannel.PickUp
      ) {
        payV2Mutation.mutate({
          cartId: cart.id,
          paymentProof: null,
          promotionId: cart.promotionId,
          phoneNumber: phoneNumber,
          deliveryAddress: deliveryAddress,
          postalCode: null,
          recipient: cart.customer?.name,
          deliveryInstruction: note,
          kaioProvinceId: selectedKaioProvince?.id ?? null,
          kaioDistrictId: selectedKaioDistrict?.id ?? null,
          kaioWardId: selectedKaioWard?.id ?? null,
          kaioProvince: selectedKaioProvince?.name ?? null,
          kaioDistrict: selectedKaioDistrict?.name ?? null,
          kaioWard: selectedKaioWard?.name ?? null,
          isPickUp: paymentMode === PaymentChannel.PickUp ? true : null
        })
      } else if (
        paymentMode === PaymentChannel.Offline &&
        paymentProof &&
        paymentProof?.length > 0
      ) {
        const reader = new FileReader()

        reader.addEventListener(
          'load',
          function () {
            payV2Mutation.mutate({
              cartId: cart.id,
              paymentProof: reader.result as string,
              promotionId: cart.promotionId,
              phoneNumber: phoneNumber,
              deliveryAddress: deliveryAddress,
              postalCode: null,
              recipient: cart.customer?.name,
              deliveryInstruction: note,
              kaioProvinceId: selectedKaioProvince?.id ?? null,
              kaioDistrictId: selectedKaioDistrict?.id ?? null,
              kaioWardId: selectedKaioWard?.id ?? null,
              kaioProvince: selectedKaioProvince?.name ?? null,
              kaioDistrict: selectedKaioDistrict?.name ?? null,
              kaioWard: selectedKaioWard?.name ?? null,
              isPickUp: null
            })
          },
          false
        )
        reader.readAsDataURL(paymentProof[0])
      } else if (paymentMode === PaymentChannel.PayMe) {
        useGetPayme.mutate(
          {
            customerId: query?.data?.customer?.id,
            orgId: query?.data?.organizationId,
            orderId: query?.data?.id,
            amount: query?.data?.total,
            paymentMethod: 'payme',
            checkoutUrl: window.location.href,
            customerName: query?.data?.customer?.name,
            customerPhone: query?.data?.customer?.phoneNumber
          },
          {
            onSuccess(res) {
              setUrlPayme(res?.data?.data?.url)
            }
          }
        )
      }

      localStorageManager.clearTempCart()

      return
    }

    if (paymentMode === PaymentChannel.Online) {
      const stripe = await stripePromise

      const sessionId: string = await fetchStripeCheckout({
        cartId: cart.id,
        promotionId: cart.promotionId
      })

      await stripe?.redirectToCheckout({
        sessionId
      })

      return
    }
    history.push('/payment')
  }

  const toHomeLink = (
    <Link href='/' color='white' fontWeight='bold' fontSize='xl'>
      <Trans>Your cart</Trans>
    </Link>
  )

  const reloadButton = (
    <Button
      ml={5}
      variant='primary'
      onClick={() => {
        window.location.reload()
      }}
    >
      <Trans>Reload</Trans>
    </Button>
  )

  return (
    <Main title={toHomeLink}>
      <AppContainer
        paddingX={{ base: 0, sm: '50px' }}
        pt={{ base: '0', sm: '24px' }}
      >
        {(query.isFetching || query.isError) && (
          <Box p={3} width='100%'>
            <Spinner />
            {reloadButton}
          </Box>
        )}
        {query.isFetched &&
          query.data &&
          query.data.customerVerificationRequired &&
          !query.data.isVerified && (
            <PhoneNumberVerification
              onVerified={() => {
                query.refetch()
              }}
              id={id}
              defaultPhoneNumber={query.data.phoneNumber || ''}
              currency={query.data.currency}
            />
          )}

        {query.isFetched && query.data && (
          <Box display={{ xl: 'flex' }}>
            <Box flex='1' bg='white' borderRadius='md' position='relative'>
              {isLoading && (
                <Progress
                  size='xs'
                  isIndeterminate
                  position='absolute'
                  width='100%'
                />
              )}
              {query.data.permalinkUrl && (
                <PageHeader
                  pageName={query.data.pageName}
                  permalinkUrl={query.data.permalinkUrl}
                  py={3}
                />
              )}
              <Products
                onChangeQuantity={handleChangeQuantity}
                onDelete={handleDeleteProduct}
                isDisabled={isLoading}
                products={query.data.products}
              />
              <StockCheckModal
                onResync={() => {
                  query.refetch()
                }}
                id={id}
                products={query.data.products}
              />
              {/* <PromotionForm
                form={promotionForm}
                promotionId={query.data.promotionId}
                handleApplyCode={handleApplyCode}
                handleRemoveCode={resetPromotionCode}
                isLoading={promotionMutation.isLoading}
              /> */}
              <Divider />
              {/* only show shipping form when there's at least 1 shipping option*/}
              {/* {shippingOptionsQuery.data &&
                shippingOptionsQuery.data.length > 0 &&
                !query.data.isGiaoHangNhanhEnabled &&
                !query.data.isJtExpressEnabled && (
                  <ShippingForm
                    shippingSettings={shippingOptionsQuery.data ?? []}
                    deliveryOptionId={query.data.deliveryOptionId}
                    handlePickShippingOption={pickShippingOption}
                  />
                )} */}
              {cart?.currency === Currency.VND ? (
                <Card
                  title={
                    <>
                      <Box display='flex' flex='1' alignItems='center'>
                        <Image width={6} src='/images/shipping.svg' mr={3} />
                        <Text mr={3} fontWeight='600' whiteSpace='pre'>
                          <Trans>Address</Trans>
                        </Text>
                      </Box>
                    </>
                  }
                >
                  <Box display='flex' p={3} flexDirection='column'>
                    <Stack>
                      <Box display='flex' justifyContent='space-between'>
                        <Select
                          placeholder={t`Select city`}
                          value={selectedKaioProvince?.id?.toString()}
                          onChange={e => {
                            const province = kaioProvinces.filter(
                              province => province.id === +e.target.value
                            )[0]

                            onKaioProvinceChanged(province)
                          }}
                          isFullWidth
                        >
                          {kaioProvinces &&
                            kaioProvinces.map(province => {
                              return (
                                <option key={province.id} value={province.id}>
                                  {province.name}
                                </option>
                              )
                            })}
                        </Select>
                      </Box>
                      <Select
                        placeholder={t`Select district`}
                        value={selectedKaioDistrict?.id?.toString()}
                        isFullWidth
                        onChange={e => {
                          const district = kaioDistricts.filter(
                            district => district.id === +e.target.value
                          )[0]

                          onKaioDistrictChanged(district)
                        }}
                      >
                        {kaioDistricts &&
                          kaioDistricts.map(district => {
                            return (
                              <option key={district.id} value={district.id}>
                                {district.name}
                              </option>
                            )
                          })}
                      </Select>
                      <Select
                        placeholder={t`Select ward`}
                        value={selectedKaioWard?.id?.toString()}
                        isFullWidth
                        onChange={e => {
                          const ward = kaioWards.filter(
                            ward => ward.id === +e.target.value
                          )[0]

                          setSelectedKaioWard(ward)
                        }}
                      >
                        {kaioWards &&
                          kaioWards.map(ward => {
                            return (
                              <option key={ward.id} value={ward.id}>
                                {ward.name}
                              </option>
                            )
                          })}
                      </Select>
                    </Stack>
                  </Box>
                </Card>
              ) : null}
              <Divider />

              <Divider />
              <Box>
                <Card
                // title={
                //   <>
                //     <Box display='flex' flex='1' alignItems='center'>
                //       <Image
                //         width={6}
                //         src='/images/shopping-cart.svg'
                //         mr={3}
                //       />
                //       <Text mr={3} fontWeight='600' whiteSpace='pre'>
                //         <Trans>Address</Trans>
                //       </Text>
                //     </Box>
                //   </>
                // }
                >
                  <Box display='flex' p={3} flexDirection='column'>
                    <Stack>
                      <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                          <EmailIcon color='gray.300' />
                        </InputLeftElement>
                        <Input
                          value={deliveryAddress || ''}
                          isInvalid={!deliveryAddress}
                          errorBorderColor='crimson'
                          placeholder={i18n._(t`Address`)}
                          onChange={e => setDeliveryAddress(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                          <PhoneIcon color='gray.300' />
                        </InputLeftElement>
                        <TextField
                          isRequired={true}
                          error={Boolean(errors.phoneNumber?.message)}
                          helperText={errors.phoneNumber?.message}
                          inputProps={{
                            name: 'phoneNumber',
                            ref: register({
                              pattern: {
                                value:
                                  cart?.currency === 0
                                    ? VIETNAM_PHONE_NUMBER_REGEX
                                    : DEFAULT_PHONE_NUMBER_REGEX,
                                message: i18n._(
                                  t`Please enter a valid phone number.`
                                )
                              }
                            }),
                            errorBorderColor: 'crimson',
                            placeholder: i18n._(t`Phone number`),
                            onChange: (e: any) => {
                              setPhoneNumber(e.target.value)
                            },
                            pl: 10
                          }}
                        />
                      </InputGroup>
                      {cart?.isVerified && (
                        <Alert status='success'>
                          <AlertIcon />
                          Your phone number has been verified!
                        </Alert>
                      )}
                      <Input
                        value={note || ''}
                        onChange={e => setNote(e.target.value)}
                        placeholder={i18n._(t`Note to delivery drivers`)}
                      />
                    </Stack>
                  </Box>
                </Card>
              </Box>
              <Divider />
              <Card title={<Trans>Payment Method</Trans>}>
                <RadioGroup
                  p={3}
                  defaultValue={paymentMode.toString()}
                  onChange={(paymentMode: string) => {
                    setPaymentMode(+paymentMode)
                  }}
                >
                  <Stack>
                    {/* {query.data.onlinePaymentEnabled ? (
                      <Radio value={PaymentChannel.Online.toString()}>
                        <Flex alignItems='center'>
                          <Image width={6} src='/images/card.svg' />
                          <Text fontSize='sm' ml={2}>
                            <Trans>Pay with credit or debit card</Trans>
                          </Text>
                        </Flex>
                      </Radio>
                    ) : null}{' '} */}
                    {query.data.cashOnDeliveryPaymentEnabled ? (
                      <Radio value={PaymentChannel.CashOnDelivery.toString()}>
                        <Flex alignItems='center'>
                          <Image width={6} src='/images/cod.svg' />
                          <Text fontSize='sm' ml={2}>
                            <Trans>Cash on delivery</Trans>
                          </Text>
                        </Flex>
                      </Radio>
                    ) : null}
                    {query.data.selfPickUpEnabled ? ( //tien mat
                      <Radio value={PaymentChannel.PickUp.toString()}>
                        <Flex alignItems='center'>
                          <Image width={6} src='/images/cod.svg' />
                          <Box ml={2}>
                            <Text fontSize='sm'>
                              <Trans>Pick Up (Free)</Trans>
                            </Text>
                            <Text fontSize='sm' color='GrayText'>
                              <Trans>
                                Please contact shop before picking up. Delivery
                                fee will be updated to be free for pick up.
                              </Trans>
                            </Text>
                          </Box>
                        </Flex>
                      </Radio>
                    ) : null}
                    {query.data.offlinePaymentEnabled && ( //chuyen khoan
                      <Radio value={PaymentChannel.Offline.toString()} pt={1}>
                        <Flex alignItems='center'>
                          <Image width={6} src='/images/bank-transfer.svg' />
                          <Box ml={2}>
                            <Text fontSize='sm'>
                              <Trans>Bank transfer</Trans>
                            </Text>
                            <Text fontSize='sm' color='GrayText'>
                              <Trans>
                                You will be required to attach a transaction
                                receipt
                              </Trans>
                            </Text>
                          </Box>
                        </Flex>
                      </Radio>
                    )}
                    {query.data.isPaymeEnabled && (
                      <Radio value={PaymentChannel.PayMe.toString()} pt={1}>
                        <Flex alignItems='center'>
                          <Image width={6} src='/images/bank-transfer.svg' />
                          <Box ml={2}>
                            <Text fontSize='sm' color='GrayText'>
                              <Trans>PAY ME</Trans>
                            </Text>
                          </Box>
                        </Flex>
                      </Radio>
                    )}
                    {query.data.offlinePaymentEnabled &&
                      paymentMode === PaymentChannel.Offline && (
                        <>
                          {paymentInstruction.isLoading && <Spinner />}
                          {paymentInstruction.data && (
                            <>
                              {paymentInstruction.data.map(instruction => (
                                <Box
                                  key={instruction.id}
                                  d='flex'
                                  mb={3}
                                  borderTopWidth='1px'
                                  borderColor='gray'
                                  pt={3}
                                  alignItems='flex-start'
                                >
                                  {instruction.image && (
                                    <Image
                                      borderRadius={10}
                                      borderColor='gray'
                                      borderWidth='3px'
                                      borderStyle='solid'
                                      mr={3}
                                      width='100px'
                                      src={instruction.image}
                                    />
                                  )}
                                  <Box>
                                    <Text fontWeight='bold' fontSize='md'>
                                      {instruction.title}
                                    </Text>
                                    <div
                                      className='description'
                                      dangerouslySetInnerHTML={{
                                        __html: instruction.description
                                      }}
                                    />
                                  </Box>
                                </Box>
                              ))}
                            </>
                          )}
                          <Box>
                            {paymentProof && paymentProof.length > 0 && (
                              <Box d='flex'>
                                <PreviewPaymentProof
                                  onClear={() => setPaymentProof(null)}
                                  file={paymentProof[0]}
                                />
                              </Box>
                            )}
                            <Box display={paymentProof ? 'none' : ''}>
                              <Dropzone
                                onChange={e => setPaymentProof(e)}
                                options={{
                                  maxFiles: 1,
                                  maxSize: 5 * 1024 * 1024,
                                  accept: 'image/*'
                                }}
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                  </Stack>
                </RadioGroup>
              </Card>
            </Box>
            {isXl ? (
              <Box width={{ xl: '400px' }} pl={{ xl: '50px' }}>
                <Summary
                  isLoading={payV2Mutation.isLoading}
                  total={query.data.total}
                  isDisabled={isLoading || isDisabled}
                  subTotal={query.data.subTotal}
                  discount={query.data.discount}
                  deliveryFee={query.data.deliveryFee}
                  onProceed={onProceed}
                  extraNote={query.data.extraNote}
                />
              </Box>
            ) : (
              <FixedFooter>
                {/* <a href='https://shorten.asia/3vvcnNQa' target='_blank'>
                  Mo tai khoan
                </a> */}
                <Summary
                  isLoading={payV2Mutation.isLoading}
                  total={query.data.total}
                  isDisabled={isLoading || isDisabled}
                  subTotal={query.data.subTotal}
                  discount={query.data.discount}
                  deliveryFee={query.data.deliveryFee}
                  extraNote={query.data.extraNote}
                  onProceed={onProceed}
                />
              </FixedFooter>
            )}
          </Box>
        )}
        {/* <RelatedProducts cartId={id} /> */}
        {/* {urlPayme && (
          <ModalPayme
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            urlPayme={urlPayme}
          />
        )} */}
      </AppContainer>
    </Main>
  )
}

export default Home
