import React from 'react'
import { Trans } from '@lingui/macro'
import {
  Modal,
  ModalProps,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton
} from '@chakra-ui/react'

import { BuyerData } from 'models/cart'
import useModalSize from 'utils/useModalSize'
import { useBuyer } from 'fetchers/buyer'

import ShippingForm from '../components/ShippingForm'

export default function NewAddressModal(props: Omit<ModalProps, 'children'>) {
  const modalSize = useModalSize()
  const { saveBuyerDataMutation } = useBuyer()

  const handleSubmitForm = (values: BuyerData) => {
    saveBuyerDataMutation.mutate(values, {
      onSuccess: () => {
        props.onClose()
      }
    })
  }
  return (
    <Modal {...props} isCentered size={modalSize}>
      <ModalOverlay />
      <ModalContent marginY={0}>
        <ModalHeader>
          <Trans>Add your shipping address</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ShippingForm
            onSubmit={handleSubmitForm}
            isLoading={saveBuyerDataMutation.isLoading}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
