import { Trans } from '@lingui/macro'
import { useContext } from 'react'
import {
  RadioGroup,
  Radio,
  Stack,
  Text,
  Image,
  Flex,
  Box
} from '@chakra-ui/react'

import CheckoutContext from 'contexts/CheckoutContext'

import Card from 'components/Card'
import { Cart, Currency } from 'models/cart'
import { PaymentChannel } from 'models/order'

function PaymentMethod(props: { cart: Cart }) {
  const { paymentMode, setPaymentMode } = useContext(CheckoutContext)

  const shouldShowStripe =
    props.cart.currency === Currency.AUD && props.cart.onlinePaymentEnabled

  return (
    <Card title={<Trans>Payment Method</Trans>}>
      <RadioGroup
        p={3}
        defaultValue={paymentMode.toString()}
        onChange={(paymentMode: string) => {
          setPaymentMode(+paymentMode)
        }}
      >
        <Stack>
          <Radio value={PaymentChannel.PickUp.toString()}>
            <Flex alignItems='center'>
              <Image width={6} src='/images/cod.svg' />
              <Text fontSize='sm' ml={2}>
                <Trans>Pick Up</Trans>
              </Text>
            </Flex>
          </Radio>
          {props.cart.cashOnDeliveryPaymentEnabled ? (
            <Radio value={PaymentChannel.CashOnDelivery.toString()}>
              <Flex alignItems='center'>
                <Image width={6} src='/images/cod.svg' />
                <Text fontSize='sm' ml={2}>
                  <Trans>Cash on delivery</Trans>
                </Text>
              </Flex>
            </Radio>
          ) : null}
          {props.cart.offlinePaymentEnabled ? (
            <Radio value={PaymentChannel.Offline.toString()} pt={1}>
              <Flex alignItems='center'>
                <Image width={6} src='/images/bank-transfer.svg' />
                <Box ml={2}>
                  <Text fontSize='sm'>
                    <Trans>Bank transfer</Trans>
                  </Text>
                  <Text fontSize='sm' color='GrayText'>
                    <Trans>
                      You will be required to attach a transaction receipt
                    </Trans>
                  </Text>
                </Box>
              </Flex>
            </Radio>
          ) : null}
          {shouldShowStripe ? (
            <Radio value={PaymentChannel.Online.toString()} pt={1}>
              <Flex alignItems='center'>
                <Image width={6} src='/images/card.svg' />
                <Text fontSize='sm' ml={2}>
                  <Trans>Pay with credit or debit card</Trans>
                </Text>
              </Flex>
            </Radio>
          ) : null}
        </Stack>
      </RadioGroup>
    </Card>
  )
}

export default PaymentMethod
