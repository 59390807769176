import { useQuery, useMutation, useQueryClient } from 'react-query'

import request, { handleErrors, RequestError } from '../utils/request'
import { ShippingSetting, Cart } from '../models/cart'
import config from '../config'
import { Order } from '../models/order'
import { fetchCancelPayment } from './payment'

export async function fetchCart(id: string): Promise<Cart> {
  const result = await request<Cart>({
    method: 'GET',
    url: config.apis.getCart(id)
  })
  return result.data
}

export async function fetchOrder(id: string): Promise<Order> {
  const result = await request<Order>({
    method: 'GET',
    url: config.apis.getOrder(id)
  })
  return result.data
}

export async function fetchPhoneNumberStatus(
  id: string,
  number: string
): Promise<boolean> {
  const result = await request<boolean>({
    method: 'GET',
    url: config.apis.getPhoneNumberStatus(id, number)
  })
  return result.data
}

export async function fetchShippingSettings(
  id: string
): Promise<ShippingSetting[]> {
  const result = await request<ShippingSetting[]>({
    method: 'GET',
    url: config.apis.getShippingOptions(id)
  })
  return result.data
}

export async function fetchCartIncrease(data: {
  productId: string
  cartId: string
  quantity?: number
}): Promise<undefined> {
  await request<Cart>({
    method: 'POST',
    url: config.apis.cartIncrease(),
    data
  })
  return
}

export async function verifyPhoneNumber(data: {
  cartId: string
  phoneNumber: string
  isZalo: boolean
}): Promise<boolean> {
  const result = await request<boolean>({
    method: 'POST',
    url: config.apis.verifyPhoneNumber(),
    data
  })
  return result.data
}

export async function refreshStock(data: { cartId: string }): Promise<boolean> {
  const result = await request<boolean>({
    method: 'POST',
    url: config.apis.refreshStock(),
    data
  })
  return result.data
}

export async function verifyOtp(data: {
  cartId: string
  otp: string
}): Promise<boolean> {
  const result = await request<boolean>({
    method: 'POST',
    url: config.apis.verifyOtp(),
    data
  })
  return result.data
}

export async function fetchCartDecrease(data: {
  productId: string
  cartId: string
  quantity?: number
}): Promise<undefined> {
  await request<Cart>({
    method: 'POST',
    url: config.apis.cartDecrease(),
    data
  })
  return
}

export async function fetchCartDeleteProduct(data: {
  productId: string
  cartId: string
}): Promise<undefined> {
  await request<Cart>({
    method: 'POST',
    url: config.apis.cartDeleteProduct(),
    data
  })
  return
}

export async function fetchApplyPromotion(data: {
  cartId: string
  promotionCode: string | null
}): Promise<undefined> {
  await request<undefined>({
    method: 'POST',
    url: config.apis.applyPromotion(),
    data
  })
  return
}

export async function fetchPickShipping(data: {
  cartId: string
  deliveryOptionId: string | null
}): Promise<undefined> {
  await request<undefined>({
    method: 'POST',
    url: config.apis.pickShippingOption(),
    data
  })
  return
}

interface DeliveryInfo {
  customerEmail?: string | null
  customerPhoneNumber?: string | null
  cartId?: string | null
  deliveryAddress?: string | null
  recipient?: string | null
  deliveryInstruction?: string | null
  postalCode?: string | null
  provinceId?: number | null
  wardCode?: string | null
  districtId?: number | null
  giaoHangNhanhServiceId?: number | null
  jtCityId?: string | null
  jtDistrictId?: string | null
  jtAreaId?: string | null
  kaioWardId?: number | null
  kaioDistrictId?: number | null
  kaioProvinceId?: number | null
  kaioWard?: string | null
  kaioDistrict?: string | null
  kaioProvince?: string | null
}

export async function fetchSaveGuestDeliveryInfo(
  data: DeliveryInfo
): Promise<undefined> {
  await request<Cart>({
    method: 'POST',
    url: config.apis.saveGuestDeliveryInfo(),
    data
  })
  return
}

function fetchCartWrap(id: string) {
  return async () => {
    return await fetchCart(id)
  }
}

function fetchShippingSettingsWrap(id: string) {
  return async () => {
    return await fetchShippingSettings(id)
  }
}

function fetchOrderWrap(id: string) {
  return async () => {
    return await fetchOrder(id)
  }
}

export function useOrder(id: string) {
  const orderQueryKey = `order__${id}`
  const query = useQuery<Order, RequestError, Order>(
    orderQueryKey,
    fetchOrderWrap(id)
  )

  return {
    query
  }
}

export function useCart(id: string) {
  const queryClient = useQueryClient()
  const cartQueryKey = `cart__${id}`
  const shippingSettingsQueryKey = `cart__shipping__settings__${id}`

  const query = useQuery<Cart, RequestError, Cart>(
    cartQueryKey,
    fetchCartWrap(id),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 10
    }
  )

  const shippingSettingsQuery = useQuery<
    ShippingSetting[],
    RequestError,
    ShippingSetting[]
  >(shippingSettingsQueryKey, fetchShippingSettingsWrap(id), {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  const increaseMutation = useMutation(fetchCartIncrease, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  const decreaseMutation = useMutation(fetchCartDecrease, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  const cancelPaymentMutation = useMutation(fetchCancelPayment, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  const deleteMutation = useMutation(fetchCartDeleteProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  const deliveryInfoMutation = useMutation(fetchSaveGuestDeliveryInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
    },
    onError: error => {
      handleErrors(error)
    }
  })

  const promotionMutation = useMutation(fetchApplyPromotion, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
    },
    onError: () => {
      queryClient.invalidateQueries(cartQueryKey)
    }
  })

  const pickShippingMutation = useMutation(fetchPickShipping, {
    onSuccess: () => {
      queryClient.invalidateQueries(cartQueryKey)
    },
    onError: () => {
      queryClient.invalidateQueries(cartQueryKey)
    }
  })

  return {
    query,
    shippingOptionsQuery: shippingSettingsQuery,
    cancelPaymentMutation,
    deliveryInfoMutation,
    increaseMutation,
    decreaseMutation,
    deleteMutation,
    promotionMutation,
    pickShippingMutation
  }
}
