/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { detect, fromStorage, fromNavigator } from '@lingui/detect-locale'
import localStorageManager from 'utils/localStorageManager'

interface UserSettingsContextInterface {
  locale: string
  setLocale: (value: string) => void
}

export const UserSettingsContext = React.createContext<UserSettingsContextInterface>(
  {
    locale: 'vi',
    setLocale: () => {}
  }
)

const DEFAULT_LOCALE = 'vi'

const AppSettings: FunctionComponent = ({
  children
}: PropsWithChildren<unknown>) => {
  const localeQuery = new URLSearchParams(window.location.search).get('locale')
  const [locale, setLocaleValue] = React.useState<string>(
    detect(
      localeQuery?.substring(0, 2),
      fromStorage(localStorageManager.langKey),
      fromNavigator()?.replace(/-[\w]*/g, ''),
      DEFAULT_LOCALE
    ) || DEFAULT_LOCALE
  )

  if (localeQuery) {
    history.replaceState(
      null,
      '',
      location.pathname +
        location.search.replace(/[?&]locale=[^&]+/, '').replace(/^&/, '?')
    )
  }

  const setLocale = React.useCallback((value: string) => {
    setLocaleValue(value)
  }, [])

  return (
    <UserSettingsContext.Provider
      value={{
        locale,
        setLocale
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  )
}

export default AppSettings
