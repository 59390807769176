import { useQuery } from 'react-query'

import { ProductList } from 'models/productList'
import request, { RequestError } from 'utils/request'
import config from 'config'
import Catalog from 'models/catalog'
import Category from 'models/category'

async function fetchCatalog(tracker: string): Promise<Catalog[]> {
  const result = await request<Catalog[]>({
    method: 'GET',
    url: config.apis.getCatalogs(tracker)
  })

  return result.data
}

async function fetchCatalogProduct(tracker: string, catalogId: string) {
  const result = await request<ProductList>({
    method: 'GET',
    url: config.apis.getCatalogProducts(tracker, catalogId)
  })

  return result.data
}

export function useCatalog(tracker: string) {
  const catalogKey = `catalog_${tracker}`

  const query = useQuery<Catalog[], RequestError, Catalog[]>(
    catalogKey,
    () => fetchCatalog(tracker),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )

  return {
    query
  }
}

export function useCatalogProducts(tracker: string, catalogId: string) {
  const catalogKey = `catalog_${tracker}_${catalogId}`

  const query = useQuery<ProductList, RequestError, ProductList>(
    catalogKey,
    () => fetchCatalogProduct(tracker, catalogId),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )

  return {
    query
  }
}

async function fetchCategory(tracker: string): Promise<Category[]> {
  const result = await request<Category[]>({
    method: 'GET',
    url: config.apis.getCategories(tracker)
  })

  return result.data
}

async function fetchCategoryProducts(tracker: string, categoryId: string) {
  const result = await request<ProductList>({
    method: 'GET',
    url: config.apis.getCategoryProducts(tracker, categoryId)
  })

  return result.data
}

export function useCategory(tracker: string) {
  const categoryKey = `category_${tracker}`

  const query = useQuery<Category[], RequestError, Category[]>(
    categoryKey,
    () => fetchCategory(tracker),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )

  return {
    query
  }
}

export function useCategoryProducts(tracker: string, categoryId: string) {
  const categoryKey = `category_${tracker}_${categoryId}`

  const query = useQuery<ProductList, RequestError, ProductList>(
    categoryKey,
    () => fetchCategoryProducts(tracker, categoryId),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )

  return {
    query
  }
}
